import React, { ChangeEvent, RefObject } from 'react';
import isEmpty from 'lodash/isEmpty';

import { connect } from 'react-redux';
import { Form, Input, Select, Checkbox } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { CheckboxChangeEventTarget } from 'antd/lib/checkbox/Checkbox';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import {
  GUEST_TYPE_MEMBER,
  namesPrefix,
  SECOND_CHARACTER_POSITION,
  TENTH_CHARACTER_POSITION,
  CONDO_BOOKING_GUEST_INFO_LABEL,
  CONDO_BOOKING_SAME_ADDRESS_INFO_LABEL,
  USA_STATES,
  NULL_VALUE,
} from '@constants';
import {
  ICondoReviewBookState,
  insuranceActions,
  setCondoCard,
  setCondoGuest,
  setErrorMessageZipCode,
  refreshInsuranceQuote,
  IInsuranceState,
} from '@store/slices';
import { ICondosStaticState, ILoginState, IMenuState } from '@share/store/slices';
import { ZipRuleTypeEnum } from '@share/common-types';
import { ICondoCardBooking, ICondoGuestBooking } from '@common-types';
import { SupplierType, IRciCountriesCodes, IIntervalCountriesCodes } from '@share/common-types';
import { RootState, Toaster } from '@share/utils';
import {
  setNumberPhone,
  updateCondoGuest,
  getMemberPhone,
  getState,
  isPostalCode,
  hideBrowserAutoFill,
  getCountryCode,
  setValidator,
  getCountryIsoCode,
} from '@utils';
import { checkInLatinLetters } from '@share/utils';
import { countriesCode } from '@share/constants';
import { MemberChangeValue, SavedMembers } from '@components';

import './style.scss';

interface IMapStateToProps {
  condoReviewBookStore: ICondoReviewBookState;
  condosStaticStore: ICondosStaticState;
  menuStore: IMenuState;
  insuranceStore: IInsuranceState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  setCondoGuest: (guest: ICondoGuestBooking) => void;
  setCondoCard: (card: ICondoCardBooking) => void;
  setErrorMessageZipCode: (value: boolean) => void;

  resetInsurance: () => void;
  refreshInsuranceQuote: (state: string, priceChangeHandler?: () => void) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, WrappedComponentProps {
  formRef: RefObject<FormInstance>;
  isSomeoneElse: boolean;

  onChangeSomeoneElse: (e: { target: CheckboxChangeEventTarget }) => void;
  onUpdateAddress: () => void;
  onChangeCountryState: () => void;
}

const ZERO = 0;
const MAX_LENGTH_VALID_POST_CODE = 5;
const MAX_LENGTH_VALID_POST_CODE_CANADA = 7;
const MAX_LENGTH_NAME = 35;
const MAX_LENGTH_INTERVALS = 25;
const MAX_LENGTH_CITY_FOR_INTERVALS = 15;
const MAX_LENGTH_SURNAME = 35;
const MIN_LENGTH_SURNAME = 2;
const MAX_LENGTH = 100;
const POSITION = 3;
const COUNTRY_CANADA = 'Canada';
const MAX_LENGTH_NUMBER = 12;
const MAX_LENGTH_ADDRESS = 25;

class CondoGuestInfoComponent extends React.Component<IProps> {
  wrapperRef: React.RefObject<HTMLDivElement> = React.createRef();

  getPopupContainer = () => {
    return this.wrapperRef ? this.wrapperRef.current : document.body;
  };

  onChangeNamePrefix = (value: string) => {
    const { guest } = this.props.condoReviewBookStore;
    this.props.setCondoGuest(updateCondoGuest(guest, { namePrefix: value }) as ICondoGuestBooking);
    this.props.formRef.current?.setFieldsValue({ prefixName: value });
  };

  onChangeFirstName = (e: { target: HTMLInputElement }): void => {
    const { guest } = this.props.condoReviewBookStore;
    this.props.setCondoGuest(
      updateCondoGuest(guest, { givenName: e.target.value }) as ICondoGuestBooking,
    );
  };

  onChangeLastName = (e: { target: HTMLInputElement }): void => {
    const { guest } = this.props.condoReviewBookStore;
    this.props.setCondoGuest(
      updateCondoGuest(guest, { surname: e.target.value }) as ICondoGuestBooking,
    );
  };

  onChangePhone = (e: { target: HTMLInputElement }): void => {
    const { guest } = this.props.condoReviewBookStore;
    this.props.setCondoGuest(
      updateCondoGuest(guest, { phone: setNumberPhone(e.target.value) }) as ICondoGuestBooking,
    );
  };

  onChangePhoneCountryCode = (value: string) => {
    const { guest } = this.props.condoReviewBookStore;
    this.props.setCondoGuest(
      updateCondoGuest(guest, { phoneCountryCode: value }) as ICondoGuestBooking,
    );
  };

  handleUpdateAddress = () => {
    setTimeout(() => this.props.onUpdateAddress(), 200);
  }

  onChangeAddressLine1 = (e: { target: HTMLInputElement }): void => {
    const { card, guest } = this.props.condoReviewBookStore;
    this.props.setCondoGuest(
      updateCondoGuest(guest, { addressLine1: e.target.value }) as ICondoGuestBooking,
    );

    const isAddressAsPrimary = localStorage.getItem(CONDO_BOOKING_SAME_ADDRESS_INFO_LABEL);
    if (isAddressAsPrimary === 'true') {
      this.props.setCondoCard(
        updateCondoGuest(card, { addressLine1: e.target.value }, false) as ICondoCardBooking,
      );
      this.handleUpdateAddress();
    }
  };

  onChangeAddressLine2 = (e: { target: HTMLInputElement }): void => {
    const { card, guest } = this.props.condoReviewBookStore;
    this.props.setCondoGuest(
      updateCondoGuest(guest, { addressLine2: e.target.value }) as ICondoGuestBooking,
    );

    const isAddressAsPrimary = localStorage.getItem(CONDO_BOOKING_SAME_ADDRESS_INFO_LABEL);
    if (isAddressAsPrimary === 'true') {
      this.props.setCondoCard(
        updateCondoGuest(card, { addressLine2: e.target.value }, false) as ICondoCardBooking,
      );
      this.handleUpdateAddress();
    }
  };

  onChangePrimaryCity = (e: { target: HTMLInputElement }): void => {
    const { card, guest } = this.props.condoReviewBookStore;
    this.props.setCondoGuest(
      updateCondoGuest(guest, { city: e.target.value }) as ICondoGuestBooking,
    );

    const isAddressAsPrimary = localStorage.getItem(CONDO_BOOKING_SAME_ADDRESS_INFO_LABEL);
    if (isAddressAsPrimary === 'true') {
      this.props.setCondoCard(
        updateCondoGuest(card, { city: e.target.value }, false) as ICondoCardBooking,
      );
      this.handleUpdateAddress();
    }
  };

  onChangePrimaryCountry = (value: string): void => {
    const { loginStore, condoReviewBookStore, condosStaticStore, menuStore, setCondoGuest, setCondoCard, resetInsurance, refreshInsuranceQuote, onChangeCountryState } = this.props
    const { card, guest, condoBookingSummary } = condoReviewBookStore;
    const { condosCountriesCode } = condosStaticStore;
    const { supplierType } = condoBookingSummary;
    const { account } = loginStore;
    const { items } = menuStore;

    const countryCode = getCountryCode(value, supplierType, condosCountriesCode);
    const zipCode = isPostalCode(value, condosCountriesCode, condoBookingSummary.supplierType) ? guest.zipCode : '';
    const stateCode = !isEmpty(getState(value, condosCountriesCode, condoBookingSummary.supplierType)) ? guest.stateCode : '';
    const country = value;
    const newGuestData = { country, stateCode, zipCode, countryCode };

    setCondoGuest(
      updateCondoGuest(guest, newGuestData) as ICondoGuestBooking,
    );

    const isAddressAsPrimary = localStorage.getItem(CONDO_BOOKING_SAME_ADDRESS_INFO_LABEL);
    if (isAddressAsPrimary === 'true') {
      const countryCodeCard = getCountryIsoCode(value, supplierType, condosCountriesCode);
      setCondoCard(
        updateCondoGuest(card, {
          ...newGuestData,
          countryCode: countryCodeCard,
          country: supplierType !== SupplierType.Intervals ? value : countryCode
        }, false) as ICondoCardBooking,
      );
      this.handleUpdateAddress();
    }
    
    if (account?.enableInsurance && !items?.isRemoveInsurance) {
      if (guest?.country === 'USA' && value !== 'USA') {
        resetInsurance();
        this.handleToaster('insurance.policy.not_valid');
      } else if (guest?.country !== 'USA' && value === 'USA') {
        const isUSState = USA_STATES.map(s => s.postalCode).includes(card?.stateCode);
        refreshInsuranceQuote(isUSState ? card?.stateCode : NULL_VALUE);
        this.handleToaster('insurance.policy.us_resident');
      }
    }

    this.props.formRef.current?.setFieldsValue({ primaryCountry: value });
    onChangeCountryState();
  };

  onChangePrimaryState = (value: string): void => {
    const { formRef, loginStore, condoReviewBookStore, menuStore, setCondoGuest, setCondoCard, refreshInsuranceQuote, onChangeCountryState } = this.props;
    const { card, guest } = condoReviewBookStore;
    const { account } = loginStore;
    const { items } = menuStore;

    setCondoGuest(
      updateCondoGuest(guest, { stateCode: value }) as ICondoGuestBooking
    );

    const isAddressAsPrimary = localStorage.getItem(CONDO_BOOKING_SAME_ADDRESS_INFO_LABEL);
    if (isAddressAsPrimary === 'true') {
      setCondoCard(
        updateCondoGuest(card, { stateCode: value }, false) as ICondoCardBooking,
      );
      this.handleUpdateAddress();
    }

    if (account?.enableInsurance && !items?.isRemoveInsurance && guest?.country === 'USA') {
      refreshInsuranceQuote(value as string, () => this.handleToaster('insurance.policy.state_change')); 
    }

    formRef.current?.setFieldsValue({ primaryState: value });
    onChangeCountryState();
  };

  handleToaster = (message: string) => {
    const { intl } = this.props;
    Toaster.info(intl.formatMessage({ id : message }), {
      position: 'bottom-center',
      icon: false,
      hideProgressBar: true,  
    });
  }

  onChangePrimaryStateInput = (e: { target: HTMLInputElement }): void => {
    this.onChangePrimaryState(e.target.value);
  };

  onChangePrimaryPostalCode = (e: ChangeEvent<HTMLInputElement>): void => {
    const { card, guest } = this.props.condoReviewBookStore;
    const isAddressAsPrimary = localStorage.getItem(CONDO_BOOKING_SAME_ADDRESS_INFO_LABEL);

    if (this.props.condoReviewBookStore.errorsField) {
      this.props.setErrorMessageZipCode(false);
    }

    if (e.target.value.length === POSITION && guest.country === COUNTRY_CANADA) {
      const newValue = [e.target.value.slice(ZERO, POSITION), e.target.value.slice(POSITION)].join(
        ' ',
      );

      this.props.setCondoGuest(
        updateCondoGuest(guest, { zipCode: newValue }) as ICondoGuestBooking,
      );

      if (isAddressAsPrimary === 'true') {
        this.props.setCondoCard(
          updateCondoGuest(card, { zipCode: e.target.value } , false) as ICondoCardBooking,
        );
        this.handleUpdateAddress();
      }
    } else {
      this.props.setCondoGuest(
        updateCondoGuest(guest, { zipCode: e.target.value }) as ICondoGuestBooking,
      );

      if (isAddressAsPrimary === 'true') {
        this.props.setCondoCard(
          updateCondoGuest(card, { zipCode: e.target.value } , false) as ICondoCardBooking,
        );
        this.handleUpdateAddress();
      }
    }
  };

  getErrorMessagePostalCode = () => {
    const { guest, condoBookingSummary } = this.props.condoReviewBookStore;
    const { condosCountriesCode } = this.props.condosStaticStore;
    const { supplierType } = condoBookingSummary;

    const countryCode =
      supplierType !== SupplierType.Intervals
        ? condosCountriesCode.rciCountries?.find((item: any) => item.name === guest?.country)
        : condosCountriesCode.intervalCountries?.find((item: any) => item.name === guest?.country);

    if (!isEmpty(countryCode) && supplierType !== SupplierType.Intervals) {
      const stateData = countryCode.states?.find((value: any) => value.code === guest.stateCode);

      if ('zipRuleType' in countryCode && countryCode?.zipRuleType === ZipRuleTypeEnum.startsWith) {
        return (
          <FormattedMessage
            id="error.message.postal.code.start.with"
            values={{ value: stateData?.zipRule }}
          />
        );
      } else if (
        'zipRuleType' in countryCode &&
        countryCode.zipRuleType === ZipRuleTypeEnum.range
      ) {
        return (
          <FormattedMessage
            id="error.message.postal.code.range"
            values={{ value: stateData?.zipRule }}
          />
        );
      } else {
        return <FormattedMessage id="error.message.postal.code" />;
      }
    }
  };

  componentDidMount() {
    const { menuStore, isSomeoneElse } = this.props;
    const { guest, condoBookingSummary } = this.props.condoReviewBookStore;
    const guestInfo = localStorage.getItem(CONDO_BOOKING_GUEST_INFO_LABEL);
    let guestFromStorage: ICondoGuestBooking | undefined = undefined;

    if (guestInfo) {
      try {
        guestFromStorage = JSON.parse(guestInfo);
      } catch (error) {
        console.error(error);
      }
    }

    const resultData = guestFromStorage ? guestFromStorage : {};

    this.props.setCondoGuest(
      updateCondoGuest(guest, {
        phone: setNumberPhone(
          menuStore.items?.memberType === GUEST_TYPE_MEMBER && !isSomeoneElse
            ? getMemberPhone(menuStore?.items?.memberType, condoBookingSummary.mainGuest.phone)
            : guest.phone,
        ),
        ...resultData,
      }) as ICondoGuestBooking,
    );

    if (guestFromStorage) {
      setTimeout(() => {
        this.props.formRef?.current?.setFieldsValue({
          prefixName: guestFromStorage.namePrefix,
          givenName: guestFromStorage.givenName,
          surname: guestFromStorage.surname,
          phone: guestFromStorage.phone,
          primaryAddressLine1: guestFromStorage.addressLine1,
          primaryAddressLine2: guestFromStorage.addressLine2,
          primaryCity: guestFromStorage.city,
          primaryCountry: guestFromStorage.country,
          primaryState: guestFromStorage.stateCode,
          primaryPostalCode: guestFromStorage.zipCode,
        });
      });
    }
  }

  onChangeSavedMmeber = (selection: MemberChangeValue) => {
    const { guest } = this.props.condoReviewBookStore;

    this.props.setCondoGuest(
      updateCondoGuest(guest, { 
        givenName: selection.firstName,
        surname: selection.lastName,
        phone: setNumberPhone(selection.phone),
        email: selection.email,
      }) as ICondoGuestBooking,
    );
  }

  render(): React.ReactNode {
    const { Option } = Select;
    const { intl, condoReviewBookStore, insuranceStore, onChangeSomeoneElse } = this.props;
    const { condosCountriesCode } = this.props.condosStaticStore;
    const { guest, condoBookingSummary, errorsField, isErrorMessageZipCode, loadingBooking } = condoReviewBookStore;
    const { bookingCard, supplierType } = condoBookingSummary;
    const { bedRoomType, totalGuests, totalChildren, totalBathrooms, unitName } = bookingCard;

    const countryStates = guest ? getState(guest.country, condosCountriesCode, supplierType) : null;

    const loadingQuote = insuranceStore?.loading;

    return (
      <div className="condo-guest-info" ref={this.wrapperRef}>
        <div className="condo-guest-info__header">
          <h4 className="condo-guest-info__title">
            <FormattedMessage id="guest.info" />
          </h4>
        </div>

        <div className="condo-guest-info__unit-info">
          <h4 className="condo-guest-info__unit-title capitalize">{unitName}</h4>
          <p className="condo-guest-info__unit-description">
            {totalGuests} <FormattedMessage id="adults" />
            {totalChildren !== ZERO ? (
              <>
                {', '}
                {totalChildren} <FormattedMessage id="children" />
              </>) : null}
            {(bedRoomType || totalBathrooms) ? ', ' : null}
            {bedRoomType}
            {totalBathrooms ? (
              <>
                {', '}
                <FormattedMessage id="bathrooms.count" values={{ count: totalBathrooms }} />
              </>) : null}
          </p>
        </div>

        <SavedMembers onMemberChange={this.onChangeSavedMmeber} />

        <div className="condo-guest-info__wrapper">
          <p className="condo-guest-info__guest-title capitalize">
            <FormattedMessage id="main.guest.title" />
          </p>
          <Checkbox onChange={onChangeSomeoneElse} checked={guest.isBookingForSomeoneElse || false} disabled={loadingBooking}>
            {' '}
            <FormattedMessage id="booking.someone.else" />
          </Checkbox>
          <div className="condo-guest-info__block-wrapper">
            <Form.Item
              name="prefixName"
              className="prefixName"
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: 'error.message.prefix.name' }),
                },
              ]}
            >
              <div className="condo-guest-info__select-wrapper prefix-name">
                <Select
                  value={guest.namePrefix}
                  onChange={(value) => {
                    this.onChangeNamePrefix(value);
                  }}
                  disabled={loadingBooking}
                >
                  {namesPrefix.map((prefix, index) => (
                    <Option key={index} value={prefix.namePrefix}>
                      {prefix.namePrefix}
                    </Option>
                  ))}
                </Select>
              </div>
            </Form.Item>
            <Form.Item
              name="givenName"
              rules={[
                {
                  required: true,
                  max: supplierType !== SupplierType.Intervals ? MAX_LENGTH_NAME : MAX_LENGTH_INTERVALS,
                  pattern: checkInLatinLetters,
                  message: intl.formatMessage({ id: 'error.message.contact.name' }),
                },
              ]}
            >
              <div className="condo-guest-info__name-input-wrapper">
                <span className="condo-guest-info__input-label">
                  <FormattedMessage id="first.name" />
                </span>
                <div className="condo-guest-info__input">
                  <Input
                    placeholder={intl.formatMessage({ id: 'first.name' })}
                    value={guest.givenName}
                    maxLength={
                      supplierType !== SupplierType.Intervals ? MAX_LENGTH_NAME : MAX_LENGTH_INTERVALS
                    }
                    onChange={(e) => {
                      this.onChangeFirstName(e);
                    }}
                    disabled={loadingBooking}
                  />
                </div>
              </div>
            </Form.Item>
            <Form.Item
              name="surname"
              validateTrigger="onBlur"
              rules={[
                {
                  required: true,
                  max:
                    supplierType !== SupplierType.Intervals ? MAX_LENGTH_SURNAME : MAX_LENGTH_INTERVALS,
                  pattern: checkInLatinLetters,
                  message: intl.formatMessage({ id: 'error.message.contact.name' }),
                },
                {
                  min: supplierType !== SupplierType.Intervals ? MIN_LENGTH_SURNAME : undefined,
                  message: intl.formatMessage({ id: 'error.message.contact.name.minimum.letters' }),
                },
              ]}
            >
              <div className="condo-guest-info__name-input-wrapper">
                <span className="condo-guest-info__input-label">
                  <FormattedMessage id="last.name" />
                </span>
                <div className="condo-guest-info__input">
                  <Input
                    placeholder={intl.formatMessage({ id: 'last.name' })}
                    value={guest.surname}
                    maxLength={
                      supplierType !== SupplierType.Intervals ? MAX_LENGTH_SURNAME : MAX_LENGTH_INTERVALS
                    }
                    onChange={(e) => {
                      this.onChangeLastName(e);
                    }}
                    disabled={loadingBooking}
                  />
                </div>
              </div>
            </Form.Item>
          </div>
          <Form.Item
            name={'phone'}
            validateTrigger="onBlur"
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'error.message.site.phone' }),
              },
              {
                max: MAX_LENGTH_NUMBER,
                message: intl.formatMessage({ id: 'error.message.number.must.be' }),
              },
            ]}
          >
            <div className="condo-guest-info__name-input-wrapper">
              <span className="condo-guest-info__input-label">
                <FormattedMessage id="mobile.phone" />
              </span>
              <div className="condo-guest-info__input">
                <Input.Group compact>
                  <Form.Item
                    name={['phone', 'code']}
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <div>
                      <Select
                        defaultValue={`${countriesCode[ZERO].isoCode} ${countriesCode[ZERO].dialCode}`}
                        onChange={(value) => {
                          this.onChangePhoneCountryCode(value);
                        }}
                        getPopupContainer={this.getPopupContainer as (props: any) => HTMLElement}
                        disabled={loadingBooking}
                        optionFilterProp="label"
                        showSearch={true}
                        options={countriesCode.map(code => ({
                          value: code.dialCode,
                          label: `${code.isoCode} ${code.dialCode}`
                        }))}
                      >
                      </Select>
                    </div>
                  </Form.Item>
                  <Form.Item
                    name={['phone', 'number']}
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <div className="condo-guest-info__phone_number">
                      <Input
                        placeholder={intl.formatMessage({ id: 'phone.number' })}
                        onChange={(e) => {
                          this.onChangePhone(e);
                        }}
                        value={guest.phone}
                        maxLength={12}
                        disabled={loadingBooking}
                      />
                    </div>
                  </Form.Item>
                </Input.Group>
              </div>
            </div>
          </Form.Item>

          <div className="condo-guest-info__guest-message">
            <FormattedMessage id="name.changes" />
          </div>

          <div className="condo-guest-info__primary-address-wrapper">
            <p className="condo-guest-info__primary-address-title">
              <FormattedMessage id="primary.address" />
            </p>
            <Form.Item
              name="primaryAddressLine1"
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: 'error.message.address' }),
                },
              ]}
            >
              <div className="condo-guest-info__name-input-wrapper">
                <span className="condo-guest-info__input-label">
                  <FormattedMessage id="address.line" values={{ number: 1 }} />
                </span>
                <div className="condo-guest-info__input">
                  <Input
                    placeholder={intl.formatMessage({ id: 'street.and.number' })}
                    value={guest.addressLine1}
                    maxLength={MAX_LENGTH_ADDRESS}
                    onChange={(e) => {
                      this.onChangeAddressLine1(e);
                    }}
                    disabled={loadingBooking}
                  />
                </div>
              </div>
            </Form.Item>
            <Form.Item name="primaryAddressLine2" rules={[{ required: false }]}>
              <div className="condo-guest-info__name-input-wrapper">
                <span className="condo-guest-info__input-label">
                  <FormattedMessage id="address.line" values={{ number: 2 }} />
                </span>
                <div className="condo-guest-info__input">
                  <Input
                    placeholder={intl.formatMessage({ id: 'address.optional' })}
                    value={guest.addressLine2}
                    maxLength={MAX_LENGTH_ADDRESS}
                    onChange={(e) => {
                      this.onChangeAddressLine2(e);
                    }}
                    disabled={loadingBooking}
                  />
                </div>
              </div>
            </Form.Item>
            <Form.Item
              name="primaryCity"
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: 'error.message.city' }),
                  pattern: checkInLatinLetters,
                },
              ]}
            >
              <div className="condo-guest-info__name-input-wrapper">
                <span className="condo-guest-info__input-label">
                  <FormattedMessage id="city" />
                </span>
                <div className="condo-payment__input">
                  <Input
                    placeholder={intl.formatMessage({ id: 'city' })}
                    maxLength={
                      supplierType !== SupplierType.Intervals ? MAX_LENGTH : MAX_LENGTH_CITY_FOR_INTERVALS
                    }
                    value={guest.city}
                    onChange={this.onChangePrimaryCity}
                    disabled={loadingBooking}
                  />
                </div>
              </div>
            </Form.Item>
            <Form.Item
              name="primaryCountry"
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: 'error.message.country' }),
                },
              ]}
            >
              <div className="condo-guest-info__name-input-wrapper">
                <span className="condo-guest-info__input-label">
                  <FormattedMessage id="country" />
                </span>
                <div className="condo-payment__input">
                  <Select
                    placeholder={hideBrowserAutoFill(
                      intl.formatMessage({ id: 'country' }),
                      SECOND_CHARACTER_POSITION,
                    )}
                    value={guest.country || null}
                    onChange={this.onChangePrimaryCountry}
                    disabled={loadingBooking || loadingQuote}

                    optionFilterProp="label"
                    showSearch={true}
                    options={supplierType !== SupplierType.Intervals ?
                      condosCountriesCode?.rciCountries?.map(
                        (item: IRciCountriesCodes) => ({
                          value: item.name,
                          label: item.name,
                        })) : 
                      condosCountriesCode?.intervalCountries?.map(
                        (item: IIntervalCountriesCodes) => ({
                          value: item.name,
                          label: item.name,
                        }))}
                  >
                  </Select>
                </div>
              </div>
            </Form.Item>

            <Form.Item
              name="primaryState"
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: 'error.message.simple.state' }),
                  pattern: checkInLatinLetters,
                },
              ]}
            >
              <div className="condo-guest-info__name-input-wrapper">
                <span className="condo-guest-info__input-label">
                  <FormattedMessage id="state" />
                </span>
                <div className="condo-payment__input">
                  {!isEmpty(countryStates) ? (
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        (option?.children as any)?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder={hideBrowserAutoFill(
                        intl.formatMessage({ id: 'select.state' }),
                        TENTH_CHARACTER_POSITION,
                      )}
                      value={guest?.stateCode || null}
                      onChange={this.onChangePrimaryState}
                      disabled={loadingBooking || loadingQuote}
                    >
                      {countryStates?.map(
                        (item, index) => {
                          return (
                            <Select.Option key={index} value={item.code}>
                              {item.name}
                            </Select.Option>
                          );
                        },
                      )}
                    </Select>) : (
                    <Input
                      placeholder={intl.formatMessage({ id: 'error.message.simple.state' })}
                      value={guest?.stateCode || NULL_VALUE}
                      onChange={this.onChangePrimaryStateInput}
                      disabled={loadingBooking}
                    />)}
                </div>
              </div>
            </Form.Item>

            <Form.Item
              name="primaryPostalCode"
              rules={[
                {
                  required: true,
                  message: this.getErrorMessagePostalCode(),
                },
                {
                  max:
                    guest.country === COUNTRY_CANADA
                      ? MAX_LENGTH_VALID_POST_CODE_CANADA
                      : MAX_LENGTH_VALID_POST_CODE,
                  message:
                    guest.country === COUNTRY_CANADA
                      ? intl.formatMessage({ id: 'error.message.postal.code.length.canada' })
                      : intl.formatMessage({ id: 'error.message.postal.code.length' }),
                },
                {
                  validator: async () => {
                    await setValidator(errorsField, 'guests[0]', isErrorMessageZipCode);
                  },
                },
              ]}
            >
              <div className="condo-guest-info__name-input-wrapper postal-code-input">
                <span className="condo-guest-info__input-label">
                  <FormattedMessage id="postal-code" />
                </span>
                <div className="condo-payment__input">
                  <Input
                    placeholder={intl.formatMessage({ id: 'postal.code.label' })}
                    onChange={this.onChangePrimaryPostalCode}
                    value={guest.zipCode}
                    max={
                      guest.country === COUNTRY_CANADA
                        ? MAX_LENGTH_VALID_POST_CODE_CANADA
                        : MAX_LENGTH_VALID_POST_CODE
                    }
                    disabled={loadingBooking}
                  />
                </div>
              </div>
            </Form.Item>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    condoReviewBookStore: state.condoReviewBookStore,
    condosStaticStore: state.condosStaticStore,
    menuStore: state.navigationMenuStore,
    insuranceStore: state.insuranceStore,
    loginStore: state.loginStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  setCondoGuest,
  setCondoCard,
  setErrorMessageZipCode,
  refreshInsuranceQuote,
  resetInsurance: insuranceActions.reset,
};

export const CondoGuestInfo = connect(mapStateToProps, mapDispatchToProps)(injectIntl(CondoGuestInfoComponent));
