import React from 'react';

import { Modal, Spin, Button, Form, Input } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { BlueButton } from '@share/components';
import { DownLoadShareType, HandleDownload, HandleShare } from '@share/api';

import './style.scss';

interface IProps extends RouteComponentProps, WrappedComponentProps {
  visible: boolean;
  isShare: boolean;

  type: DownLoadShareType;

  convertData: (members: any, comment: string) => void;
  onCancel: () => void;
  onLoading: (loading: boolean) => void;
}

interface IState {
  loading: boolean;
  comment: string;
  members: string[];
}

class ModalShareComponent extends React.Component<IProps, IState> {

  state: IState = { loading: false, comment: '', members: [] };

  onChange = (e: any) => {
    this.setState({ comment: e.target.value });
  }

  handleDownload = () => {
    const loading = this.state.loading;
    this.setState({ loading: true }, () => this.props.onLoading(true));
    HandleDownload(
      this.props.type,
      loading,
      this.props.convertData(this.state.members, this.state.comment),
      this.props.intl,
      () => this.setState({ loading: false }, () => {
        this.props.onLoading(false);
        this.props.onCancel();
      }),
      () => this.setState({ comment: '', members: []}));
  }

  handleShare = () => {
    const loading = this.state.loading;
    this.setState({ loading: true }, () => this.props.onLoading(true));
    HandleShare(
      this.props.type,
      loading,
      this.props.convertData(this.state.members, this.state.comment),
      this.props.intl,
      () => this.setState({ loading: false }, () => {
        this.props.onLoading(false);
        this.props.onCancel();
      }),
      () => this.setState({ comment: '', members: []}));
  }

  onFinish = (values: any) => {
    this.setState({ members: values.names });
    this.handleShare();
  };

  render(): React.ReactNode {
    const { TextArea } = Input;
    const { visible, isShare } = this.props;
    const { comment, loading } = this.state;
    const formItemLayoutWithOutLabel = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 20, offset: 4 },
      },
    };

    return (
      <Modal
        className="modal-share"
        visible={visible}
        centered
        footer={null}
        onCancel={() => this.setState({ comment: '' }, () => this.props.onCancel())}
        wrapClassName="modal-share__wrapper"
        width={500}
      >
        <div>
          {isShare ?
            <>
              <h4 className="modal-share__title">
                Enter MemberID or Member Email
              </h4>
              <div className="modal-share__hotels">
                <Form name="dynamic_form_item" style={{ width: '100%' }} {...formItemLayoutWithOutLabel} onFinish={this.onFinish}>
                  <Form.List
                    name="names"
                    rules={[
                      {
                        validator: async (_, names) => {
                          if (!names || names.length < 1) {
                            return Promise.reject(new Error('At least 1 recipient'));
                          }
                        },
                      },
                    ]}
                  >
                    {(fields, { add, remove }, { errors }) => (
                      <>
                        {fields.map((field) => (
                          <Form.Item
                            {...(formItemLayoutWithOutLabel)}
                            required={false}
                            key={field.key}
                          >
                            <Form.Item
                              {...field}
                              validateTrigger={['onChange', 'onBlur']}
                              rules={[
                                {
                                  required: true,
                                  whitespace: true,
                                  message: "Please input MemberID/Member Email or delete this field.",
                                },
                              ]}
                              noStyle
                            >
                              <Input placeholder='Please input MemberID or Member Email' style={{ width: '90%' }} />
                            </Form.Item>
                            {fields.length > 1 ? (
                              <MinusCircleOutlined
                                className="dynamic-delete-button"
                                onClick={() => remove(field.name)}
                              />
                            ) : null}
                          </Form.Item>
                        ))}
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            style={{ width: '90%' }}
                            icon={<PlusOutlined />}
                          >
                            Add another MemberID or Member Email
                          </Button>
                          <Form.ErrorList errors={errors} />
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                  <h4 className="modal-share__title">
                    <FormattedMessage id="results.hotel.card.compare.modal.comment.title" />
                  </h4>
                  <div className="modal-share__hotels">
                    <TextArea rows={4} value={comment || ''} onChange={this.onChange} />
                  </div>

                  <div className="modal-share__buttons">
                    <BlueButton disabled={loading} htmlType="submit">
                      {loading ? (<Spin />) : <FormattedMessage id="results.hotel.card.compare.modal.share" />}
                    </BlueButton>
                  </div>
                </Form>
              </div>
            </>
            : null
          }

          {!isShare ?
            <>
              <h4 className="modal-share__title">
                <FormattedMessage id="results.hotel.card.compare.modal.comment.title" />
              </h4>
              <div className="modal-share__hotels">
                <TextArea rows={4} value={comment || ''} onChange={this.onChange} />
              </div>

              <div className="modal-share__buttons">
                <BlueButton disabled={loading} onClick={this.handleDownload}>
                  {loading ? (<Spin />) : <FormattedMessage id="results.hotel.card.compare.modal.download" />}
                </BlueButton>
              </div>
            </>: null}
        </div>
      </Modal>)
  }
}

export const ModalShare = connect()(injectIntl(withRouter(ModalShareComponent)));