import React from 'react';
import debounce from 'lodash/debounce';
import { Link } from 'react-router-dom';
import { Input } from 'antd';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { LabeledValue } from 'antd/lib/select';
import { ThunkDispatch } from 'redux-thunk';
import { FormattedMessage } from 'react-intl';
import { SearchOutlined } from '@ant-design/icons';
import { RootState, UrlUtils } from '@share/utils';
import { FilterLocations } from '@components';
import { BlueButton, CustomSelect } from '@share/components';
import {
  getAllInclusiveSortOptions,
  GETAWAY_BACKGROUND_TRANSPARENT,
  GetGetawaysLocations,
  getGetawaySortOptions,
  renderGetawayListHeaderInfo,
} from '@utils';
import {
  getGetaways,
  getGetawaysLocations,
  getawaysActions,
  IGetawaysState,
  SubCategory,
} from '@store/slices';
import { IBenefitsState, IMenuState } from '@share/store/slices';
import {
  GetGetawaysArgs,
  IGetawaysLocations,
  defaultLocationValue,
  GetawaysLocationEnum,
  defaultLocationCountriesValue,
} from '@share/common-types';
import { Routes, SortTypes } from '@share/constants';
import { APP_SELECTOR, DEFAULT_PAGE_SIZE } from '@constants';
import { GETAWAY_LOCATION, GETAWAY_SORT_ORDER } from '@share/constants';
import { ILoginState } from '@share/store/slices';

import { GetawayListCard } from '../getaway-list-card';
import { GetawayListSkeleton } from '../getaway-list-skeleton';
import { GetawayListCardSkeleton } from '../getaway-list-card-skeleton';

import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const ZERO = 0;
const ONE = 1;
const THREE = 3;
const FOUR = 4;
const DEBOUNCE_TIME = 300;
const DEBOUNCE_TIME_SEARCH = 500;
const SCROLL_PIXELS = 620;

interface IMapStateToProps {
  getawaysStore: IGetawaysState;
  benefitsStore: IBenefitsState;
  menuStore: IMenuState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  getGetaways: GetGetawaysArgs;
  getGetawaysLocations: (getaway: string, subCategory: SubCategory) => void;
  setSortType: (sort: SortTypes, subCategory: SubCategory) => void;
  setFiltrationType: (type: IGetawaysLocations, subCategory: SubCategory) => void;
  setFiltrationTypeCountry: (country: IGetawaysLocations, subCategory: SubCategory) => void;
  setPageNumber: (page: number, subCategory: SubCategory) => void;
  setPromoId: (promoId: string, subCategory: SubCategory) => void;
  setGetawaysListPage: (listPage: boolean, subCategory: SubCategory) => void;
  setDefaultValues: (subCategory: SubCategory) => void;
  resetFilters: (subCategory: SubCategory) => void;
  setActiveGetawaySubCategory: (subCategory: SubCategory) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, WrappedComponentProps {
  isInternal: boolean;

  isFromProperty?: boolean;
  getaway?: string;
  condoSubcategory?: string;

  isHome?: boolean;
  isSlider?: boolean;
  isAllInclusive?: boolean;
}

interface IState {
  isCondo: boolean;
  activeGetaway: string;
  activeCondoSubcategory: string;
  realTimeGetaway: SubCategory;
}

class GetawayListComponent extends React.Component<IProps, IState> {
  root: HTMLDivElement = document.querySelector(APP_SELECTOR);
  wrapperRef: React.RefObject<HTMLDivElement> = React.createRef();
  prevWrapperRef: any = null;

  state: IState = {
    isCondo: false,
    activeGetaway: null,
    activeCondoSubcategory: null,
    realTimeGetaway: null,
  };

  onScroll = debounce(() => {
    this.handleDeals(false);
  }, DEBOUNCE_TIME);

  handleDeals = (skipElement: boolean) => {
    const { isCondo, activeGetaway, activeCondoSubcategory, realTimeGetaway } = this.state;
    const { isSlider, setPageNumber, getawaysStore } = this.props;

    const { getaways, loadingGetaways, getawaysTotalCount, loadingMoreGetaways } = getawaysStore[
      realTimeGetaway
    ];

    if (
      !loadingGetaways &&
      !loadingMoreGetaways &&
      getaways.length &&
      getaways.length !== getawaysTotalCount &&
      getaways.length % DEFAULT_PAGE_SIZE === ZERO &&
      this.root &&
      (skipElement ||
        (!isSlider &&
          window.innerHeight + window.scrollY + SCROLL_PIXELS >= this.root.offsetHeight) ||
        (isSlider &&
          this.wrapperRef.current.scrollLeft + this.wrapperRef.current.offsetWidth >=
            this.wrapperRef.current.scrollWidth))
    ) {
      setPageNumber(undefined, realTimeGetaway);
      this.handleGetaways(activeGetaway, isCondo ? activeCondoSubcategory : null, true);
    }
  };

  componentDidMount() {
    const values = UrlUtils.getValues();
    const {
      isSlider,
      isHome,
      isFromProperty,
      getaway,
      condoSubcategory,
      loginStore,
      menuStore,
      setSortType,
      setFiltrationType,
      setFiltrationTypeCountry,
      setGetawaysListPage,
      getGetawaysLocations,
      setActiveGetawaySubCategory,
    } = this.props;
    const { user, account } = loginStore;
    const { items } = menuStore;
    const queryGetawayLocation = values[GETAWAY_LOCATION];
    const queryGetawaySortOrder = values[GETAWAY_SORT_ORDER];
    const activeGetaway = isFromProperty ? getaway : location.pathname.split('/')[THREE];
    const activeCondoSubcategory = isFromProperty
      ? condoSubcategory
      : location.pathname.split('/')[FOUR];

    const realTimeGetaway = (activeCondoSubcategory ?? activeGetaway) as SubCategory;

    const isCondo =
      activeGetaway === GetawaysLocationEnum.Condo.toLowerCase() ||
      activeGetaway === GetawaysLocationEnum.Condos.toLowerCase();
    const isRSITemplate = account?.isRSITemplate;

    setGetawaysListPage(true, realTimeGetaway);
    this.setState({ isCondo, activeGetaway, activeCondoSubcategory, realTimeGetaway });

    setActiveGetawaySubCategory(activeCondoSubcategory as SubCategory);

    setFiltrationTypeCountry(undefined, realTimeGetaway);

    if (queryGetawayLocation) {
      setFiltrationType(queryGetawayLocation as IGetawaysLocations, realTimeGetaway);
    }

    if (queryGetawaySortOrder) {
      setSortType(queryGetawaySortOrder as SortTypes, realTimeGetaway);
    }

    if (isSlider) {
      this.prevWrapperRef = this.wrapperRef.current;
    } else {
      if (!isHome) {
        window.addEventListener('scroll', this.onScroll);
      }
    }

    if (user) {
      if (!isRSITemplate) {
        getGetawaysLocations(activeGetaway, realTimeGetaway);
        this.handleGetaways(activeGetaway, isCondo ? activeCondoSubcategory : null);
      } else if (items) {
        getGetawaysLocations(activeGetaway, realTimeGetaway);
        this.handleGetaways(activeGetaway, isCondo ? activeCondoSubcategory : null);
      }
    }
  }

  handleGetaways = (
    getaway: string,
    subCategory?: string,
    isLoadMore?: boolean,
    isSearch?: boolean,
  ) => {
    this.props.getGetaways(getaway, subCategory as SubCategory, isLoadMore, isSearch);
  };

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    const { activeGetaway, isCondo, activeCondoSubcategory } = this.state;
    const { loginStore, menuStore, getGetawaysLocations, setActiveGetawaySubCategory } = this.props;
    const { user, account } = loginStore;
    const { items } = menuStore;

    const isRSITemplate = account?.isRSITemplate;

    if (!prevProps?.loginStore?.user && user && !isRSITemplate) {
      getGetawaysLocations(activeGetaway, isCondo ? (activeCondoSubcategory as SubCategory) : null);
      this.handleGetaways(activeGetaway, isCondo ? activeCondoSubcategory : null);
      setActiveGetawaySubCategory(activeCondoSubcategory as SubCategory);
    }

    if (!prevProps?.menuStore?.items && items && isRSITemplate) {
      getGetawaysLocations(activeGetaway, isCondo ? (activeCondoSubcategory as SubCategory) : null);
      this.handleGetaways(activeGetaway, isCondo ? activeCondoSubcategory : null);
      setActiveGetawaySubCategory(activeCondoSubcategory as SubCategory);
    }

    if (this.prevWrapperRef !== this.wrapperRef.current) {
      this.wrapperRef.current?.addEventListener('scroll', this.onScroll);
    }

    this.prevWrapperRef = this.wrapperRef.current;
  }

  componentWillUnmount() {
    const { realTimeGetaway } = this.state;
    const { setGetawaysListPage, setDefaultValues } = this.props;
    setGetawaysListPage(false, realTimeGetaway);
    setDefaultValues(realTimeGetaway);

    window.removeEventListener('scroll', this.onScroll);
    this.wrapperRef?.current?.removeEventListener('scroll', this.onScroll);
  }

  onSearchHandler = debounce(({ target }) => {
    const { realTimeGetaway } = this.state;
    const { value } = target;
    const { setPromoId, setPageNumber } = this.props;
    const { isCondo, activeGetaway, activeCondoSubcategory } = this.state;

    setPromoId(value, realTimeGetaway);

    setPageNumber(ONE, realTimeGetaway);
    this.handleGetaways(activeGetaway, isCondo ? activeCondoSubcategory : null, false, true);
  }, DEBOUNCE_TIME_SEARCH);

  onFilterChange = (value: string): void => {
    const { realTimeGetaway } = this.state;
    const { getawaysStore, setFiltrationType, setPageNumber } = this.props;
    const { isCondo, activeGetaway, activeCondoSubcategory } = this.state;
    const activeFilterOption = getawaysStore[realTimeGetaway].getawaysLocations.find(
      (item) => item.city === value,
    );

    const selectedFiltrationType =
      activeFilterOption.city === defaultLocationValue ? undefined : activeFilterOption;

    setFiltrationType(selectedFiltrationType, realTimeGetaway);
    UrlUtils.setUrl(GETAWAY_LOCATION, selectedFiltrationType);

    setPageNumber(ONE, realTimeGetaway);
    this.handleGetaways(activeGetaway, isCondo ? activeCondoSubcategory : null);
  };

  onCountryFilterChange = (value: string): void => {
    const { realTimeGetaway } = this.state;
    const {
      getawaysStore,
      setFiltrationTypeCountry,
      setFiltrationType,
      setPageNumber,
    } = this.props;
    const { isCondo, activeGetaway, activeCondoSubcategory } = this.state;

    const activeFilterOption = getawaysStore[realTimeGetaway].getawaysLocationsCountries.find(
      (item) => item.isoCountry === value,
    );

    const selectedFiltrationType =
      activeFilterOption.city === defaultLocationCountriesValue ? undefined : activeFilterOption;

    const getawaysLocations = GetGetawaysLocations(
      selectedFiltrationType,
      getawaysStore[realTimeGetaway].getawaysLocationsOriginal,
    );
    const filtrationType = getawaysLocations?.length === 1 ? getawaysLocations[0] : undefined;

    setFiltrationTypeCountry(selectedFiltrationType, realTimeGetaway);
    setFiltrationType(filtrationType, realTimeGetaway);

    setPageNumber(ONE, realTimeGetaway);
    this.handleGetaways(activeGetaway, isCondo ? activeCondoSubcategory : null);
  };

  onSortChange = (value: SortTypes): void => {
    const { realTimeGetaway } = this.state;
    const { setSortType, setPageNumber } = this.props;
    const { isCondo, activeGetaway, activeCondoSubcategory } = this.state;
    setSortType(value, realTimeGetaway);
    UrlUtils.setUrl(GETAWAY_SORT_ORDER, value);
    setPageNumber(ONE, realTimeGetaway);
    this.handleGetaways(activeGetaway, isCondo ? activeCondoSubcategory : null);
  };

  getSortOptions = (): LabeledValue[] => {
    if (this.props.isAllInclusive) {
      return getAllInclusiveSortOptions();
    }

    return getGetawaySortOptions();
  };

  resetFilters = (): void => {
    const { realTimeGetaway } = this.state;
    const { resetFilters } = this.props;
    const { isCondo, activeGetaway, activeCondoSubcategory } = this.state;

    resetFilters(realTimeGetaway);
    this.handleGetaways(activeGetaway, isCondo ? activeCondoSubcategory : null);
  };

  handleScrollLeft = () => {
    const windowWidth = window.innerWidth;
    const padding = windowWidth > 580 ? 550 : windowWidth - 10;
    this.wrapperRef.current.scrollTo({
      top: 0,
      left: this.wrapperRef.current.scrollLeft - padding,
      behavior: 'smooth',
    });
  };
  handleScrollRight = () => {
    const windowWidth = window.innerWidth;
    const padding = windowWidth > 580 ? 550 : windowWidth - 10;
    this.wrapperRef.current.scrollTo({
      top: 0,
      left: this.wrapperRef.current.scrollLeft + padding,
      behavior: 'smooth',
    });
  };

  render(): React.ReactNode {
    const {
      benefitsStore,
      getawaysStore,
      intl,
      menuStore,
      isSlider,
      isHome,
      isAllInclusive,
    } = this.props;
    const { isCondo, activeGetaway, activeCondoSubcategory, realTimeGetaway } = this.state;

    if (!realTimeGetaway) {
      return null;
    }

    const {
      promoId,
      getaways,
      sortType,
      filterType,
      filterTypeCountry,
      loadingGetaways,
      getawaysLocations,
      getawaysLocationsCountries,
      getawaysTotalCount,
      loadingMoreGetaways,
      loadingGetawaysLocations,
    } = getawaysStore[realTimeGetaway];
    const phone = menuStore?.items?.phone;
    const headerInfo = renderGetawayListHeaderInfo(
      isCondo ? activeCondoSubcategory : activeGetaway,
    );

    const { loginStore } = this.props;
    const { account } = loginStore;

    const toLowercaseSpecialTypeName = activeGetaway?.toLowerCase();
    const isTypeNameStaycation = toLowercaseSpecialTypeName === GetawaysLocationEnum.Staycation;

    const fromHome = benefitsStore.benefits?.fromHome;

    const style: any = {};
    if (isSlider) {
      style.flexWrap = 'unset';
      style.flexDirection = 'row';
      style.overflowX = 'scroll';
    }

    const mainStyle: any = {};
    if (!isHome) {
      mainStyle.background = `url(${headerInfo?.headerImg}) center / cover no-repeat ${GETAWAY_BACKGROUND_TRANSPARENT}`;
    } else {
      if (!getaways?.length && !filterType?.city) {
        return null;
      }
    }

    return (
      <div className={isHome ? 'getaway__list-home-page' : ''}>
        <div className={`getaway__list-header ${isHome ? 'home-page' : ''}`} style={mainStyle}>
          <div className={`getaway__container ${isHome ? 'home-page' : ''}`}>
            <h1 className={`getaway__list-header_title ${isHome ? 'home-page' : ''}`}>
              {headerInfo && <FormattedMessage id={headerInfo.headerTitle} />}
            </h1>
            <p className={`getaway__list-header_subtitle ${isHome ? 'home-page' : ''}`}>
              {headerInfo && <FormattedMessage id={headerInfo?.headerSubtitle} />}
            </p>
          </div>
        </div>
        <div className="getaway__list first">
          {loadingGetaways ? (
            <GetawayListSkeleton isSlider={isSlider} />
          ) : (
            <div className={`getaway__container ${isHome ? 'home-page' : ''}`}>
              {!isHome ? (
                !isCondo ? (
                  <Link
                    className="getaway__list__back-link"
                    to={`/${account?.name}${fromHome ? Routes.Search : Routes.Getaway}`}
                  >
                    <FormattedMessage
                      id={fromHome ? 'confirmation.go.back_home' : 'getaways.back'}
                    />
                  </Link>
                ) : (
                  <Link
                    className="getaway__list__back-link"
                    to={`/${account?.name}${Routes.GetawayCondo}`}
                  >
                    <FormattedMessage id={'getaways.back.condo'} />
                  </Link>
                )
              ) : null}

              <div className="getaway__list-top">
                <div className={`getaway__list-filters ${isHome ? 'home-page' : ''}`}>
                  <div className="getaway__list-filters-filter">
                    <FilterLocations
                      value={filterType?.city}
                      options={getawaysLocations}
                      onChange={this.onFilterChange}
                      disabled={loadingGetawaysLocations || !getawaysLocations.length}
                      disableSort={isAllInclusive}
                    />

                    {getawaysLocationsCountries.length > 1 && menuStore?.items?.isMLM ? (
                      <FilterLocations
                        value={filterTypeCountry?.country}
                        options={getawaysLocationsCountries}
                        onChange={this.onCountryFilterChange}
                        disabled={loadingGetawaysLocations || !getawaysLocationsCountries.length}
                        getOptionValue={(obj: any) => {
                          return obj?.isoCountry;
                        }}
                        isCountryBased
                      />
                    ) : null}
                  </div>
                  {!isTypeNameStaycation && !isHome ? (
                    <div className="getaway__list-filters-search">
                      <Input
                        defaultValue={promoId}
                        onChange={this.onSearchHandler}
                        placeholder={intl.formatMessage({ id: 'search.promo.id' })}
                        prefix={<SearchOutlined />}
                      />
                    </div>
                  ) : null}
                </div>

                {!isHome || isAllInclusive ? (
                  <div className="getaway__list-sort">
                    <p className="getaway__list-sort_text">
                      <FormattedMessage id="sort.by" />
                      {':'}
                    </p>
                    <div className="getaway__list-sort_wrapper">
                      <CustomSelect
                        value={sortType}
                        onChange={this.onSortChange}
                        options={this.getSortOptions()}
                      />
                    </div>
                  </div>
                ) : null}
              </div>

              {!isHome ? (
                <div className="getaway__list-count_wrapper">
                  <p className="getaway__list-count">
                    {!!getawaysTotalCount && '+'}
                    {getawaysTotalCount} <FormattedMessage id="getaways" />
                  </p>
                  {(promoId || filterType) && (
                    <div onClick={this.resetFilters} className="getaway__list-reset">
                      <FormattedMessage id="reset.search" />
                    </div>
                  )}
                </div>
              ) : null}
            </div>
          )}
        </div>

        {!loadingGetaways ? (
          <div className="getaway__list last">
            <div className={`getaway__container ${isHome ? 'home-page' : ''}`}>
              {isSlider ? (
                <>
                  <div
                    className={`getaway__list-arrow-left ${
                      getaways.length ? `length_${getaways.length}` : ''
                    }`}
                    onClick={this.handleScrollLeft}
                  >
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </div>

                  <div
                    className={`getaway__list-arrow-right ${
                      getaways.length ? `length_${getaways.length}` : ''
                    }`}
                    onClick={this.handleScrollRight}
                  >
                    <FontAwesomeIcon icon={faChevronRight} />
                  </div>
                </>
              ) : null}

              <div
                className={`getaway__list-wrapper ${isSlider ? 'is-slider' : ''}`}
                style={style}
                ref={this.wrapperRef}
              >
                {getaways.length
                  ? getaways.map((getaway, index) => (
                      <GetawayListCard
                        key={`${getaway.title}-${!!getaway.promoId ? getaway.promoId : index}`}
                        data={getaway}
                        condoSubcategory={activeCondoSubcategory}
                        activeGetaway={activeGetaway}
                        isSlider={isSlider}
                      />
                    ))
                  : !loadingMoreGetaways && (
                      <p>
                        <FormattedMessage id="getaway.message" values={{ phone }} />
                      </p>
                    )}
                {loadingMoreGetaways && isSlider && (
                  <GetawayListCardSkeleton blocks={1} isSlider={isSlider} noMarginTop />
                )}
              </div>
              {loadingMoreGetaways && !isSlider && <GetawayListCardSkeleton />}
              {!isSlider && isHome && getaways.length !== getawaysTotalCount ? (
                <div className="getaway__list-load-more-container">
                  <BlueButton onClick={() => this.handleDeals(true)}>
                    <FormattedMessage id="deals.load.more" />
                  </BlueButton>
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    getawaysStore: state.getawaysStore,
    benefitsStore: state.benefitsStore,
    menuStore: state.navigationMenuStore,
    loginStore: state.loginStore,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, undefined, Action>,
): IMapDispatchToProps => ({
  getGetaways: (getaway, subCategory, isLoadMore, isSearch) => {
    dispatch(getGetaways(getaway, subCategory, isLoadMore, isSearch));
  },
  getGetawaysLocations: (getaway, subCategory) => {
    dispatch(getGetawaysLocations(getaway, subCategory as SubCategory));
  },
  setSortType: (value, subCategory) => {
    dispatch(getawaysActions.setSortType({ sort: value, subCategory }));
  },
  setFiltrationType: (value, subCategory) => {
    dispatch(getawaysActions.setFiltrationType({ type: value, subCategory }));
  },
  setFiltrationTypeCountry: (value, subCategory) => {
    dispatch(getawaysActions.setFiltrationTypeCountry({ country: value, subCategory }));
  },
  setPageNumber: (pageNumber, subCategory) => {
    dispatch(getawaysActions.setPageNumber({ page: pageNumber, subCategory }));
  },
  setPromoId: (promoId, subCategory) => {
    dispatch(getawaysActions.setPromoId({ promo: promoId, subCategory }));
  },
  setDefaultValues: (subCategory) => {
    dispatch(getawaysActions.setDefaultValues({ subCategory }));
  },
  setGetawaysListPage: (isGetawayListPage, subCategory) => {
    dispatch(getawaysActions.setGetawaysListPage({ listPage: isGetawayListPage, subCategory }));
  },
  resetFilters: (subCategory) => {
    dispatch(getawaysActions.resetFilters({ subCategory }));
  },
  setActiveGetawaySubCategory: (subCategory: SubCategory) => {
    dispatch(getawaysActions.setActiveGetawaySubCategory({ subCategory }));
  },
});

export const GetawayList = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(GetawayListComponent));
