import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import compose from 'lodash/fp/compose';
import format from 'date-fns/format';
import {
  Key,
  SorterResult,
  TablePaginationConfig,
  FilterDropdownProps,
} from 'antd/lib/table/interface';
import { Table, Tooltip, notification } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { formatDateCheckInOut, RootState } from '@share/utils';
import {
  getAdminBookings,
  getSubStatusList,
  adminBookingsActions,
  IAdminBookingsState,
  adminHotelBookingsActions,
} from '@store/slices';
import {
  IAdminBookings,
  BookingStatusEnum,
  IAdminBookingsMember,
  IBookingSubStatus,
  IAdminBookingGuest,
  IAdminBookingUnit,
  IBookingsCardDetails,
  IAdminBookingPrice,
  HotelBookingStatusEnum,
  IAutocompleteResult,
  MatchedByEnum,
} from '@common-types';
import {
  WhiteButton,
  CheckboxTableFilter,
  DateRangeTableFilter,
} from '@components';
import {
  BlueButton,
} from '@share/components';
import {
  BOOKING_SUPPLIER_TYPES_LABEL,
  BOOKING_TABLE_DATE_CHECK_IN_RANGE,
  BOOKING_TABLE_DATE_CHECK_OUT_RANGE,
  BOOKING_TABLE_PAGINATION,
  BOOKING_TABLE_SORT_ORDER,
  BOOKING_TABLE_SEARCH,
  BOOKING_SUB_STATUSES_LABEL,
  DATE_FORMAT_FOR_BOOKINGS,
  TIME_FORMAT,
  NULL_VALUE,
} from '@constants';
import {
  BOOKING_TABLE_DATE_BOOKED_ON_RANGE,
  BOOKING_STATUS_LABEL,
  BOOKING_TABLE_MATCHED_BY,
} from '@share/constants';

import { UrlUtils, renderNumber } from '@share/utils';
import { getCondosStatusByHotels } from '@utils';
import { RefreshSvg, InfoSvg, SuccessSmallSvg, ErrorSmallSvg } from '@assets';
import { CondoBookingsSearch } from '../../../condo-booking-search';
import { BookingsStatus } from '../../../booking-status';
import { TableHeader } from '../table-hader';
import { Routes } from '@share/constants';

import './style.scss';

interface IMapStateToProps {
  adminBookingsStore: IAdminBookingsState;
}

interface IMapDispatchToProps {
  getAdminBookings: () => void;
  getSubStatusList: () => void;
  resetFilters: () => void;
  resetHotelBookingsFilters: () => void;
  setPagination: (pagination: TablePaginationConfig) => void;
  setSort: (sort: SorterResult<IAdminBookings>) => void;
  setSearch: (search: string) => void;
  setCheckInDateRangeFilter: (range: { from: string; to: string }) => void;
  setCheckOutDateRangeFilter: (range: { from: string; to: string }) => void;
  setBookedOnDateRangeFilter: (range: { from: string; to: string }) => void;
  setStatusesFilters: (val: string[]) => void;
  setSubStatusesFilters: (val: string[]) => void;
  setSupplierTypesFilters: (val: string[]) => void;
  setSelectedSearchItem: (result: IAutocompleteResult) => void;
}

interface IProps
  extends IMapStateToProps,
    IMapDispatchToProps,
    RouteComponentProps,
    WrappedComponentProps {
  showModalExportCsv: () => void;
}

const zero = 0;
const one = 1;
const three = 3;
const numsToShow = 7;
const emptyData = '-';
const pageSizeOptions = ['10', '20', '50'];

class CondoBookingsComponent extends React.Component<IProps> {
  onTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, (Key | boolean)[] | null>,
    sorter: SorterResult<IAdminBookings>,
  ): void => {
    if (filters) {
      if (filters.checkIn && filters.checkIn[zero]) {
        const [from, to] = filters.checkIn[zero].toString().split('~');
        this.props.setCheckInDateRangeFilter({ from, to });
      } else {
        this.props.setCheckInDateRangeFilter(NULL_VALUE);
      }

      if (filters.checkOut && filters.checkOut[zero]) {
        const [from, to] = filters.checkOut[zero].toString().split('~');
        this.props.setCheckOutDateRangeFilter({ from, to });
      } else {
        this.props.setCheckOutDateRangeFilter(NULL_VALUE);
      }

      if (filters.bookedOn && filters.bookedOn[zero]) {
        const [from, to] = filters.bookedOn[zero].toString().split('~');
        this.props.setBookedOnDateRangeFilter({ from, to });
      } else {
        this.props.setBookedOnDateRangeFilter(NULL_VALUE);
      }

      if (filters.supplierType && filters.supplierType.length) {
        this.props.setSupplierTypesFilters(filters.supplierType as string[]);
      } else {
        this.props.setSupplierTypesFilters(NULL_VALUE);
      }

      if (filters.status && filters.status.length) {
        this.props.setStatusesFilters(filters.status as string[]);
      } else {
        this.props.setStatusesFilters(NULL_VALUE);
      }

      if (filters.subStatuses && filters.subStatuses.length) {
        this.props.setSubStatusesFilters(filters.subStatuses as string[]);
      } else {
        this.props.setSubStatusesFilters(NULL_VALUE);
      }
    }

    this.props.setSort({ ...sorter, column: NULL_VALUE });
    this.props.setPagination(pagination);
    this.props.getAdminBookings();
  };

  getFailedInfoMessage = (order: IAdminBookings): React.ReactNode => {
    const { status, subStatuses } = order;
    const isBookUnknown = subStatuses?.some(({ stage, status }) => {
      return stage.toLowerCase() === 'book' && status.toLowerCase() === 'unknown';
    });
    const isCaptureUnknown = subStatuses?.some(({ stage, status }) => {
      return stage.toLowerCase() === 'capture' && status.toLowerCase() === 'unknown';
    });
    const isFailed = status === BookingStatusEnum.Failed;

    if (isFailed && (isBookUnknown || isCaptureUnknown)) {
      return (
        <Tooltip
          title={
            <FormattedMessage
              id={
                isBookUnknown
                  ? 'information.about.order.status.was.not.received'
                  : 'please.check.in.on.details.in.the.payment.system'
              }
            />
          }
          placement="top"
        >
          <div className="bookings-title-wrapper">
            <InfoSvg />
          </div>
        </Tooltip>
      );
    }

    return null;
  };

  getAllColumns = (): ColumnsType<IAdminBookings> => {
    const { adminBookingsStore } = this.props;
    const {
      sort,
      checkOutDateRangeFilter,
      checkInDateRangeFilter,
      bookedOnDateRangeFilter,
      supplierFilters,
      subStatusesFilters,
      statusesFilters,
      subStatuses,
    } = adminBookingsStore;

    return [
      {
        title: (
          <span>
            <FormattedMessage id="order" /> #
          </span>
        ),
        sorter: true,
        sortOrder: sort?.columnKey === 'orderId' ? sort.order : null,
        width: 146,
        dataIndex: 'orderId',
        key: 'orderId',
        render: (id: string, order: IAdminBookings) => {
          return (
            <div className="all-bookings__link">
              <Link to={`${Routes.OrderDetails}/${order.orderId}`}>{order.orderId}</Link>
            </div>
          );
        },
      },
      {
        title: <FormattedMessage id="member.siteId" />,
        render: (member: IAdminBookingsMember) =>
          member ? <div className="bold-text">{member.siteId}</div> : emptyData,
        dataIndex: 'member',
        key: 'member.siteId',
        width: 150,
        sorter: true,
        sortOrder: sort?.columnKey === 'member.siteId' ? sort.order : null,
      },
      {
        title: <FormattedMessage id="member.id" />,
        render: (member: IAdminBookingsMember) =>
          member ? <div className="bold-text">{member.id}</div> : emptyData,
        dataIndex: 'member',
        key: 'member.id',
        width: 140,
        sorter: true,
        sortOrder: sort?.columnKey === 'member.id' ? sort.order : null,
      },
      {
        title: <FormattedMessage id="member.name" />,
        render: (member: IAdminBookingsMember) =>
          member ? (
            <Tooltip title={`${member?.lastName}, ${member?.firstName}`} placement="topLeft">
              <div className="all-bookings__cut-content">
                {member?.firstName} {member?.lastName}
              </div>
            </Tooltip>
          ) : (
            emptyData
          ),
        dataIndex: 'member',
        key: 'member.name',
        width: 200,
        sorter: true,
        sortOrder: sort?.columnKey === 'member.name' ? sort.order : null,
      },
      {
        title: <FormattedMessage id="status" />,
        dataIndex: 'status',
        key: 'status',
        render: (status: BookingStatusEnum, order: IAdminBookings) => {
          return (
            <div className="all-bookings__status-column">
              <BookingsStatus status={status} subStatus={order?.subStatuses} />
              {this.getFailedInfoMessage(order)}
            </div>
          );
        },
        width: 246,
        filterDropdown: (props: FilterDropdownProps) => (
          <CheckboxTableFilter
            {...props}
            options={[
              { text: <FormattedMessage id="draft" />, value: BookingStatusEnum.Draft },
              { text: <FormattedMessage id="confirmed" />, value: BookingStatusEnum.Confirmed },
              { text: <FormattedMessage id="completed" />, value: BookingStatusEnum.Completed },
              { text: <FormattedMessage id="failed" />, value: BookingStatusEnum.Failed },
              { text: <FormattedMessage id="cancelled" />, value: BookingStatusEnum.Cancelled },
            ]}
          />
        ),
        filteredValue: statusesFilters && statusesFilters.length ? statusesFilters : null,
        filtered: !!(statusesFilters && statusesFilters.length),
        sorter: true,
        sortOrder: sort?.columnKey === 'status' ? sort.order : null,
      },
      {
        title: <FormattedMessage id="substatus" />,
        render: (subStatuses: IBookingSubStatus[], order: IAdminBookings) => (
          <div className="all-bookings__sub-status-column">
            {subStatuses[0]?.displayValue}
            {this.getFailedInfoMessage(order)}
          </div>
        ),
        dataIndex: 'subStatuses',
        key: 'subStatuses',
        width: 166,
        filterDropdown: (props: FilterDropdownProps) =>
          subStatuses && subStatuses.length ? (
            <CheckboxTableFilter
              {...props}
              options={subStatuses.map(({ displayValue }) => {
                return { text: displayValue, value: displayValue };
              })}
            />
          ) : null,
        filteredValue: subStatusesFilters && subStatusesFilters.length ? subStatusesFilters : null,
        filtered: !!(subStatusesFilters && subStatusesFilters.length),
      },
      {
        title: <FormattedMessage id="check.in" />,
        dataIndex: 'checkIn',
        key: 'checkIn',
        sorter: true,
        sortOrder: sort?.columnKey === 'checkIn' ? sort.order : null,
        render: (checkIn: string) => (
          <div className="all-bookings__date-time">
            <div className="all-bookings__date-time-date">
              {formatDateCheckInOut(checkIn, DATE_FORMAT_FOR_BOOKINGS)}
            </div>
          </div>
        ),
        width: 150,
        filtered: !!checkInDateRangeFilter,
        filteredValue: checkInDateRangeFilter
          ? [`${checkInDateRangeFilter?.from}~${checkInDateRangeFilter?.to}`]
          : null,
        filterDropdown: (props: FilterDropdownProps) => (
          <DateRangeTableFilter {...props} dateRangeFilter={checkInDateRangeFilter} />
        ),
      },
      {
        title: <FormattedMessage id="check.out" />,
        dataIndex: 'checkOut',
        key: 'checkOut',
        sorter: true,
        sortOrder: sort?.columnKey === 'checkOut' ? sort.order : null,
        render: (checkOut: string) => (
          <div className="all-bookings__date-time">
            <div className="all-bookings__date-time-date">
              {formatDateCheckInOut(checkOut, DATE_FORMAT_FOR_BOOKINGS)}
            </div>
          </div>
        ),
        width: 150,
        filtered: !!checkOutDateRangeFilter,
        filteredValue: checkOutDateRangeFilter
          ? [`${checkOutDateRangeFilter?.from}~${checkOutDateRangeFilter?.to}`]
          : null,
        filterDropdown: (props: FilterDropdownProps) => (
          <DateRangeTableFilter {...props} dateRangeFilter={checkOutDateRangeFilter} />
        ),
      },
      {
        title: <FormattedMessage id="guest.name" />,
        render: (guest: IAdminBookingGuest) =>
          guest ? (
            <Tooltip title={`${guest.surname}, ${guest.givenName}`} placement="topLeft">
              <div className="all-bookings__cut-content">
                {guest.givenName} {guest.surname}
              </div>
            </Tooltip>
          ) : (
            emptyData
          ),
        dataIndex: 'guest',
        key: 'guest.name',
        width: 200,
        sorter: true,
        sortOrder: sort?.columnKey === 'guest.name' ? sort.order : null,
      },
      {
        title: <FormattedMessage id="booked.on" />,
        dataIndex: 'bookedOn',
        key: 'bookedOn',
        render: (date: string, order: IAdminBookings) => (
          <div className="all-bookings__date-time">
            <div className="all-bookings__date-time-date">
              {formatDateCheckInOut(date ? date : order.createDate, DATE_FORMAT_FOR_BOOKINGS)}
            </div>
            <div className="all-bookings__date-time-time">
              {format(new Date(date ? date : order.createDate), TIME_FORMAT)}
            </div>
          </div>
        ),
        width: 150,
        sorter: true,
        sortOrder: sort?.columnKey === 'bookedOn' ? sort.order : null,
        filtered: !!bookedOnDateRangeFilter,
        filteredValue: bookedOnDateRangeFilter
          ? [`${bookedOnDateRangeFilter?.from}~${bookedOnDateRangeFilter?.to}`]
          : null,
        filterDropdown: (props: FilterDropdownProps) => (
          <DateRangeTableFilter {...props} dateRangeFilter={bookedOnDateRangeFilter} />
        ),
      },
      {
        title: <FormattedMessage id="supplier" />,
        render: (supplierType: string) => (
          <div style={{ textTransform: 'uppercase' }}>
            {supplierType ? supplierType : emptyData}
          </div>
        ),
        dataIndex: 'supplierType',
        key: 'supplierType',
        width: 130,
        sorter: true,
        sortOrder: sort?.columnKey === 'supplierType' ? sort.order : null,
        filterDropdown: (props: FilterDropdownProps) => (
          <CheckboxTableFilter
            {...props}
            options={[
              { text: 'RCI', value: 'rci' },
              { text: 'Intervals', value: 'intervals' },
            ]}
          />
        ),
        filteredValue: supplierFilters && supplierFilters.length ? supplierFilters : null,
        filtered: !!(supplierFilters && supplierFilters.length),
      },
      {
        title: (
          <>
            <FormattedMessage id="supplier.confirmation" /> #
          </>
        ),
        render: (supplierId: string) => <div>{supplierId ? supplierId : emptyData}</div>,
        dataIndex: 'supplierId',
        key: 'supplierId',
        width: 230,
        sorter: true,
        sortOrder: sort?.columnKey === 'supplierId' ? sort.order : null,
      },
      {
        title: <FormattedMessage id="condo.name" />,
        dataIndex: 'condoName',
        key: 'condoName',
        render: (condoName: string) =>
          condoName ? (
            <Tooltip title={condoName} placement="topLeft">
              <div className="all-bookings__cut-content bold-text">{condoName}</div>
            </Tooltip>
          ) : (
            emptyData
          ),
        width: 380,
        sorter: true,
        sortOrder: sort?.columnKey === 'condoName' ? sort.order : null,
      },
      {
        title: <FormattedMessage id="property.address" />,
        dataIndex: 'address',
        key: 'address',
        render: (address: string) =>
          address ? (
            <Tooltip title={address} placement="topLeft">
              <div className="all-bookings__cut-content">{address}</div>
            </Tooltip>
          ) : (
            emptyData
          ),
        width: 220,
        sorter: true,
        sortOrder: sort?.columnKey === 'address' ? sort.order : null,
      },
      {
        title: <FormattedMessage id="property.city" />,
        dataIndex: 'city',
        key: 'city',
        render: (city: string) =>
          city ? (
            <Tooltip title={city} placement="topLeft">
              <div className="all-bookings__cut-content">{city}</div>
            </Tooltip>
          ) : (
            emptyData
          ),
        width: 160,
        sorter: true,
        sortOrder: sort?.columnKey === 'city' ? sort.order : null,
      },
      {
        title: <FormattedMessage id="property.country" />,
        dataIndex: 'country',
        key: 'country',
        render: (country: string) =>
          country ? (
            <Tooltip title={country} placement="topLeft">
              <div className="all-bookings__cut-content">{country}</div>
            </Tooltip>
          ) : (
            emptyData
          ),
        width: 160,
        sorter: true,
        sortOrder: sort?.columnKey === 'country' ? sort.order : null,
      },
      {
        title: <FormattedMessage id="region" />,
        dataIndex: 'region',
        key: 'region',
        render: (region: string) =>
          region ? (
            <Tooltip title={region} placement="topLeft">
              <div className="all-bookings__cut-content">{region}</div>
            </Tooltip>
          ) : (
            emptyData
          ),
        width: 220,
        sorter: true,
        sortOrder: sort?.columnKey === 'region' ? sort.order : null,
      },
      {
        title: <FormattedMessage id="max.occupancy" />,
        dataIndex: 'unit',
        key: 'unit.max',
        render: (unit: IAdminBookingUnit) => <div>{unit.maxOccupancy}</div>,
        width: 140,
        sorter: true,
        sortOrder: sort?.columnKey === 'unit.max' ? sort.order : null,
      },
      {
        title: <FormattedMessage id="private.occupancy" />,
        dataIndex: 'unit',
        key: 'unit.private',
        render: (unit: IAdminBookingUnit) => <div>{unit.privacyOccupancy}</div>,
        width: 160,
        sorter: true,
        sortOrder: sort?.columnKey === 'unit.private' ? sort.order : null,
      },
      {
        title: <FormattedMessage id="bedroom.type" />,
        dataIndex: 'unit',
        key: 'unit.bedroom',
        render: (unit: IAdminBookingUnit) => (
          <div>{unit.bedroomType ? unit.bedroomType : emptyData}</div>
        ),
        width: 160,
        sorter: true,
        sortOrder: sort?.columnKey === 'unit.bedroom' ? sort.order : null,
      },
      {
        title: <FormattedMessage id="bath.count" />,
        dataIndex: 'unit',
        key: 'unit.bathroom',
        render: (unit: IAdminBookingUnit) => <div>{unit.bathroom ? unit.bathroom : emptyData}</div>,
        width: 116,
        sorter: true,
        sortOrder: sort?.columnKey === 'unit.bathroom' ? sort.order : null,
      },
      {
        title: <FormattedMessage id="card.info" />,
        dataIndex: 'cardDetails',
        key: 'cardDetails',
        render: (cardDetails: IBookingsCardDetails) => (
          <div>
            {cardDetails ? (
              <span>
                <span>{cardDetails?.cardNumber.slice(-numsToShow)}</span>,{' '}
                <span className="ttu">{cardDetails?.cardType}</span>
              </span>
            ) : (
              emptyData
            )}
          </div>
        ),
        width: 160,
      },
      {
        title: <FormattedMessage id="public.price.without.colon" />,
        dataIndex: 'bookingPrice',
        key: 'bookingPrice.publicPrice',
        render: (bookingPrice: IAdminBookingPrice) => (
          <div>
            {bookingPrice?.publicPrice ? `\$${renderNumber(bookingPrice?.publicPrice)}` : emptyData}
          </div>
        ),
        width: 140,
        sorter: true,
        sortOrder: sort?.columnKey === 'bookingPrice.publicPrice' ? sort.order : null,
      },
      {
        title: <FormattedMessage id="supplier.price" />,
        dataIndex: 'bookingPrice',
        key: 'bookingPrice.supplier',
        render: (bookingPrice: IAdminBookingPrice) => (
          <div>
            {bookingPrice?.totalSupplierPrice
              ? `\$${renderNumber(bookingPrice?.totalSupplierPrice)}`
              : emptyData}
          </div>
        ),
        width: 140,
        sorter: true,
        sortOrder: sort?.columnKey === 'bookingPrice.supplier' ? sort.order : null,
      },
      {
        title: <FormattedMessage id="supplier.taxes" />,
        dataIndex: 'bookingPrice',
        key: 'bookingPrice.supplierTaxes',
        render: (bookingPrice: IAdminBookingPrice) => (
          <div>
            {bookingPrice?.totalTaxes
              ? `\$${renderNumber(bookingPrice?.totalTaxes)}`
              : emptyData}
          </div>
        ),
        width: 140,
        sorter: true,
        sortOrder: sort?.columnKey === 'bookingPrice.supplierTaxes' ? sort.order : null,
      },
      {
        showSorterTooltip: false,
        title: (
          <Tooltip title={<FormattedMessage id="markup.tooltip" />} placement="topLeft">
            <div className="all-hotel-bookings__table-header-tooltip">
              <span>
                <FormattedMessage id="markup" />
              </span>
              <InfoSvg />
            </div>
          </Tooltip>
        ),
        dataIndex: 'markup',
        key: 'markup',
        render: (markup: number) => <div>{markup ? `\$${renderNumber(markup)}` : emptyData}</div>,
        width: 110,
        sorter: true,
        sortOrder: sort?.columnKey === 'markup' ? sort.order : null,
      },
      {
        title: <FormattedMessage id="savings.condo" />,
        dataIndex: 'bookingPrice',
        key: 'bookingPrice.savings',
        render: (bookingPrice: IAdminBookingPrice) => (
          <div>
            {bookingPrice?.savings ? `\$${renderNumber(bookingPrice?.savings)}` : emptyData}
          </div>
        ),
        width: 140,
        sorter: true,
        sortOrder: sort?.columnKey === 'bookingPrice.savings' ? sort.order : null,
      },
      {
        title: <FormattedMessage id="discount" />,
        dataIndex: 'bookingPrice',
        key: 'bookingPrice.discount',
        render: (bookingPrice: IAdminBookingPrice) => (
          <div>
            {bookingPrice?.couponAmount
              ? `\$${renderNumber(bookingPrice?.couponAmount)}`
              : emptyData}
          </div>
        ),
        width: 110,
        sorter: true,
        sortOrder: sort?.columnKey === 'bookingPrice.discount' ? sort.order : null,
      },
      {
        title: <FormattedMessage id="profit" />,
        dataIndex: 'bookingPrice',
        key: 'bookingPrice.profit',
        render: (bookingPrice: IAdminBookingPrice) => {
          const profit = bookingPrice?.totalProfitAmount;

          return profit ? (
            <div className={profit > 0 ? '' : 'negative-profit'}>${renderNumber(profit)}</div>
          ) : (
            emptyData
          );
        },
        width: 110,
        sorter: true,
        sortOrder: sort?.columnKey === 'bookingPrice.profit' ? sort.order : null,
      },
      {
        title: <FormattedMessage id="total.paid" />,
        dataIndex: 'bookingPrice',
        key: 'bookingPrice.total',
        render: (bookingPrice: IAdminBookingPrice) => (
          <div className="bold-text">
            {bookingPrice?.payNowPrice ? `\$${renderNumber(bookingPrice?.payNowPrice)}` : emptyData}
          </div>
        ),
        width: 120,
        fixed: 'right',
        sorter: true,
        sortOrder: sort?.columnKey === 'bookingPrice.total' ? sort.order : null,
      },
    ];
  };

  getAllColumnsByStatus = (): ColumnsType<IAdminBookings> => {
    const { status } = this.props.adminBookingsStore;
    const finishedStatuses = [
      BookingStatusEnum.Confirmed,
      BookingStatusEnum.Completed,
      BookingStatusEnum.Cancelled,
    ];
    const allColumns = this.getAllColumns();
    const defaultFields = [
      'orderId',
      'member.siteId',
      'member.id',
      'member.name',
      'checkIn',
      'checkOut',
      'guest.name',
      'bookedOn',
      'supplierType',
      'condoName',
      'address',
      'city',
      'country',
      'region',
      'unit.max',
      'unit.private',
      'unit.bedroom',
      'unit.bathroom',
      'cardDetails',
      'bookingPrice.publicPrice',
      'bookingPrice.supplier',
      'bookingPrice.supplierTaxes',
      'markup',
      'bookingPrice.savings',
      'bookingPrice.discount',
      'bookingPrice.profit',
      'bookingPrice.total',
    ];

    if (!status) {
      defaultFields.splice(three, zero, 'status');

      const supplierIndex = defaultFields.findIndex((key) => key === 'supplierType');
      defaultFields.splice(supplierIndex + one, zero, 'supplierId');
    } else if (status === BookingStatusEnum.Failed) {
      defaultFields.splice(three, zero, 'subStatuses');
    }

    if (finishedStatuses.includes(status)) {
      const supplierIndex = defaultFields.findIndex((key) => key === 'supplierType');
      defaultFields.splice(supplierIndex + one, zero, 'supplierId');
    }

    return defaultFields.map((key: string) => {
      return allColumns.find((column) => column.key === key);
    });
  };

  getTotalTitle = (total: number, range: [number, number]): React.ReactNode => {
    return (
      <span>
        {range[zero]}-{range[one]} <FormattedMessage id="of" />{' '}
        <FormattedMessage id="items.count" values={{ count: total }} />
      </span>
    );
  };

  onRefresh = (): void => {
    this.props.getAdminBookings();
  };

  getFiltersCount = (): number => {
    const {
      checkInDateRangeFilter,
      checkOutDateRangeFilter,
      bookedOnDateRangeFilter,
      statusesFilters,
      subStatusesFilters,
      supplierFilters,
    } = this.props.adminBookingsStore;
    let res = 0;

    if (statusesFilters && statusesFilters.length) {
      res += statusesFilters.length;
    }

    if (subStatusesFilters && subStatusesFilters.length) {
      res += subStatusesFilters.length;
    }

    if (supplierFilters && supplierFilters.length) {
      res += supplierFilters.length;
    }

    if (checkInDateRangeFilter && checkInDateRangeFilter.to && checkInDateRangeFilter.from) {
      res++;
    }

    if (checkOutDateRangeFilter && checkOutDateRangeFilter.to && checkOutDateRangeFilter.from) {
      res++;
    }

    if (bookedOnDateRangeFilter && bookedOnDateRangeFilter.to && bookedOnDateRangeFilter.from) {
      res++;
    }

    return res;
  };

  resetAllFilters = (): void => {
    this.props.resetFilters();
    this.props.resetHotelBookingsFilters();
    this.onRefresh();
  };

  componentDidMount(): void {
    const values = UrlUtils.getValues();

    if (values[BOOKING_TABLE_DATE_CHECK_IN_RANGE]) {
      this.props.setCheckInDateRangeFilter(
        values[BOOKING_TABLE_DATE_CHECK_IN_RANGE] as { from: string; to: string },
      );
    }

    if (values[BOOKING_TABLE_DATE_CHECK_OUT_RANGE]) {
      this.props.setCheckOutDateRangeFilter(
        values[BOOKING_TABLE_DATE_CHECK_OUT_RANGE] as { from: string; to: string },
      );
    }

    if (values[BOOKING_TABLE_DATE_BOOKED_ON_RANGE]) {
      this.props.setBookedOnDateRangeFilter(
        values[BOOKING_TABLE_DATE_BOOKED_ON_RANGE] as { from: string; to: string },
      );
    }

    if (values[BOOKING_SUB_STATUSES_LABEL]) {
      this.props.setSubStatusesFilters(values[BOOKING_SUB_STATUSES_LABEL] as string[]);
    }

    if (values[BOOKING_SUPPLIER_TYPES_LABEL]) {
      this.props.setSupplierTypesFilters(values[BOOKING_SUPPLIER_TYPES_LABEL] as string[]);
    }

    if (values[BOOKING_TABLE_SEARCH]) {
      this.props.setSearch(values[BOOKING_TABLE_SEARCH] as string);
    }

    if (values[BOOKING_TABLE_MATCHED_BY]) {
      const value = values[BOOKING_TABLE_MATCHED_BY] as MatchedByEnum;

      if (value) {
        this.props.setSelectedSearchItem({ matchedBy: value });
      }
    }

    if (values[BOOKING_TABLE_SORT_ORDER]) {
      this.props.setSort(values[BOOKING_TABLE_SORT_ORDER]);
    }

    if (values[BOOKING_STATUS_LABEL]) {
      const statuses = values[BOOKING_STATUS_LABEL] as (
        | HotelBookingStatusEnum
        | BookingStatusEnum
      )[];
      const statusesFilters = statuses.map?.(getCondosStatusByHotels);
      this.props.setStatusesFilters(statusesFilters);
    }

    if (values[BOOKING_TABLE_PAGINATION]) {
      this.props.setPagination(values[BOOKING_TABLE_PAGINATION]);
    }

    this.props.getSubStatusList();
    this.props.getAdminBookings();
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    const { adminBookingsStore, intl } = this.props;
    const { exportCsv, exportLoading, exportError } = adminBookingsStore;

    if (
      prevProps.adminBookingsStore.exportLoading !== exportLoading &&
      exportCsv &&
      !exportLoading
    ) {
      notification['success']({
        message: intl.formatMessage({ id: 'export.is.successful' }),
        icon: <SuccessSmallSvg />,
        className: 'all-bookings__notification success',
      });
    }

    if (
      prevProps.adminBookingsStore.exportError.length !== exportError.length &&
      exportError.length !== zero
    ) {
      notification['error']({
        message: intl.formatMessage({ id: 'export.is.failed' }),
        icon: <ErrorSmallSvg />,
        className: 'all-bookings__notification error',
      });
    }
  }

  render(): React.ReactNode {
    const filtersCount = this.getFiltersCount();
    const { showModalExportCsv } = this.props;
    const { bookings, totalOrderCount, loading, pagination } = this.props.adminBookingsStore;

    return (
      <>
        <div className="all-bookings__filters-header-wrapper">
          {filtersCount > 0 && (
            <div className="all-bookings__filters-header">
              <div className="all-bookings__filters-text">
                <FormattedMessage id="filters.count" values={{ count: filtersCount }} />{' '}
                <FormattedMessage id="have.been.applied" />
              </div>
              <WhiteButton onClick={this.resetAllFilters}>
                <FormattedMessage id="reset.all" />
              </WhiteButton>
            </div>
          )}
          <div className="all-bookings__actions">
            <div className="all-bookings__search">
              <CondoBookingsSearch />
            </div>
            <div className="all-bookings__btn-wrapper">
              <BlueButton disabled={bookings.length === zero} onClick={showModalExportCsv}>
                <FormattedMessage id="export" />
              </BlueButton>
            </div>
            <div className="all-bookings__refresh" onClick={this.onRefresh}>
              <RefreshSvg />
            </div>
          </div>
        </div>
        <div className="all-bookings__table">
          <Table
            locale={{
              emptyText: !loading ? <TableHeader onClick={this.resetAllFilters} /> : null,
            }}
            loading={loading}
            onChange={this.onTableChange}
            rowKey={(row: IAdminBookings) => `${row.orderId}`}
            columns={this.getAllColumnsByStatus()}
            dataSource={bookings}
            scroll={{
              x: this.getAllColumnsByStatus().reduce((res, { width }) => {
                return res + (width as number);
              }, 0),
              y: 'calc(100vh - 420px)',
            }}
            pagination={{
              ...pagination,
              showSizeChanger: true,
              pageSizeOptions,
              total: totalOrderCount,
              showTotal: this.getTotalTitle,
            }}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    adminBookingsStore: state.adminBookingsStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  getAdminBookings,
  getSubStatusList,
  setPagination: adminBookingsActions.setPagination,
  setSort: adminBookingsActions.setSort,
  setCheckInDateRangeFilter: adminBookingsActions.setCheckInDateRangeFilter,
  setCheckOutDateRangeFilter: adminBookingsActions.setCheckOutDateRangeFilter,
  setBookedOnDateRangeFilter: adminBookingsActions.setBookedOnDateRangeFilter,
  setStatusesFilters: adminBookingsActions.setStatusesFilters,
  setSubStatusesFilters: adminBookingsActions.setSubStatusesFilters,
  setSupplierTypesFilters: adminBookingsActions.setSupplierTypesFilters,
  setSearch: adminBookingsActions.setSearch,
  setSelectedSearchItem: adminBookingsActions.setSelectedSearchItem,
  resetFilters: adminBookingsActions.resetFilters,
  resetHotelBookingsFilters: adminHotelBookingsActions.resetFilters,
};

export const CondoBookings = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withRouter,
)(CondoBookingsComponent);
