import React, { RefObject } from 'react';
import { Form, Input } from 'antd';
import { connect } from 'react-redux';
import { FormInstance } from 'antd/lib/form';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { RootState } from '@share/utils';
import { setGuests, IReviewBookState } from '@store/slices';
import { IMenuState } from '@share/store/slices';
import { IGuestsBooking } from '@common-types';
import { mailFormat } from '@share/utils';
import './style.scss';

interface IMapStateToProps {
  reviewBookStore: IReviewBookState;
  menuStore: IMenuState;
}

interface IMapDispatchToProps {
  setGuests: (guests: IGuestsBooking[]) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, WrappedComponentProps {
  formRef: RefObject<FormInstance>;
  value: string;
}

class ConfirmationEmailComponent extends React.Component<IProps> {
  getIndexMainGuest = () =>
    this.props.reviewBookStore.guests &&
    this.props.reviewBookStore.guests.findIndex((guest) => guest?.isMainGuest === true);

  setEmailAddress = (value: string | null = null) => {
    const indexMainGuest = this.getIndexMainGuest();
    const items = [...this.props.reviewBookStore.guests];

    items[indexMainGuest] = {
      ...items[indexMainGuest],
      email: value as string,
    };
    this.props.setGuests(items);
  };

  validateEmail = (e: { target: HTMLInputElement }) => {
    this.setEmailAddress(e.target.value);
  };

  onFocus = (e: { target: HTMLInputElement }) => {
    this.props.formRef.current?.setFieldsValue({ email: e.target.value });
  };

  componentDidUpdate() {
    if (this.props.reviewBookStore.errorsField) {
      this.props.formRef.current?.validateFields().then();
    }
  }

  render(): React.ReactNode {
    const { intl, reviewBookStore } = this.props;
    const { loadingBooking } = reviewBookStore;

    return (
      <div className="confirmation-email">
        <div className="confirmation-email__header">
          <h4 className="confirmation-email__title">
            <FormattedMessage id="confirmation.email" />
          </h4>
          <p className="confirmation-email__description">
            <FormattedMessage id="enter.email.address" />
          </p>
        </div>
        <div className="confirmation-email__main-guest-info">
          <Form
            name="confirmation-email-rule"
            className="confirmation-email__rule"
            ref={this.props.formRef}
          >
            <Form.Item
              name="email"
              validateTrigger="onBlur"
              rules={[
                {
                  required: true,
                  pattern: new RegExp(mailFormat),
                  message: intl.formatMessage({ id: 'error.message.email.validation' }),
                },
              ]}
            >
              <div className="confirmation-email__name-input-wrapper">
                <span className="confirmation-email__input-label">
                  <FormattedMessage id="email" />
                </span>
                <div className="confirmation-email__input">
                  <Input
                    placeholder={intl.formatMessage({ id: 'email.address' })}
                    value={this.props.value}
                    maxLength={255}
                    onChange={this.validateEmail}
                    onFocus={this.onFocus}
                    disabled={loadingBooking}
                  />
                </div>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    reviewBookStore: state.reviewBookStore,
    menuStore: state.navigationMenuStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = { setGuests };

export const ConfirmationEmail = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(ConfirmationEmailComponent));
