import { useMemo, useState } from 'react';

import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { Select } from 'antd';
import { get } from 'lodash';

import { ILoginState, IMenuState, IMyProfileState } from '@share/store/slices';
import { GetToken, IsImpersonating, RootState } from '@share/utils';
import { NULL_VALUE } from '@constants';
import { IMyProfile } from '@share/common-types';

import './style.scss';

export const MAIN_MEMBER = 'MAIN';
export const AUTHORIZED_USER_MEMBER = 'AUTHORIZED_USER';

export interface MemberChangeValue {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
}

interface IMapStateToProps {
  menuStore: IMenuState;
  myProfileStore: IMyProfileState;
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps, WrappedComponentProps {
  className?: string;

  onMemberChange: (selection: MemberChangeValue) => void;
}

function SavedMembersComponent(props: IProps) {
  const { className, myProfileStore, menuStore, loginStore, intl, onMemberChange } = props;
  const { items } = menuStore;
  const { result } = myProfileStore;
  const { account, user } = loginStore;

  const [selectedValue, setSelectedValue] = useState<string>(NULL_VALUE);

  const isPrivateLoginAccountType = account?.isPrivateLoginAccountType;
  const isPrivateTokenAccountType = account?.isPrivateTokenAccountType;
  const displaySavedMembers = account?.displaySavedMembers;
  const displaySavedMembersRsiSiteidList = account?.displaySavedMembersRsiSiteidList;
  const displaySavedMembersRsiOrgidList = account?.displaySavedMembersRsiOrgidList;
  const isCapitalVacationsAgent = account?.isCapitalVacationsAgent;

  const listsEmpty = !displaySavedMembersRsiSiteidList?.length && !displaySavedMembersRsiOrgidList?.length;
  const display = !!isPrivateLoginAccountType || !!isPrivateTokenAccountType;
  const displayAccount = displaySavedMembers && (listsEmpty || displaySavedMembersRsiSiteidList?.includes(items?.siteId.toString() as string) || displaySavedMembersRsiOrgidList?.includes(items?.organizationId.toString() as string));

  const isImpersonating = IsImpersonating();

  if (!display || !items || !displayAccount || (isCapitalVacationsAgent && !isImpersonating)) {
    return null;
  }

  const isCapitalImpersonate = isCapitalVacationsAgent && isImpersonating;

  const handleOnChange = (value: string) => {
    setSelectedValue(value);

    const isMainSelection = value === MAIN_MEMBER;
    const authorizedUserIndex = get(value.split('|'), '[1]', 0);
    const selectedAuthorizedUser: IMyProfile = get(result?.authorizedUsers, `[${authorizedUserIndex}]`, {} as IMyProfile);

    let selectedUser = {
      firstName: (items?.firstName ? items?.firstName : user?.firstName) as string,
      lastName: (items?.lastName ? items?.lastName : user?.lastName) as string,
      phone: (items?.phone ? items?.phone : user?.userAdditionalData?.phone) as string,
      email: (items?.email ? items?.email : user?.email) as string,
    };

    if (isCapitalImpersonate) {
      selectedUser = {
        firstName: (user?.firstName) as string,
        lastName: (user?.lastName) as string,
        phone: (user?.userAdditionalData?.phone) as string,
        email: (user?.email) as string,
      };
    }

    const mainMemberData = { ...selectedUser };

    const member = {
      firstName: (isMainSelection ? mainMemberData?.firstName : selectedAuthorizedUser?.firstName) as string,
      lastName: (isMainSelection ? mainMemberData?.lastName : selectedAuthorizedUser?.lastName) as string,
      phone: (isMainSelection ? mainMemberData?.phone : selectedAuthorizedUser?.phone1) as string,
      email: (isMainSelection ? mainMemberData?.email : selectedAuthorizedUser?.email) as string,
    }

    console.log('items', items);
    console.log('user', user);
    console.log('member', member);
    console.log('mainMemberData', mainMemberData);
    console.log('isCapitalVacationsAgent', isCapitalVacationsAgent);
    console.log('isImpersonating', isImpersonating);
    console.log('GetToken()', GetToken());
    console.log(' ');

    onMemberChange(member);
  }

  const options = useMemo(() => {
    const list = [
      { 
        label: isCapitalImpersonate ? `${user?.firstName} ${user?.lastName}` : `${items?.firstName} ${items?.lastName}`,
        value: MAIN_MEMBER
      }
    ];

    if (result?.authorizedUsers?.length) {
      result?.authorizedUsers.forEach((a, i) => list.push({
        label: `${a?.firstName} ${a?.lastName}`,
        value: `${AUTHORIZED_USER_MEMBER}|${i}`
      }));
    }

    return list;
  }, [items, result?.authorizedUsers]);

  return (
    <div className={`room-info__saved-members ${className ? className : ''}`}>
      <div className="payment-method__input-wrapper">
        <span className="payment-method__input-label">
          <FormattedMessage id="select.member" />
        </span>
        <div className="payment-method__input">
          <Select
            placeholder={intl.formatMessage({ id: 'select.member' })}
            value={selectedValue}
            onChange={handleOnChange}
            disabled={menuStore.loading || myProfileStore.loading}
            popupClassName="payment-year-select"
          >
            {options.map((o, i) => (
              <Select.Option key={i} value={o.value}>
                {o.label}<br />
                {options.length > 1 && (
                  <label style={{ fontSize: '12px', color: 'var(--silver)' }}><FormattedMessage id={o.value === MAIN_MEMBER ? 'primary' : 'authorized.user'} /></label>)}
              </Select.Option>))}
          </Select>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    menuStore: state.navigationMenuStore,
    myProfileStore: state.myProfileStore,
    loginStore: state.loginStore,
  };
};

export const SavedMembers = connect(mapStateToProps)(injectIntl(SavedMembersComponent));
