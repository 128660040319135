import React from 'react';
import { Form, Input, Select, Checkbox } from 'antd';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { CheckboxChangeEventTarget } from 'antd/lib/checkbox/Checkbox';
import { IErrorField, IGuestsBooking } from '@common-types';
import { checkInLatinLetters } from '@share/utils';
import { namesPrefix } from '@constants';
import { countriesCode } from '@share/constants';

import './style.scss';

interface IProps extends WrappedComponentProps {
  guests: IGuestsBooking[];
  guest: IGuestsBooking;
  title: string;
  childAge?: number;
  memberFirstName?: string;
  memberLastName?: string;
  memberPhone?: string;
  memberEmail?: string;
  index: number;
  indexRoom: number;
  roomGuestCount: number;
  childIndex: number;
  adultIndex: number;
  memberType: string;
  disabled: boolean;
  displayOptional: boolean;
  onChangeMainGuest: (
    e: { target: CheckboxChangeEventTarget },
    memberFirstName: string,
    memberLastName: string,
    memberPhone: string,
    memberEmail: string,
    index: number,
  ) => void;
  onChangeFirstName: (e: { target: HTMLInputElement }, index: number) => void;
  onChangeLastName: (e: { target: HTMLInputElement }, index: number) => void;
  onChangePhone: (e: { target: HTMLInputElement }, index: number) => void;
  onChangeNamePrefix: (value: string, index: number) => void;
  onFocusPhone: () => void;
  onDisplayOptional: (indexRoom: number) => void;
  isShowCheckbox: (guest: IGuestsBooking) => boolean;
  updateDefaultMainGuest: (
    memberFirstName: string,
    memberLastName: string,
    memberPhone: string,
    memberEmail: string,
  ) => void;
  errorsField: IErrorField[];
  isCheckMainGuest: boolean;
}

const MAX_LENGTH_NUMBER = 12;
const MIN_LENGTH_NAME = 2;

class GuestFormComponent extends React.Component<IProps> {
  
  componentDidMount() {
    if (!this.props.guest?.isMainGuest) {
      return;
    }

    const { updateDefaultMainGuest, memberFirstName, memberLastName, memberPhone, memberEmail } =
      this.props;
    updateDefaultMainGuest(memberFirstName as string, memberLastName as string, memberPhone as string, memberEmail as string);
  }

  render(): React.ReactNode {
    const { Option } = Select;
    const {
      intl,
      guest,
      title,
      childAge,
      memberFirstName,
      memberLastName,
      memberPhone,
      memberEmail,
      index,
      indexRoom,
      childIndex,
      adultIndex,
      disabled,
      displayOptional,
      roomGuestCount,
      isShowCheckbox,
      onFocusPhone,
    } = this.props;

    const isFirstAdult = adultIndex === 1;
    
    return (isFirstAdult || displayOptional) ? (
      <div className="guest__info">
        <p className="guest__title">
          <FormattedMessage id={title} /> {title === 'kid.title' ? childIndex : adultIndex} <FormattedMessage id={isFirstAdult? 'review.book.guest.mandatory' : 'review.book.guest.optional'} /> 
          {title === 'kid.title' && (
            <span className="guest__years">
              ({childAge} <FormattedMessage id="years.old.text" />)
            </span>
          )}
        </p>
        {isShowCheckbox(guest) && (
          <>
            {!this.props.isCheckMainGuest && !guest.isMainGuest && (
              <p className="guest__error-message">
                <FormattedMessage id="error.message.main.guest" />
              </p>
            )}
            <Checkbox
              className="guest__checkbox-main-guest"
              checked={guest.isMainGuest}
              disabled={disabled}
              onChange={(e) => {
                this.props.onChangeMainGuest(
                  e,
                  memberFirstName as string,
                  memberLastName as string,
                  memberPhone as string,
                  memberEmail as string,
                  index,
                );
              }}
            >
              <FormattedMessage id="main.guest" />
            </Checkbox>
          </>
        )}
        <div className="guest__rule">
          <div className="guest__info-wrapper">
            <Form.Item className="preffixName">
              <div className="guest__select-wrapper">
                <Select
                  defaultValue="Mr"
                  disabled={disabled}
                  onChange={(value) => {
                    this.props.onChangeNamePrefix(value, index);
                  }}
                  value={guest.namePrefix}
                >
                  {namesPrefix.map((prefix, index) => (
                    <Option key={index} value={prefix.namePrefix}>
                      {prefix.namePrefix}
                    </Option>
                  ))}
                </Select>
              </div>
            </Form.Item>
            <Form.Item
              name={guest.isMainGuest ? 'mainGuestGivenName' : `guests[${index}].givenName`}
              rules={[
                {
                  required: isFirstAdult,
                  pattern: checkInLatinLetters,
                  message: intl.formatMessage({ id: 'error.message.contact.name' }),
                },
                {
                  min: MIN_LENGTH_NAME,
                  message: intl.formatMessage({ id: 'error.message.min' }, { min: MIN_LENGTH_NAME }),
                },
            ]}
            >
              <div className="guest__name-input-wrapper">
                <span className="guest__input-label">
                  <FormattedMessage id="first.name" />
                </span>
                <div className="guest__input">
                  <Input
                    placeholder={intl.formatMessage({ id: 'first.name' })}
                    value={guest.givenName}
                    maxLength={255}
                    disabled={disabled}
                    onChange={(e) => {
                      this.props.onChangeFirstName(e, index);
                    }}
                  />
                </div>
              </div>
            </Form.Item>
            <Form.Item
              name={guest.isMainGuest ? `mainGuestSurname` : `guests[${index}].surname`}
              rules={[
                {
                  required: isFirstAdult,
                  pattern: checkInLatinLetters,
                  message: intl.formatMessage({ id: 'error.message.contact.name' }),
                },
                {
                  min: MIN_LENGTH_NAME,
                  message: intl.formatMessage({ id: 'error.message.min' }, { min: MIN_LENGTH_NAME }),
                },
            ]}
            >
              <div className="guest__name-input-wrapper">
                <span className="guest__input-label">
                  <FormattedMessage id="last.name" />
                </span>
                <div className="guest__input">
                  <Input
                    placeholder={intl.formatMessage({ id: 'last.name' })}
                    value={guest.surname}
                    maxLength={255}
                    disabled={disabled}
                    onChange={(e) => {
                      this.props.onChangeLastName(e, index);
                    }}
                  />
                </div>
              </div>
            </Form.Item>
          </div>
          {guest.isMainGuest && (
            <div className="guest__info-phone-wrapper">
              <Form.Item
                name={`mainGuestPhone`}
                validateTrigger="onBlur"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: intl.formatMessage({ id: 'error.message.phone' }),
                  },
                  {
                    max: MAX_LENGTH_NUMBER,
                    message: intl.formatMessage({ id: 'error.message.number.must.be' }),
                  },
                ]}
              >
                <div className="guest__name-input-wrapper test-test">
                  <span className="guest__input-label">
                    <FormattedMessage id="mobile.phone" />
                  </span>
                  <div className="guest__input">
                    <Input.Group compact>
                      <Select
                        defaultValue="United States"
                        disabled={disabled}
                        optionFilterProp="label"
                        showSearch={true}
                        options={countriesCode.map(code => ({
                          value: code.name,
                          label: `${code.isoCode} ${code.dialCode}`
                        }))}
                      >
                      </Select>
                      <Input
                        style={{ width: '50%' }}
                        placeholder={intl.formatMessage({ id: 'phone.number' })}
                        disabled={disabled}
                        onChange={(e) => {
                          this.props.onChangePhone(e, index);
                        }}
                        value={guest.phone}
                        onFocus={onFocusPhone}
                        maxLength={12}
                      />
                    </Input.Group>
                  </div>
                </div>
              </Form.Item>
            </div>
          )}

          {guest?.isMainGuest ? (
            <div className="room-info__guest-message">
              <FormattedMessage id="name.changes" />
            </div>) : null}

          {isFirstAdult && roomGuestCount > 1 && (
            <div className="guest__info-display-more" onClick={() => this.props.onDisplayOptional(indexRoom)}>
              {displayOptional && <FormattedMessage id="review.book.guest.hide.optionals" />}
              {!displayOptional && <FormattedMessage id="review.book.guest.display.optionals" />}
            </div>)}
        </div>
      </div>
    ) : null;
  }
}

export const GuestForm = injectIntl(GuestFormComponent);
