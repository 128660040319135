import React from 'react';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'antd';
import { get } from 'lodash';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';

import { WalletPriceType, getFullAddress, getWalletPrices, isAllInclusivetMealPlan, isFreeBreakfastMealPlan } from '@utils';
import { formatDateCheckInOut,  renderNumber, insertSpacesAndPluralize, GetWalletNumber, isVacationRentalsFromPath } from '@share/utils';
import { RootState } from '@share/utils';
import { IReviewBookState, setCoupon, setRedeemCode, setUpdateCoupon, setSelectedHotelReviewClientCash, setSelectedHotelReviewClientCashStr, IInsuranceState, InsuranceSelection } from '@store/slices';
import { IHotelsState, ILoginState, IMenuState, hotelsActions } from '@share/store/slices';
import { IRedeemCode, ITaxesAndFees } from '@common-types';
import { ClockSvg } from '@assets';
import { HotelStars, ModalPrices, PriceSkeleton, Currency, Refundability, WalletInput, WalletMessage } from '@components';
import { DATE_FORMAT_CHECK_IN_OUT_YEAR, NON_REFUNDABLE_TYPE, NULL_VALUE, REFUNDABLE_TYPE, USD_CURRENCY } from '@constants';
import { IAccount, IClientCash, IUserWallet } from '@share/common-types';

import Package from '@assets/images/package.png';
import FreeBreakfast from '@assets/images/free-breakfast.png';
import AllInclusive from '@assets/images/all-inclusive.png';

import './style.scss';

interface IMapStateToProps {
  reviewBookStore: IReviewBookState;
  hotelsStore: IHotelsState;
  loginStore: ILoginState;
  navigationMenuStore: IMenuState;
  insuranceStore: IInsuranceState;
}

interface IMapDispatchToProps {
  setCoupon: (coupon: string) => void;
  setUpdateCoupon: (coupon: boolean) => void;
  setSelectedHotelReviewClientCash: (selectedClientCash: number) => void;
  setSelectedHotelReviewClientCashStr: (selectedClientCash: string) => void;
  setSelectedHotelSearchClientCash: (selectedClientCash: IClientCash) => void;
  setRedeemCode: (coupon: IRedeemCode) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps, WrappedComponentProps { }

const ZERO = 0;

interface IState {
  isModalPricesVisible: boolean;
}

class RoomInfoComponent extends React.Component<IProps, IState> {

  state: IState = {
    isModalPricesVisible: false
  };

  componentDidMount() {
    this.props.setCoupon('');
    this.props.setRedeemCode(NULL_VALUE);
    this.props.setUpdateCoupon(false);
  }

  getFeeNode = (fee: ITaxesAndFees, index: number): React.ReactNode => {
    const isNotZeroFee = fee.value > ZERO;

    return (
      <div className="room-info__fee" key={index}>
        <p className="room-info__fee__price">
          {isNotZeroFee ? <FormattedMessage id={fee.title} /> : fee.remarks}{' '}
          <span className="room-info__fee__price__value">
            {fee.isApproximateValue ? <FormattedMessage id="fee.approx" /> : null}{' '}
            {fee.formattedValue}
          </span>
        </p>
        {isNotZeroFee ? (
          <>
            {fee.unitType && (
              <p>
                <FormattedMessage id="fee.unit.type" />: <FormattedMessage id={fee.unitType} />
              </p>
            )}
            {fee.frequencyType && (
              <p>
                <FormattedMessage id="fee.frequency.type" />: <FormattedMessage id={fee.frequencyType} />
              </p>
            )}
            {fee.remarks && (
              <p>
                <FormattedMessage id="fee.remarks" />: <FormattedMessage id={fee.remarks} />
              </p>
            )}
          </>
        ) : null}
      </div>
    );
  };

  getPayAtPropertyFeesNode = (fees: ITaxesAndFees[]): React.ReactNode => {
    return (
      <>
        <div className="room-info__pay-info" style={{ marginTop: '10px' }}>
          <p className="room-info__pay-info-fee-text">
            <FormattedMessage id="pay.property" />:
          </p>
        </div>
        <div className="room-info__fee-wrapper">{fees.map(this.getFeeNode)}</div>
      </>
    );
  };

  getOptionalFeesNode = (fees: ITaxesAndFees[]): React.ReactNode => {
    return (
      <>
        <div className="room-info__pay-info" style={{ marginTop: '10px' }}>
          <p className="room-info__pay-info-fee-text">
            <FormattedMessage id="other.optional.fees" />:
          </p>
        </div>
        <div className="room-info__fee-wrapper">{fees.map(this.getFeeNode)}</div>
      </>
    );
  };

  getPackageInfo = () => {
    return { cancellationPolicyText: '', refundabilityText: '' };
  };

  handleOnSelectClientCash = (clientCash: string) => {
    const { hotelsStore } = this.props;
    const { selectedHotelSearchClientCash } = hotelsStore;

    this.props.setSelectedHotelReviewClientCash(Number(clientCash));
    this.props.setSelectedHotelReviewClientCashStr(clientCash);
    this.props.setSelectedHotelSearchClientCash({ ...selectedHotelSearchClientCash, selectedPropertyReviewClientCash: Number(clientCash) });
  }

  render(): React.ReactNode {
    const { reviewBookStore, navigationMenuStore, hotelsStore, loginStore, insuranceStore, history, intl } = this.props
    const {
      bookingComplete,
      isUpdatePrice,
      isBookingInProgress,
      bookingSummary,
      selectedHotelReviewClientCash,
      selectedHotelReviewClientCashStr,
      booking
    } = reviewBookStore;
    const { isModalPricesVisible } = this.state;
    const {
      name,
      address,
      roomsCount,
      checkIn,
      checkOut,
      nightsCount,
      bookingPrice,
      stars,
      city,
      countryCode,
      countryName,
      zipCode,
      state,
      balance,
    } = bookingSummary.bookingCard;
    const {
      totalTaxes,
      payAtPropertyFees,
      optionalFees,
      totalFarePrice,
      savings,
      totalLeisureCredits,
      maxWalletClientCash,
    } = bookingPrice;
    const { refundability } = bookingSummary.bookingCard.package;
  
    const { selectedHotel } = hotelsStore;
    const { items } = navigationMenuStore;
    const { selection, insurance, baseRequest } = insuranceStore;
    const { account, userWallet, userWalletData } = loginStore;
    
    const isNotification = bookingComplete || isBookingInProgress;

    const mealBasisInfo = bookingSummary.bookingCard.package.rooms[ZERO].roomBasis;
    const mealBasisPrice = bookingSummary.bookingCard.package.priceDifference;
    const pricePerNight = bookingSummary.bookingCard.package.pricePerNight;
    const isPackage = bookingSummary.bookingCard.package.isPackage;
    const currency = bookingSummary.bookingCard.package.currency;

    const isPayAtPropertyFeesSpecified = !isEmpty(payAtPropertyFees);
    const isOptionalFeesSpecified = !isEmpty(optionalFees);
    const isAnyApproxValueSpecified =
      (isPayAtPropertyFeesSpecified &&
        !isUndefined(payAtPropertyFees.find((f) => f.isApproximateValue))) ||
      (isOptionalFeesSpecified && !isUndefined(optionalFees.find((t) => t.isApproximateValue)));

    const hasClientCash = account?.hasClientCash;
    const clientCashPercentage = account?.clientCashPercentage;
    const isLogged = !!userWallet;
  
    const usedClientCashAmount = (bookingComplete && booking) ? get(booking?.hotelSegments, '[0].clientCash', 0) : 0;
    const balancePaid = (bookingComplete && booking) ? get(booking?.hotelSegments, '[0].totalPay', 0) : 0;
    const refundabilityFinal = (hasClientCash && isLogged && (selectedHotelReviewClientCash > 0 || usedClientCashAmount > 0)) ? NON_REFUNDABLE_TYPE : refundability;
    const other = bookingSummary.bookingCard.bookingPrice.taxes;
    const roomInfo: any = get(bookingSummary.bookingCard.package.rooms, '[0]', {});
    
    const isFreeBreakfast = isFreeBreakfastMealPlan(selectedHotel?.mealPLan) || isFreeBreakfastMealPlan(roomInfo.roomBasis);
    const isAllInclusive = isAllInclusivetMealPlan(selectedHotel?.mealPLan) || isAllInclusivetMealPlan(roomInfo.roomBasis);

    const isVacationRentals = isVacationRentalsFromPath(history);

    const locale = account?.locale;
    const disableExclusivePricingTagVacationRentals= account?.disableExclusivePricingTagVacationRentals;

    const isMLM = items?.isMLM;
    const balanceInt = balance;

    const convertionRate = userWalletData?.convertionRate ? userWalletData?.convertionRate : 1;

    const usedClientCashInt = Math.round(usedClientCashAmount / convertionRate);
    const usedClientCash = account?.walletNoDecimals ? Math.floor(usedClientCashInt) : usedClientCashInt;


    const clientCashCalculated = bookingComplete ? usedClientCashAmount : (selectedHotelReviewClientCash ? selectedHotelReviewClientCash : 0);
    const clientCash = clientCashCalculated * convertionRate;
    const finalBalanceIntermediate = (bookingComplete ? balancePaid : balanceInt) - (bookingComplete ? 0: clientCash);
    const finalBalanceCalculated = Math.floor(finalBalanceIntermediate * 100) / 100;
    const finalBalance = finalBalanceCalculated > 0 ? finalBalanceCalculated : 0;
    const eligibleClientCash = finalBalance > 0 ? finalBalance * ((clientCashPercentage && !isEmpty(clientCashPercentage)) ? parseInt(clientCashPercentage) : 0) / 100 : 0;

    const maxWalletClientCashInt = maxWalletClientCash;
    const maxWalletClientCashValueInt = maxWalletClientCashInt / convertionRate;
    const maxWalletClientCashValue = account?.walletNoDecimals ? Math.floor(maxWalletClientCashValueInt) : maxWalletClientCashValueInt;

    const walletPrices = getWalletPrices(account as IAccount, userWalletData as IUserWallet, hotelsStore?.selectedHotelSearchClientCash, balanceInt, maxWalletClientCashValue, WalletPriceType.Book);

    const maxWalletClientCashService = walletPrices?.maxClientCashAllow ? walletPrices?.maxClientCashAllow : 0;
    const maxWalletClientCashFinal = (maxWalletClientCashValue && maxWalletClientCashValue <= maxWalletClientCashService) ? maxWalletClientCashValue : maxWalletClientCashService;
    const maxWalletClientCashCalculated = account?.walletNoDecimals ? Math.floor(maxWalletClientCashFinal) : maxWalletClientCashFinal;

    const displayWalletSavings = (account?.walletWalletSavings && (walletPrices?.priceSavings > 0 || walletPrices?.maxClientCashAmountAllow > 0) && !!walletPrices?.maxClientCashAmountAllow) || isMLM;
    
    const insuranceBalance = (selection === InsuranceSelection.YES && !!insurance?.productDetails?.price) ? insurance?.productDetails?.price : 0;

    return (
      <div className="room-info position-sticky">
        <div className="room-info__wrapper" style={{ borderTopLeftRadius: '0px', borderTopRightRadius: '0px' }}>
          <div className="room-info__content">
            <p className="room-info__hotel-name">{name}</p>
            <div className="hotel-info__stars">
              <HotelStars stars={stars} />
            </div>

            <p className="room-info__address">
              {getFullAddress(address, city, state, zipCode, countryCode, countryName).join(', ')}
            </p>

            <div className="room-info__room-info-block">
              <p className="room-info__room-info-title">{roomInfo.roomName}</p>
              <p className="room-info__room-info-description">{insertSpacesAndPluralize(roomInfo.bedType)}</p>
              <p className="room-info__room-info-description">{roomInfo.roomBasis}</p>
            </div>

            {([NON_REFUNDABLE_TYPE, REFUNDABLE_TYPE].includes(refundabilityFinal)) && (
              <Refundability />)}

            <div className="room-info__period">
              <div className="room-info__period-item">
                <p className="room-info__period-item-title">
                  <FormattedMessage id="check.in" />:
                </p>
                <p className="room-info__period-item-description">
                  {formatDateCheckInOut(checkIn, DATE_FORMAT_CHECK_IN_OUT_YEAR, locale)}
                </p>
              </div>
              <div className="room-info__period-item">
                <p className="room-info__period-item-title">
                  <FormattedMessage id="check.out" />:
                </p>
                <p className="room-info__period-item-description">
                  {formatDateCheckInOut(checkOut, DATE_FORMAT_CHECK_IN_OUT_YEAR, locale)}
                </p>
              </div>
              <div className="room-info__period-item">
                <FormattedMessage id="nights.stay" values={{ count: nightsCount }} />
              </div>
            </div>

          </div>
        </div>

        <div className="room-info__wrapper" style={{ marginTop: '15px', paddingTop: '10px' }}>
          <div className="room-info__content border-bm" style={{ paddingTop: '10px', marginBottom: '10px' }}>
            <h4 className="room-info__price-title">
              <FormattedMessage id="price.details" />
            </h4>

            {isUpdatePrice && <PriceSkeleton />}

            {(isPackage && (!isVacationRentals || !disableExclusivePricingTagVacationRentals)) &&
              <div className="room-info__label-container">
                <div className="room-info__label-package">
                  <p className="room-info__label-title-package">
                    <img src={Package} style={{width: 14, marginRight: 5}} />
                    <FormattedMessage id="result.package" />
                  </p>
                </div>
              </div>
            }

            {isFreeBreakfast &&
              <div className={`room-info__label-free-breakfast-container ${isPackage ? 'package' : ''}`}>
                <div className="room-info__label-free-breakfast">
                  <p className="room-info__label-title-free-breakfast">
                    <img src={FreeBreakfast} style={{width: 14, marginRight: 5}} />
                    <FormattedMessage id="result.free-breakfast" /> 
                  </p>
                </div>
              </div>
            }

            {isAllInclusive &&
              <div className={`room-info__label-all-inclusive-container ${isPackage ? 'package' : ''}`}>
                <div className="room-info__label-all-inclusive">
                  <p className="room-info__label-title-all-inclusive">
                    <img src={AllInclusive} style={{width: 14, marginRight: 5}} />
                    <FormattedMessage id="result.all-inclusive" /> 
                  </p>
                </div>
              </div>
            }

            {!isUpdatePrice && (
              <>
                <div className="room-info__pay-info">
                  <p className="room-info__pay-info-text">
                    <FormattedMessage id="avg.nightly.rate" /> <Currency currency={currency} />{renderNumber(pricePerNight)}{' '}
                    <FormattedMessage id="per.night" />
                  </p>
                </div>


                <div className="room-info__price-info">
                  <p className="room-info__price-info-text">
                    <FormattedMessage id={savings && totalFarePrice != balanceInt ? "public.price.details" : "total.price.details"} /> {roomsCount}{' '}
                    <FormattedMessage id="room" values={{ count: roomsCount }} /> x{' '}
                    <FormattedMessage id="nights" values={{ count: nightsCount }} />
                  </p>
                  <p className="room-info__price-info-text">
                    <Currency currency={currency} />{renderNumber(totalFarePrice, 2)}
                  </p>
                </div>

                {mealBasisPrice !== ZERO && (
                  <div className="room-info__price-info pt-0">
                    <p className="room-info__price-meal-basis-text">
                      <FormattedMessage id="including" /> {mealBasisInfo}
                      <Currency currency={currency} />{renderNumber(mealBasisPrice, 2)}
                    </p>
                  </div>
                )}

                <div className='room-info__other__container'>
                  {other.map((ot, index) => {
                    return (
                      <div key={index} className="room-info__other__name">
                        <p className="room-info__price-info-text">
                          <FormattedMessage id={ot.type} />
                        </p>
                        <p className="room-info__other__value">
                          <Currency currency={currency} />{renderNumber(ot.amount, 2)}
                        </p>
                      </div>)
                  })}
                </div>

                {(!!savings && (!displayWalletSavings || account?.isMLM)) && (
                  <div className="room-info__price-info">
                    <p className="room-info__price-savings-text">
                      <FormattedMessage id={items?.isPromoSite ? 'points' : items?.promo ? 'savings' : 'member.savings.label'} />
                    </p>
                    <p className="room-info__price-savings-text">
                      -<Currency currency={currency} />{renderNumber(savings, 2)}
                    </p>
                  </div>)}

                {(displayWalletSavings && !bookingComplete) ? (
                  <div className="room-info__price-info">
                    <p className="room-info__price-savings-text">
                      <FormattedMessage id="wallet.save.up.to" values={{ clientCashName: account?.walletClientCashName }} />
                    </p>
                    <p className="room-info__price-savings-text">
                      {GetWalletNumber(walletPrices?.clientCashAmountApplied, account as IAccount)}
                    </p>
                  </div>) : null}

                {(!!totalLeisureCredits && items?.pointsSettings?.hotel?.isRemainingBalanceEnabled && !account?.isMLM) ? (
                  <div className="room-info__price-credits">
                    <p className="room-info__price-credits-text">
                      <FormattedMessage id="leisure.credits.balance" /> {totalLeisureCredits}
                    </p>
                  </div>) : null}

                {!!totalTaxes && (
                  <div className="room-info__price-info">
                    <p className="room-info__price-info-text">
                      <FormattedMessage id="taxes.and.fees" />
                      <Tooltip title={''} placement="topLeft" style={{ marginLeft: '10px' }}>
                        <FontAwesomeIcon icon={faInfoCircle} size="1x" onClick={() => this.setState({ isModalPricesVisible: true })} />
                      </Tooltip>
                    </p>
                    <p className="room-info__price-info-text">
                      <Currency currency={currency} />{renderNumber(totalTaxes, 2)}
                    </p>
                  </div>)}

                {(selection === InsuranceSelection.YES && !!insurance) ? (
                  <div className="room-info__price-info">
                    <p className="room-info__price-info-text">
                      <FormattedMessage id="insurance.booking.protection" />
                    </p>
                    <p className="room-info__price-info-text">
                      <Currency currency={baseRequest?.totalPrice?.isoCurrencyCode ? baseRequest?.totalPrice?.isoCurrencyCode : USD_CURRENCY} />{renderNumber(insurance?.productDetails?.price, 2)}
                    </p>
                  </div>) : null}

                {hasClientCash ? (
                  <>
                    {(!account?.walletIsExternal && !items?.isMLM) ? (
                      <div className="room-info__price-info">
                        <p className="room-info__price-client-cash-text">
                          <FormattedMessage id="wallet.client.cash.eligible" values={{ value: clientCashPercentage, clientCashName: account?.walletClientCashName }} />
                          <Tooltip title={intl.formatMessage({ id: 'wallet.client.cash.eligible.tooltip' })} placement="topLeft">
                            <FontAwesomeIcon icon={faInfoCircle} size="1x" style={{ marginLeft: '5px' }} />
                          </Tooltip>
                        </p>
                        <p className="room-info__price-client-cash-text">
                          <Currency currency={currency} />{renderNumber(eligibleClientCash, 2)}
                        </p>
                      </div>) : null}
                  </>) : null}
              </>
            )}

            {/*!items?.promo && <DiscountCode />*/}
          </div>

          <WalletInput
            display={(hasClientCash && isLogged && !bookingComplete) || (isMLM && !bookingComplete) as boolean}
            bottomBorder
            currency={currency}
            maxWalletClientCash={maxWalletClientCashCalculated}
            price={balanceInt}
            selectedClientCash={selectedHotelReviewClientCash}
            selectedClientCashStr={selectedHotelReviewClientCashStr}
            onSelectClientCash={this.handleOnSelectClientCash}
          />

          {!displayWalletSavings ? (
            <WalletMessage
              price={balanceInt}
              maxClientCash={maxWalletClientCashCalculated}
              currency={currency}
              type={WalletPriceType.Book}
              displayOnlyRate={bookingComplete}
              isOneLine
              style={{ paddingLeft: '15px', paddingRight: '15px', marginBottom: '15px' }}
            />) : null}

          {(account?.walletDisplayGetMoreClientCash && walletPrices?.maxClientCashAllow > walletPrices?.maxClientCashPermited) ? (
            <div className="room-info__content" style={{ paddingBottom: '0px', color: 'red', fontWeight: 'bold' }}>
              <FormattedMessage id="wallet.client.get_more" values={{ diff:  walletPrices?.maxClientCashAllow - walletPrices?.maxClientCashPermited, clientCashName: account?.walletClientCashName }} />
            </div>) : null}

          {(hasClientCash && bookingComplete) ? (
            <div className="room-info__price-info" style={{ paddingLeft: '15px', paddingRight: '15px', paddingTop: '0px', paddingBottom: '10px' }}>
              <p className="room-info__price-client-cash-text">
                <FormattedMessage id="wallet.client.cash.applied" values={{ clientCash: usedClientCash, clientCashName: account?.walletClientCashName }} />
              </p>
              <p className="room-info__price-client-cash-text">
                <Currency currency={currency} />{renderNumber(usedClientCashAmount, 2)}
              </p>
            </div>) : null}

          {!isUpdatePrice && (
            <div className="room-info__content">
              <div className="room-info__total-price-info-wrapper">
                <div className="room-info__total-price-info">
                  <p className="room-info__price-info-text-total">
                    <FormattedMessage id={bookingComplete ? 'total.paid' : 'total.pay'} />:
                  </p>
                </div>
                <div className="room-info__price-info-amount-wrapper">
                  {(!bookingComplete && savings) ? (
                    <p className="room-info__public-price-amount">
                      <Currency currency={currency} />{renderNumber(balanceInt + savings, 2)}
                    </p>
                  ) : null}
                  <p className="room-info__price-info-amount">
                    <Currency currency={currency} />{renderNumber(finalBalance + insuranceBalance, 2)}
                  </p>
                </div>
              </div>

              {totalTaxes !== ZERO ? (
                <p className="room-info__note">
                  <FormattedMessage id="including.taxes.fees" />{' '}
                  <Currency currency={currency} />{renderNumber(totalTaxes, 2)}
                </p>
              ) : (
                <p className="room-info__note">
                  <FormattedMessage id="zero.charge.for.taxes.fees" />
                </p>
              )}

              {isAnyApproxValueSpecified && (
                <div className="room-info__fee-wrapper">
                  <div className="room-info__note">
                    <FormattedMessage id="fee.instruction" />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        {(!isUpdatePrice && (isPayAtPropertyFeesSpecified || isOptionalFeesSpecified || isAnyApproxValueSpecified)) && (
          <>
            <div className="room-info__wrapper" style={{ marginTop: '15px' }}>
              <div className="room-info__content" style={{ paddingTop: '10px', marginBottom: '10px' }}>
                <h4 className="room-info__price-title">
                  <FormattedMessage id="additional.charges" />
                </h4>
                {isPayAtPropertyFeesSpecified && this.getPayAtPropertyFeesNode(payAtPropertyFees)}

                {isOptionalFeesSpecified && this.getOptionalFeesNode(optionalFees)}

                {isAnyApproxValueSpecified && (
                  <div className="room-info__fee-wrapper">
                    <div className="room-info__note">
                      <FormattedMessage id="fee.instruction" />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}

        {!isNotification && (
          <div className="room-info__label">
            <ClockSvg />
            <p className="room-info__label-text">
              <FormattedMessage id="act.fast" />
            </p>
          </div>
        )}

        <ModalPrices
          visible={isModalPricesVisible}
          onCancel={() => this.setState({ isModalPricesVisible: false })}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    reviewBookStore: state.reviewBookStore,
    hotelsStore: state.hotelsStore,
    navigationMenuStore: state.navigationMenuStore,
    loginStore: state.loginStore,
    insuranceStore: state.insuranceStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  setCoupon,
  setRedeemCode,
  setUpdateCoupon,
  setSelectedHotelReviewClientCash,
  setSelectedHotelReviewClientCashStr,
  setSelectedHotelSearchClientCash: hotelsActions.setSelectedHotelSearchClientCash
};

export const RoomInfo = connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(RoomInfoComponent)));

