import axios, { Canceler } from 'axios';
import moment from 'moment';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { isEmpty } from 'lodash';

import {
  IHotel,
  ILocation,
  IRoom,
  IHotelsResponse,
  ICounters,
  ISessionKey,
  IBounds,
  IHotelsRecentSearches,
  IClientCash,
} from '@share/common-types';
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_MAP_LIMIT,
  Urls,
  SESSION_EXPIRED_STATUS,
  SESSION_EXPIRED_MESSAGE,
  SESSION_KEY_LABEL,
  SortTypes,
  HOTEL_RECENT_SEARCHES_LABEL,
  DEAL_HOME_LABEL,
  ALL_INCLUSIVE_KEY,
} from '@share/constants';

import { getHeaders, axiosInstance, getSearchBody, UrlUtils, getTimeout, RootState, AppThunk, delay, getSelectedCurrency, hasChangedCurrency } from '@share/utils';

import { datesActions } from './dates';
import { roomsActions } from './rooms';
import { locationActions } from './locations';
import { filtersActions } from './filters';

const oneItem = 1;
const zeroItem = 0;
const reRenderNum = 0.000000001;

export interface IHotelsState {
  hotels: IHotel[];
  similarHotels: IHotel[];
  mapHotels: IHotel[];
  selectedCompareHotels: IHotel[];
  isMapView: boolean;
  loading: boolean;
  loadingSimilar: boolean;
  mapLoading: boolean;
  loadingMore: boolean;
  loadingFilters: boolean;
  loadingFilter: boolean;
  error: string;
  sessionKey?: ISessionKey;
  similarSessionKey?: ISessionKey;
  isSearch: boolean;
  isWidget: boolean;
  pageNumber: number;
  searchedLocation: string;
  lastSearchedLocation: ILocation;
  counters: ICounters;
  mapCounters: ICounters;
  prevHotels: IHotel[];
  isSessionExpired: boolean;
  bounds: IBounds;
  recentSearches: IHotelsRecentSearches[];
  errorRecentSearches: string;
  loadingRecentSearches: boolean;
  referenceNumber: string;
  selectedHotel: IHotel;
  agencyNumber: string;
  selectedHotelSearchClientCash: IClientCash;
}

const initialState: IHotelsState = {
  hotels: null,
  similarHotels: null,
  mapHotels: null,
  selectedCompareHotels: [],
  isMapView: false,
  loading: false,
  loadingSimilar: false,
  loadingMore: false,
  mapLoading: false,
  loadingFilters: false,
  loadingFilter: false,
  error: '',
  isSearch: false,
  isWidget: false,
  pageNumber: DEFAULT_PAGE_NUMBER,
  searchedLocation: '',
  counters: null,
  mapCounters: null,
  lastSearchedLocation: null,
  bounds: null,
  prevHotels: [],
  isSessionExpired: false,
  recentSearches: [],
  errorRecentSearches: '',
  loadingRecentSearches: false,
  referenceNumber: null,
  selectedHotel: null,
  agencyNumber: null,
  selectedHotelSearchClientCash: null,
};

const hotelsSlice = createSlice({
  name: 'hotels',
  initialState,
  reducers: {
    setIsSearch: (state: IHotelsState, { payload }: PayloadAction<boolean>) => {
      state.isSearch = payload;
    },
    setIsWidget: (state: IHotelsState, { payload }: PayloadAction<boolean>) => {
      state.isWidget = payload;
    },
    setBounds: (state: IHotelsState, { payload }: PayloadAction<IBounds>) => {
      state.bounds = payload;
    },
    setIsMapView: (state: IHotelsState, { payload }: PayloadAction<boolean>) => {
      state.isMapView = payload;
    },
    setLoading: (state: IHotelsState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setLoadingSimilar: (state: IHotelsState, { payload }: PayloadAction<boolean>) => {
      state.loadingSimilar = payload;
    },
    setMapLoading: (state: IHotelsState, { payload }: PayloadAction<boolean>) => {
      state.mapLoading = payload;
    },
    setLastSearchedLocation: (state: IHotelsState, { payload }: PayloadAction<ILocation>) => {
      state.lastSearchedLocation = payload;
    },
    setSelectedHotelSearchClientCash: (state: IHotelsState, { payload }: PayloadAction<IClientCash>) => {
      state.selectedHotelSearchClientCash = payload;
    },
    setError: (state: IHotelsState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setHotels: (
      state: IHotelsState,
      { payload }: PayloadAction<{ hotels: IHotel[]; isLoadMore: boolean }>,
    ) => {
      state.prevHotels = payload.hotels;
      state.selectedHotel = null;

      if (payload.isLoadMore) {
        state.hotels = [...(state.hotels || []), ...payload.hotels];
      } else {
        state.hotels = payload.hotels;
      }
    },
    setSimilarHotels: ( state: IHotelsState, { payload }: PayloadAction<{ hotels: IHotel[]; isLoadMore: boolean }>) => {
      if (payload.isLoadMore) {
        state.similarHotels = [...(state.similarHotels || []), ...payload.hotels];
      } else {
        state.similarHotels = payload.hotels;
      }
    },
    setSelectedCompareHotels: ( state: IHotelsState, { payload }: PayloadAction<IHotel[]>) => {
      state.selectedCompareHotels = payload;
    },
    resetHotels: (state: IHotelsState) => {
      state.prevHotels = [];
      state.hotels = [];
      state.similarHotels = [];
      state.selectedHotel = null;
      state.error = '';
      state.errorRecentSearches = '';
    },
    clearHotels: (state: IHotelsState) => {
      state.prevHotels = null;
      state.hotels = null;
      state.similarHotels = null;
      state.selectedHotel = null;
      state.selectedCompareHotels = [];
      state.error = '';
      state.errorRecentSearches = '';
    },
    setSessionKey: (state: IHotelsState, { payload }: PayloadAction<ISessionKey>) => {
      state.sessionKey = payload;
    },
    setReferenceNumber: (state: IHotelsState, { payload }: PayloadAction<string>) => {
      state.referenceNumber = payload;
    },
    setAgencyNumber: (state: IHotelsState, { payload }: PayloadAction<string>) => {
      state.agencyNumber = payload;
    },
    setSimilarSessionKey: (state: IHotelsState, { payload }: PayloadAction<ISessionKey>) => {
      state.similarSessionKey = payload;
    },
    setLoadingMore: (state: IHotelsState, { payload }: PayloadAction<boolean>) => {
      state.loadingMore = payload;
    },
    setLoadingFilters: (state: IHotelsState, { payload }: PayloadAction<boolean>) => {
      state.loadingFilters = payload;
    },
    setLoadingFilter: (state: IHotelsState, { payload }: PayloadAction<boolean>) => {
      state.loadingFilter = payload;
    },
    setPageNumber: (state: IHotelsState, { payload }: PayloadAction<number | undefined>) => {
      state.pageNumber = payload ? payload : state.pageNumber + oneItem;
    },
    setSearchedLocation: (state: IHotelsState, { payload }: PayloadAction<string>) => {
      state.searchedLocation = payload;
    },
    setCounters: (state: IHotelsState, { payload }: PayloadAction<ICounters>) => {
      state.counters = payload;
    },
    setMapCounters: (state: IHotelsState, { payload }: PayloadAction<ICounters>) => {
      state.mapCounters = payload;
    },
    setMapHotels: (state: IHotelsState, { payload }: PayloadAction<IHotel[]>) => {
      state.mapHotels = payload;
    },
    setIsSessionExpired: (state: IHotelsState, { payload }: PayloadAction<boolean>) => {
      state.isSessionExpired = payload;
    },
    setSelectedHotel: (state: IHotelsState, { payload }: PayloadAction<IHotel>) => {
      state.selectedHotel = payload;
    },
    setRecentSearches: (
      state: IHotelsState,
      { payload }: PayloadAction<IHotelsState['recentSearches']>,
    ) => {
      state.recentSearches = payload;
    },
    setLoadingRecentSearches: (state: IHotelsState, { payload }: PayloadAction<boolean>) => {
      state.loadingRecentSearches = payload;
    },
    setErrorRecentSearches: (state: IHotelsState, { payload }: PayloadAction<string>) => {
      state.errorRecentSearches = payload;
    },
  },
});

export const hotelsActions = hotelsSlice.actions;

export const hotelsReducer = hotelsSlice.reducer;

let cancelRequest: Canceler;
let expirationTimer: number;

export const setSessionExpirationTimer = (
  session: ISessionKey,
  dispatch: ThunkDispatch<RootState, unknown, Action<any>>,
): void => {
  if (expirationTimer) {
    clearTimeout(expirationTimer);
  }

  // @ts-ignore
  expirationTimer = setTimeout(() => {
    dispatch(hotelsActions.setIsSessionExpired(true));
  }, getTimeout(session?.expireDate));
};

export const resetHotels = (): AppThunk => {
  return async (dispatch, getState) => {
    const { hotelsStore } = getState();

    if (!hotelsStore.isMapView) {
      dispatch(hotelsActions.setLoading(true));
    }
    dispatch(hotelsActions.resetHotels());    
  };
};

export const resetHotelsFull = (): AppThunk => {
  return async (dispatch) => {
    dispatch(datesActions.resetDates());
    dispatch(locationActions.resetLocation());
    dispatch(roomsActions.resetRooms());
    dispatch(filtersActions.resetFilters());    
    dispatch(hotelsActions.setIsSearch(false));
    dispatch(hotelsActions.clearHotels());    
    dispatch(hotelsActions.setError(''));    
    dispatch(hotelsActions.setErrorRecentSearches(''));    
  };
};

export const resetHotelsFullWithParams = (): AppThunk => {
  return async (dispatch) => {
    dispatch(resetHotelsFull());
    dispatch(locationActions.applyLocation());
    dispatch(roomsActions.applyRooms());
    dispatch(datesActions.applyDates());    
  };
};

const getHotelsCall = (locationToSearch: ILocation, isLoadMore: boolean, isReuseSessionKey: boolean, getState: any, quick: boolean) => {
  const { datesStore, roomsStore, loginStore } = getState();
  const { startDate, endDate } = datesStore;
  const { rooms } = roomsStore;
  const { lifeStyle } = loginStore;

  return axiosInstance.post(
    Urls.Hotels,
    getSearchBody(
      locationToSearch,
      startDate,
      endDate,
      rooms,
      getState().hotelsStore,
      getState().filtersStore,
      isLoadMore,
      hasChangedCurrency() ? false : isReuseSessionKey,
      null,
      quick,
      getSelectedCurrency(loginStore.account),
      lifeStyle
    ),
    {
      ...getHeaders(),
      cancelToken: new axios.CancelToken((canceler: Canceler) => {
        cancelRequest = canceler;
      }),
    },
  );
}

const getHotelsRetry = (locationToSearch: ILocation, isLoadMore: boolean, isReuseSessionKey: boolean, getState: any, quick: boolean) => {
  try {
    return getHotelsCall(locationToSearch, isLoadMore, isReuseSessionKey, getState, quick);
  } catch (error) {
    delay(300);
    return getHotelsCall(locationToSearch, isLoadMore, isReuseSessionKey, getState, quick);
  }
}

export const getSimilarHotels = (
  isFiltersChange: boolean,
  isLoadMore: boolean
): AppThunk => {
  return async (dispatch, getState) => {

    const { locationsStore } = getState();
    const { location } = locationsStore;

    const locationToSearch = { ...location, type: 'Related' } ;
    
    if (isLoadMore) {
      dispatch(hotelsActions.setLoadingMore(true));
    } else {
      dispatch(hotelsActions.setLoadingSimilar(true));
    }

    try {
      const response = getHotelsRetry(locationToSearch, isLoadMore, false, getState, false);
      response.then(res => {
        const hotels = res.data.searchHotels.filter((h:any) => h?.hotelId !== location?.code);
        
        if (res.data.counters) {
          dispatch(hotelsActions.setCounters(res.data.counters));
        }
        
        if (res.data?.sessionKey?.isInvalid) {
          dispatch(hotelsActions.setSimilarSessionKey(null));
        }
        
        dispatch(hotelsActions.setSimilarSessionKey(res.data.sessionKey));
        
        if (isFiltersChange) {
          dispatch(hotelsActions.setSimilarHotels({ hotels, isLoadMore: false }));
        } else {
          dispatch(hotelsActions.setSimilarHotels({ hotels, isLoadMore }));
        }
      }).catch((error) => {
        console.error(error);
        dispatch(hotelsActions.setMapLoading(false));
      });
    } catch (error) {
      console.error(error);
      dispatch(hotelsActions.setMapLoading(false));
    } finally {
      if (isLoadMore) {
        dispatch(hotelsActions.setLoadingMore(false));
      } else {
        dispatch(hotelsActions.setLoadingSimilar(false));
      }
    }

  };
}

export const initializeSearch = (): AppThunk => {
  return async (dispatch) => {
    dispatch(hotelsActions.setIsSearch(true));
    dispatch(hotelsActions.setLoading(true));
    dispatch(hotelsActions.setMapLoading(true));
    dispatch(hotelsActions.setHotels({ hotels: [], isLoadMore: false }));
  }
}

export const getHotels = (
  lastSearchedLocation?: ILocation,
  isLoadMore = false,
  isFiltersChange = false,
  isReuseSessionKey = false,
): AppThunk => {
  return async (dispatch, getState) => {
    const { hotelsStore } = getState();

    dispatch(hotelsActions.setSelectedHotel(null));
    dispatch(hotelsActions.setError(''));    
    dispatch(hotelsActions.setErrorRecentSearches(''));    

    if (!isLoadMore) {
      dispatch(datesActions.applyDates());
      dispatch(roomsActions.applyRooms());
      dispatch(locationActions.applyLocation());
      dispatch(hotelsActions.setSelectedCompareHotels([]))
    }

    dispatch(hotelsActions.setSimilarHotels({ hotels: [], isLoadMore: false }));

    // initial setting hotels to show skeleton
    if (!hotelsStore.hotels) {
      dispatch(hotelsActions.setHotels({ hotels: [], isLoadMore: false }));
    }

    const { locationsStore, loginStore } = getState();
    const { location } = locationsStore;
    
    const locationToSearch = lastSearchedLocation ? lastSearchedLocation : location;

    let currentPage = hotelsStore.pageNumber;
    
    dispatch(hotelsActions.setSearchedLocation(locationToSearch.name));
    dispatch(hotelsActions.setLastSearchedLocation(locationToSearch));

    // cancel request if it's not pagination request
    if (cancelRequest) {
      cancelRequest();
    }

    let loadQuick = loginStore.account.hasQuick;
    const isB2C = loginStore.account.isB2C;
    const walletWalletSavings = loginStore.account.walletWalletSavings;

    const values = UrlUtils.getValues();
    if (values[DEAL_HOME_LABEL] && (values[DEAL_HOME_LABEL] as string) === ALL_INCLUSIVE_KEY && !isFiltersChange && !isLoadMore) {
      dispatch(filtersActions.setSortBy(SortTypes.PriceAsc));
    }
    else {
      if (!isB2C || walletWalletSavings) {
        if (!loadQuick && !isFiltersChange && !isLoadMore) {
          dispatch(filtersActions.setSortBy(SortTypes.BiggestSavingsPercentage));
        }
      } else {
        if (!loadQuick && !isFiltersChange && !isLoadMore) {
          dispatch(filtersActions.setSortBy(SortTypes.GuestRating));
        }
      }
    }

    if (isLoadMore) {
      dispatch(hotelsActions.setLoadingMore(true));
      loadQuick = false;
    } else {
      dispatch(hotelsActions.setMapLoading(true));
      dispatch(hotelsActions.setLoading(true));
      dispatch(hotelsActions.setIsSearch(true));
      dispatch(hotelsActions.setLoadingFilter(true));
      dispatch(hotelsActions.setPageNumber(DEFAULT_PAGE_NUMBER));
      currentPage = DEFAULT_PAGE_NUMBER;
    }

    if (isFiltersChange) {
      dispatch(hotelsActions.setPageNumber(DEFAULT_PAGE_NUMBER));
      dispatch(hotelsActions.setLoadingFilters(true));
      currentPage = DEFAULT_PAGE_NUMBER;
      loadQuick = false;
    }

    if (location?.type === 'Hotel') {
      loadQuick = false;
    }

    try {
      const { datesStore, roomsStore, filtersStore } = getState();
      const { startDate, endDate } = datesStore;
      const { searchHomes } = filtersStore;
      const { rooms } = roomsStore;
      let isFullyLoaded = false;

      if (loadQuick) {
        const requestOne = getHotelsRetry(locationToSearch, isLoadMore, isReuseSessionKey, getState, true);
        requestOne.then(resQuick => {
          if (resQuick.data.bounds) {
            dispatch(hotelsActions.setBounds(resQuick.data.bounds));
          } else if (!resQuick.data.searchHotels?.length) {
            dispatch(hotelsActions.setBounds({...hotelsStore?.bounds, northEast: {...hotelsStore?.bounds?.northEast,latitude: hotelsStore?.bounds?.northEast.latitude + reRenderNum,},}),);
          }

          if (resQuick.data.counters) {
            dispatch(hotelsActions.setCounters(resQuick.data.counters));
          }
          
          if (!!resQuick.data.searchHotels && !isFullyLoaded) {
            if (isFiltersChange) {
              dispatch(hotelsActions.setHotels({ hotels: resQuick.data.searchHotels, isLoadMore: false }));
            } else {
              dispatch(hotelsActions.setHotels({ hotels: resQuick.data.searchHotels, isLoadMore }));
            }
          }
  
          if (resQuick.data.searchHotels.length > 0){
            dispatch(hotelsActions.setLoading(false));
            dispatch(hotelsActions.setLoadingMore(false));
          }
        }).catch((error) => {
          handleGetHotelsErrors(error, dispatch);
        });

        if (currentPage === DEFAULT_PAGE_NUMBER) {
          dispatch(hotelsActions.setPageNumber(DEFAULT_PAGE_NUMBER + 1));
        }
      }


      const requestTwo = getHotelsRetry(locationToSearch, isLoadMore, isReuseSessionKey, getState, false);
      requestTwo.then(resFull => {
      	const recentSearchesStorage = localStorage.getItem(HOTEL_RECENT_SEARCHES_LABEL);
        const recentSearchesObject = !isEmpty(recentSearchesStorage)? JSON.parse(recentSearchesStorage) : [];
        const recentSearchesFiltered = recentSearchesObject?.length ? recentSearchesObject.filter((search: IHotelsRecentSearches) => moment().startOf('day').isSameOrBefore(moment(search.checkIn, 'yyyy-MM-DD'))) : [];
        const recentSearchesValid = recentSearchesFiltered.length > 20 ? recentSearchesFiltered.slice(0, 19) : recentSearchesFiltered;
        const currentSearch = {
          checkIn: moment(startDate, 'yyyy-MM-DD').format('yyyy-MM-DDT00:00:00'),
          checkOut: moment(endDate, 'yyyy-MM-DD').format('yyyy-MM-DDT00:00:00'),
          location: locationToSearch,
          locationCode: locationToSearch.code,
          type: searchHomes,
          rooms: [...rooms.map((r: any) => ({
            adultsCount: r.adultsCount,
            kids: r.kids
          }))]
        };
        const currentSearchStr = JSON.stringify(currentSearch);
        if (!recentSearchesValid.map((r: any) => JSON.stringify(r)).includes(currentSearchStr)) {
          localStorage.setItem(HOTEL_RECENT_SEARCHES_LABEL, JSON.stringify([currentSearch, ...recentSearchesValid]));
        }

          if (resFull.data.bounds) {
          dispatch(hotelsActions.setBounds(resFull.data.bounds));
          } else if (!resFull.data.searchHotels?.length) {
            if (locationToSearch?.geoLocation?.latitude && locationToSearch?.geoLocation?.longitude) {
              dispatch(
                hotelsActions.setBounds({
                  northEast: {
                    latitude: locationToSearch.geoLocation.latitude + reRenderNum,
                    longitude: locationToSearch.geoLocation.longitude + reRenderNum,
                  },
                  southWest: {
                    latitude: locationToSearch.geoLocation.latitude + reRenderNum,
                    longitude: locationToSearch.geoLocation.longitude + reRenderNum,
                  },
                }),
              );
            } else {
              dispatch(hotelsActions.setBounds(null));
            }
          }

          if (resFull.data?.sessionKey?.isInvalid) {
            dispatch(hotelsActions.setSessionKey(null));
            UrlUtils.removeFromUrl(SESSION_KEY_LABEL);
            dispatch(getHotels(locationToSearch));
            return;
          }

          dispatch(hotelsActions.setSessionKey(resFull.data.sessionKey));

          if (resFull.data.sessionKey) {
            dispatch(hotelsActions.setIsSessionExpired(false));
            // @ts-ignore
            setSessionExpirationTimer(resFull.data.sessionKey, dispatch);
          }

          if (resFull.data.counters) {
            dispatch(hotelsActions.setCounters(resFull.data.counters));
          }

          if (!!resFull.data.searchHotels) {
            if (isFiltersChange) {
              dispatch(hotelsActions.setHotels({ hotels: resFull.data.searchHotels, isLoadMore: false }));
            } else {
              dispatch(hotelsActions.setHotels({ hotels: resFull.data.searchHotels, isLoadMore: loadQuick ? true : isLoadMore }));
            }
            isFullyLoaded = true;
          }

          dispatch(hotelsActions.setLoading(false));
          dispatch(hotelsActions.setMapLoading(false));
          dispatch(hotelsActions.setLoadingMore(false));
          dispatch(hotelsActions.setLoadingFilter(false));
          dispatch(hotelsActions.setLoadingFilters(false));

          if (location?.type === 'Hotel') {
            dispatch(getSimilarHotels(isFiltersChange, isLoadMore));
          }
        }).catch((error) => {
          handleGetHotelsErrors(error, dispatch);
        });

    } catch (error) {
      handleGetHotelsErrors(error, dispatch);
    }
  };
};

const handleGetHotelsErrors = (error: any, dispatch: any) => {
  console.error(error);

  dispatch(hotelsActions.setMapLoading(false));
  dispatch(hotelsActions.setError(!isEmpty(error?.code) ? error?.code?.toString() : 'GENERIC'));

  // error when user session ended
  if (
    error?.response?.status === SESSION_EXPIRED_STATUS &&
    error?.response?.data &&
    error?.response?.data[zeroItem] === SESSION_EXPIRED_MESSAGE
  ) {
    dispatch(hotelsActions.setIsSessionExpired(true));
  }

  // error triggered by axios when we cancel request
  if (!(error instanceof axios.Cancel)) {
    dispatch(hotelsActions.setLoadingMore(false));
    dispatch(hotelsActions.setLoadingFilters(false));
    dispatch(hotelsActions.setLoading(false));
  }
  dispatch(hotelsActions.setLoadingFilter(false));
}

let cancelMapRequest: Canceler;

const getHotelsMapCall = async (data: any) => {  
  return await axiosInstance.post(Urls.HotelsMap, data, {
    ...getHeaders(),
    cancelToken: new axios.CancelToken((canceler: Canceler) => {
      cancelMapRequest = canceler;
    }),
  });
}

const getHotelsMapRetry = async (data: any) => {
  try {
    return await getHotelsMapCall(data);
  } catch (error) {
    delay(300);
    return await getHotelsMapCall(data);
  }
}

export const getMapHotels = (
  location: ILocation,
  startDate: string,
  endDate: string,
  rooms: IRoom[],
  hotelId: number = null,
): AppThunk => {
  return async (dispatch, getState) => {
    if (cancelMapRequest) {
      cancelMapRequest();
    }

    dispatch(hotelsActions.setSelectedHotel(null));
    dispatch(hotelsActions.setError(''));    
    dispatch(hotelsActions.setErrorRecentSearches(''));    

    dispatch(hotelsActions.setMapHotels([]));
    dispatch(hotelsActions.setMapLoading(true));

    const { hotelsStore, loginStore } = getState();
    const { lifeStyle } = loginStore;

    try {
      const options = getSearchBody(
        location,
        startDate,
        endDate,
        rooms,
        getState().hotelsStore,
        getState().filtersStore,
        false,
        false,
        hotelId,
        false,
        getSelectedCurrency(loginStore.account),
        lifeStyle
      );
      const { sessionKey } = hotelsStore;
      const data = {
        ...options,
        limit: DEFAULT_MAP_LIMIT,
        bounds: hotelsStore.bounds,
      };

      delete data.pageNumber;
      delete data.pageSize;

      data.sessionKey = sessionKey;

      const res: { data: IHotelsResponse } = await getHotelsMapRetry(data);

      if (res.data.bounds && hotelId) {
        dispatch(hotelsActions.setBounds(res.data.bounds));
      }

      dispatch(hotelsActions.setMapLoading(false));

      if (res.data.counters) {
        dispatch(hotelsActions.setMapCounters(res.data.counters));
      }

      dispatch(hotelsActions.setMapHotels(res.data.searchHotels));
    } catch (error) {
      console.error(error);
      dispatch(hotelsActions.setMapLoading(false));
      dispatch(hotelsActions.setError(!isEmpty(error?.code) ? error?.code?.toString() : 'GENERIC'));

      if (
        error?.response?.status === SESSION_EXPIRED_STATUS &&
        error?.response?.data &&
        error?.response?.data[zeroItem] === SESSION_EXPIRED_MESSAGE
      ) {
        dispatch(hotelsActions.setIsSessionExpired(true));
      }
    }
  };
};

export const getHotelsRecentSearches = (): AppThunk => {
  return async (dispatch) => {
  try {
      dispatch(hotelsActions.setLoadingRecentSearches(true));
      dispatch(hotelsActions.setErrorRecentSearches(''));    
  
      const recentSearchesStorage = localStorage.getItem(HOTEL_RECENT_SEARCHES_LABEL);
      const recentSearches = !isEmpty(recentSearchesStorage)? JSON.parse(recentSearchesStorage) : null;
      const recentSearchesFiltered = recentSearches? recentSearches.filter((search: IHotelsRecentSearches) => moment().startOf('day').isSameOrBefore(moment(search.checkIn, 'yyyy-MM-DD'))) : [];

      dispatch(hotelsActions.setRecentSearches(recentSearchesFiltered));
      dispatch(hotelsActions.setLoadingRecentSearches(false));
    } catch (error) {
      console.error(error);
      dispatch(hotelsActions.setErrorRecentSearches(error?.code?.toString()));
      dispatch(hotelsActions.setLoadingRecentSearches(false));
    }
  };
};
