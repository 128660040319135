import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import ReactGA from 'react-ga4';
import moment from 'moment';

import {
  IBookingSummary,
  IBookPackageWithCard,
  IBookingComplete,
  ICardBooking,
  IGuestsBooking,
  IErrorField,
  IInsuranceRequestTravelers,
} from '@common-types';
import { ISessionKey } from '@share/common-types';
import {
  VALIDATION_ERROR,
  EXTERNAL_ERROR,
  PRICE_CHANGED,
  SOLD_OUT,
  ERROR,
  STATUS_OK,
  HOTEL_BOOKING_GUEST_INFO_LABEL,
  HOTEL_BOOKING_ADDRESS_INFO_LABEL,
  SAVING_MISMATCH,
  HOTEL_CONFIRMED_INFO,
  HOTEL_BOOKING_COMPLETE,
  HOTEL_PACKAGE_ID,
  ROOMS_UNAVAILABLE_ERROR,
  CHECK_OUT_PAYMENT_ERROR,
  INVALID_PAYLOAD,
  CHECKOUT_ERROR,
  USA_STATES
} from '@constants';
import {
  SESSION_SEARCH_EXPIRED_MESSAGE,
  SESSION_EXPIRED_STATUS,
  Urls,
  ROOMS_SEARCH_LABEL,
  SESSION_KEY_LABEL_ROOMS,
  SESSION_KEY_LABEL,
  C_BOOKING_CONFIRMED,
} from '@share/constants';
import { getHeaders, axiosInstance, AppThunk, UrlUtils, getSelectedCurrency } from '@share/utils';
import { get, isString } from 'lodash';
import { scrollTop } from '@share/utils';
import { getUserCards, getUserWallet } from '@share/store/slices';
import { hotelsActions } from '../../share/store/slices/hotels-search';
import { getInsuranceQuote, insuranceActions, InsuranceSelection } from './insurance';
import { getHotelAddressFromStorage } from '@utils';

export interface IReviewBookState {
  bookingSummary: IBookingSummary;
  loading: boolean;
  error: string;
  expiredSession: boolean;
  isUpdatePrice: boolean;
  isGetCoupon: boolean;
  loadingBooking: boolean;
  errorBooking: string;
  bookingComplete: boolean;
  booking: IBookingComplete;
  guests: IGuestsBooking[];
  card: ICardBooking;
  errorsField: IErrorField[];
  isExternalError: boolean;
  isPriceChangedError: boolean;
  isSoldOutError: boolean;
  errorsBookingMessage: IErrorField[];
  isBookingInProgress: boolean;
  isSavingsMismatch: boolean;
  threeDSModalVisible: boolean;
  threeDSUrl: string;
  threeDSId: string;
  threeDSLoading: boolean;
  selectedHotelReviewClientCashStr: string;
  selectedHotelReviewClientCash: number;
}

const cardInitialState: ICardBooking = {
  addressLine: '',
  cvv: '',
  cardNumber: '',
  cardType: null,
  city: '',
  country: 'US',
  state: '',
  expireDate: '',
  holderName: '',
  phone: '',
  zipCode: '',
  addPaymentMethod: false
}

const initialState: IReviewBookState = {
  bookingSummary: null,
  loading: false,
  error: '',
  expiredSession: false,
  isUpdatePrice: false,
  isGetCoupon: false,
  loadingBooking: false,
  errorBooking: '',
  bookingComplete: false,
  booking: null,
  guests: null,
  card: { ...cardInitialState },
  errorsField: null,
  isExternalError: false,
  isPriceChangedError: false,
  isSoldOutError: false,
  errorsBookingMessage: null,
  isBookingInProgress: false,
  isSavingsMismatch: false,
  threeDSModalVisible: false,
  threeDSUrl: null,
  threeDSId: null,
  threeDSLoading: null,
  selectedHotelReviewClientCash: null,
  selectedHotelReviewClientCashStr: '',
};

const zeroItem = 0;

const reviewBookSlice = createSlice({
  name: 'review-book',
  initialState,
  reducers: {
    setLoadingBook: (state: IReviewBookState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setErrorBook: (state: IReviewBookState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setReviewBook: (state: IReviewBookState, { payload }: PayloadAction<IBookingSummary>) => {
      state.bookingSummary = payload;
    },
    setExpiredSession: (state: IReviewBookState, { payload }: PayloadAction<boolean>) => {
      state.expiredSession = payload;
    },
    setSavingsMismatch: (state: IReviewBookState, { payload }: PayloadAction<boolean>) => {
      state.isSavingsMismatch = payload;
    },
    setThreeDSModalVisible: (state: IReviewBookState, { payload }: PayloadAction<boolean>) => {
      state.threeDSModalVisible = payload;
    },
    setThreeDSUrl: (state: IReviewBookState, { payload }: PayloadAction<string>) => {
      state.threeDSUrl = payload;
    },
    setThreeDSId: (state: IReviewBookState, { payload }: PayloadAction<string>) => {
      state.threeDSId = payload;
    },
    setThreeDSLoading: (state: IReviewBookState, { payload }: PayloadAction<boolean>) => {
      state.threeDSLoading = payload;
    },
    setUpdatePriceReview: (state: IReviewBookState, { payload }: PayloadAction<boolean>) => {
      state.isUpdatePrice = payload;
    },
    setGetCouponReview: (state: IReviewBookState, { payload }: PayloadAction<boolean>) => {
      state.isGetCoupon = payload;
    },
    setLoadingBooking: (state: IReviewBookState, { payload }: PayloadAction<boolean>) => {
      state.loadingBooking = payload;
    },
    setErrorBooking: (state: IReviewBookState, { payload }: PayloadAction<string>) => {
      state.errorBooking = payload;
    },
    setBookingComplete: (state: IReviewBookState, { payload }: PayloadAction<boolean>) => {
      state.bookingComplete = payload;
    },
    setBooking: (state: IReviewBookState, { payload }: PayloadAction<IBookingComplete>) => {
      state.booking = payload;
    },
    setGuests: (state: IReviewBookState, { payload }: PayloadAction<IGuestsBooking[]>) => {
      state.guests = payload;
    },
    setCard: (state: IReviewBookState, { payload }: PayloadAction<ICardBooking>) => {
      state.card = payload;
    },
    setErrorsField: (state: IReviewBookState, { payload }: PayloadAction<IErrorField[]>) => {
      state.errorsField = payload;
    },
    setExternalError: (state: IReviewBookState, { payload }: PayloadAction<boolean>) => {
      state.isExternalError = payload;
    },
    setPriceChangedError: (state: IReviewBookState, { payload }: PayloadAction<boolean>) => {
      state.isPriceChangedError = payload;
    },
    setSoldOutError: (state: IReviewBookState, { payload }: PayloadAction<boolean>) => {
      state.isSoldOutError = payload;
    },
    setErrorsBookingMessage: (
      state: IReviewBookState,
      { payload }: PayloadAction<IErrorField[]>,
    ) => {
      state.errorsBookingMessage = payload;
    },
    setBookingInProgress: (state: IReviewBookState, { payload }: PayloadAction<boolean>) => {
      state.isBookingInProgress = payload;
    },
    setSelectedHotelReviewClientCash: (state: IReviewBookState, { payload }: PayloadAction<number>) => {
      state.selectedHotelReviewClientCash = payload;
    },
    setSelectedHotelReviewClientCashStr: (state: IReviewBookState, { payload }: PayloadAction<string>) => {
      state.selectedHotelReviewClientCashStr = payload;
    },

    resetState: (state: IReviewBookState) => {
      return { ...initialState, selectedHotelReviewClientCash: state.selectedHotelReviewClientCash, selectedHotelReviewClientCashStr: state.selectedHotelReviewClientCash ? state.selectedHotelReviewClientCash.toString() : '' };
    },
  },
});

export const {
  setLoadingBook,
  setErrorBook,
  setReviewBook,
  setExpiredSession,
  setUpdatePriceReview,
  setGetCouponReview,
  setLoadingBooking,
  setErrorBooking,
  setBookingComplete,
  setBooking,
  setGuests,
  setCard,
  setErrorsField,
  setExternalError,
  setPriceChangedError,
  setSoldOutError,
  setErrorsBookingMessage,
  setBookingInProgress,
  resetState,
  setSavingsMismatch,
  setThreeDSModalVisible,
  setThreeDSUrl,
  setThreeDSId,
  setThreeDSLoading,
  setSelectedHotelReviewClientCash,
  setSelectedHotelReviewClientCashStr
} = reviewBookSlice.actions;

export const reviewBookReducer = reviewBookSlice.reducer;

export const getReviewBook = (
  hotelId: number,
  packageId: string,
  sessionKey: ISessionKey,
  lifeType: string,
  quote?: string
): AppThunk => {
  return async (dispatch, getState) => {
    const { reviewBookStore } = getState();
    dispatch(setExpiredSession(false));

    if (!reviewBookStore.isGetCoupon) {
      dispatch(setLoadingBook(true));
    }

    dispatch(setUpdatePriceReview(true));
    dispatch(getUserCards());

    try {
      const { loginStore, marginatorStore, reviewBookStore, hotelsStore, navigationMenuStore } = getState();
      const { account, userWalletData } = loginStore;
      const { selectedHotelReviewClientCash, card } = reviewBookStore;
      const { selectedHotelSearchClientCash } = hotelsStore;
      const { items } = navigationMenuStore;
  
      const currency = getSelectedCurrency(account);

      const res = await axiosInstance.post(
        Urls.BookingSummary,
        { 
          hotelId,
          packageId,
          sessionKey,
          marginator: { percentage: marginatorStore.percentage, commission: marginatorStore.commission },
          currency,
          quote,
          lifeType: lifeType
        },
        { ...getHeaders() },
      );

      dispatch(setUpdatePriceReview(false));
      dispatch(setLoadingBook(false));

      if (
        res.data?.bookingCard?.package?.packageId !==
        JSON.parse(localStorage.getItem(HOTEL_PACKAGE_ID))
      ) {
        localStorage.removeItem(HOTEL_CONFIRMED_INFO);
        localStorage.removeItem(HOTEL_BOOKING_COMPLETE);
        localStorage.removeItem(HOTEL_PACKAGE_ID);
      }
      
      if (!!selectedHotelReviewClientCash && !!userWalletData) {
        const walletNoDecimals = account?.walletNoDecimals;
        const maxWalletClientCashAmount = items?.isMLM ? Math.floor(res.data?.bookingCard?.bookingPrice?.savings * 100) / 100 : res.data?.bookingCard?.bookingPrice?.maxWalletClientCash;
        const convertionRate = userWalletData?.convertionRate ? userWalletData?.convertionRate : 1;
        const maxWalletClientCashCalculated = maxWalletClientCashAmount / convertionRate;
        const maxWalletClientCash = walletNoDecimals ? Math.floor(maxWalletClientCashCalculated) : maxWalletClientCashCalculated;
        
        if (maxWalletClientCash < selectedHotelReviewClientCash) {
          dispatch(setSelectedHotelReviewClientCash(maxWalletClientCash));
          dispatch(setSelectedHotelReviewClientCashStr(maxWalletClientCash ? maxWalletClientCash?.toString() : ''));
          dispatch(hotelsActions.setSelectedHotelSearchClientCash({ ...selectedHotelSearchClientCash, selectedPropertyReviewClientCash: maxWalletClientCash }));
        }
      }

      dispatch(setReviewBook(res.data));
      dispatch(setGetCouponReview(true));

      if (res?.data?.bookingCard?.package?.rooms?.filter((r: any) => r?.availability === SOLD_OUT).length) {
        dispatch(setErrorBook(ROOMS_UNAVAILABLE_ERROR));
        dispatch(setExternalError(true));
      }
      
      const balance = res.data?.bookingCard?.balance;
      const checkIn = res.data?.bookingCard?.checkIn ? moment(res.data?.bookingCard?.checkIn, 'yyyy-MM-DD').format('yyyy-MM-DD') : null;
      const checkOut = res.data?.bookingCard?.checkOut ? moment(res.data?.bookingCard?.checkOut, 'yyyy-MM-DD').format('yyyy-MM-DD') : null;
      const travelers = res.data?.bookingCard?.totalGuests ? res.data?.bookingCard?.totalGuests : 1;
      const destinationCountry = res.data?.bookingCard?.countryCode ? res.data?.bookingCard?.countryCode : null;

      const addressFromStorage: ICardBooking = getHotelAddressFromStorage();
      const country = addressFromStorage ? addressFromStorage.country ? addressFromStorage.country : card?.country : card?.country;
      const state = addressFromStorage ? addressFromStorage.state ? addressFromStorage.state : card?.state : card?.state;

      dispatch(insuranceActions.setBaseRequest({
        sessionId: sessionKey?.value,
        totalPrice: {
          isoCurrencyCode: currency,
          value: balance
        },
        booking: {
          startDateTime: checkIn,
          endDateTime: checkOut,
          destinationIsoCountryCode: 'US'
        }  
      }));
      dispatch(insuranceActions.setTravelersRequest({ numberOfTravelers: 1 } as IInsuranceRequestTravelers));

      if (country === 'US' && !items?.isRemoveInsurance) {
        const isUSState = USA_STATES.map(s => s.postalCode).includes(state);

        dispatch(getInsuranceQuote(balance, currency, checkIn, checkOut, isUSState ? state : null, travelers, destinationCountry, sessionKey?.value));
      }

    } catch (error: any) {
      console.error(error);
      const errorData = error?.response?.data;
      const errorObject = errorData ? JSON.parse(errorData) : null;
      const erroMessage = get(errorObject, 'code', get(errorObject, 'reason', error.toString()));

      dispatch(setErrorBook(erroMessage));
      dispatch(setLoadingBook(false));
      dispatch(setUpdatePriceReview(false));

      if (
        error?.response?.status === SESSION_EXPIRED_STATUS &&
        error?.response?.data[zeroItem] === SESSION_SEARCH_EXPIRED_MESSAGE
      ) {
        dispatch(setExpiredSession(true));
      } else {
        dispatch(setExternalError(true));
      }
    }
  };
};

export const getBookPackageWithCard = (bookWithCard: IBookPackageWithCard): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(setLoadingBooking(true));
    dispatch(setExpiredSession(false));
    dispatch(setThreeDSModalVisible(false));
    dispatch(setThreeDSUrl(null));
    dispatch(setThreeDSId(null));
    dispatch(setThreeDSLoading(false));
    
    const { insuranceStore } = getState();
    const { selection, insurance } = insuranceStore;

    try {
      const postData: IBookPackageWithCard = {
        ...bookWithCard,
        guests: bookWithCard.guests.map((guest: IGuestsBooking) => {
          return {
            ...guest,
            surname: guest.surname.trim(),
            givenName: guest.givenName.trim(),
          };
        }),
      };

      if (selection === InsuranceSelection.YES && insurance?.quoteId) {
        postData.insuranceQuoteId = insurance?.quoteId;
      }
  
      const res = await axiosInstance.post(Urls.BookPackageWithCard, postData, {
        ...getHeaders(),
      });

      const bookingResponse = res?.data;
      const hotelSegments = get(bookingResponse, 'hotelSegments[0]')
      if (hotelSegments?.chargeStatus === 'Pending3DS') {
        dispatch(setLoadingBooking(false));
        dispatch(setThreeDSModalVisible(true));
        dispatch(setThreeDSId(hotelSegments?.bookingGuid));
        dispatch(setThreeDSUrl(hotelSegments?.urlRedirectTo3DS));
        return;
      }

      dispatch(handleProcessBookingComplete(bookingResponse));
    } catch (error) {
      dispatch(handleErrorBookingComplete(error));
    } finally {
      scrollTop();
    }
  };
};

export const getBookPackageWithCard3DS = (sessionKey: ISessionKey): AppThunk => {
  return async (dispatch, getState) => {
    const { reviewBookStore } = getState();
    const { threeDSId } = reviewBookStore;

    dispatch(setThreeDSModalVisible(false));
    dispatch(setThreeDSLoading(true));

    try {
      const res = await axiosInstance.post(Urls.BookPackageWithCardResume, { bookingId: threeDSId, sessionKey }, { ...getHeaders() });
      dispatch(handleProcessBookingComplete(res?.data));

      dispatch(setThreeDSUrl(null));
      dispatch(setThreeDSId(null));
      dispatch(setThreeDSLoading(false));

    } catch (error) {
      dispatch(handleErrorBookingComplete(error));
    } finally {
      dispatch(setThreeDSModalVisible(false));
      dispatch(setThreeDSUrl(null));
      dispatch(setThreeDSId(null));
      dispatch(setThreeDSLoading(false));

      scrollTop();
    }
  };
};

const handleProcessBookingComplete = (data: any): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(setLoadingBooking(true));

    const { navigationMenuStore, loginStore } = getState();
    const errorCode = get(data, `[${zeroItem}].errorCode`);
    const status = get(data, `hotelSegments[${zeroItem}].status`);
    if (errorCode === SOLD_OUT) {
      dispatch(setErrorBook(ROOMS_UNAVAILABLE_ERROR));
      dispatch(setExternalError(true));
    }

    if (status === STATUS_OK) {
      dispatch(setBookingComplete(true));
      localStorage.removeItem(HOTEL_BOOKING_GUEST_INFO_LABEL);
      localStorage.removeItem(HOTEL_BOOKING_ADDRESS_INFO_LABEL);

      const { account, userWallet } = loginStore;

      if (navigationMenuStore?.items?.promo) {
        UrlUtils.removeFromUrl(SESSION_KEY_LABEL);
        UrlUtils.removeFromUrl(ROOMS_SEARCH_LABEL);
        UrlUtils.removeFromUrl(SESSION_KEY_LABEL_ROOMS);
      }

      dispatch(setSelectedHotelReviewClientCash(null));
      dispatch(setSelectedHotelReviewClientCashStr(''));
      dispatch(hotelsActions.setSelectedHotelSearchClientCash(null));
      dispatch(getUserWallet(userWallet));
      dispatch(getUserCards());
      dispatch(setCard({ ...cardInitialState }));
      
      ReactGA.event({
        category: account.name,
        action: `${C_BOOKING_CONFIRMED}_${account.name.toUpperCase()}`,
        label: `Booking confirmed on book`,
        nonInteraction: false,
      });
  
    } else {
      dispatch(setBookingInProgress(true));
    }

    dispatch(setLoadingBooking(false));
    if (isEmpty(data?.errors)) {
      localStorage.setItem(HOTEL_CONFIRMED_INFO, JSON.stringify(data));
      localStorage.setItem(HOTEL_BOOKING_COMPLETE, JSON.stringify(true));
    }

    dispatch(setBooking(data));

    if (data?.insurance?.policyDetail?.policyStatus === 'Error') {
      dispatch(insuranceActions.setInsurance(null));
    }
  }
}

const handleErrorBookingComplete = (error: any): AppThunk => {
  return async (dispatch) => {

    const errorData = error?.response?.data;

    if (errorData) {
      // TODO to check
      const errorType = get(errorData, `[${zeroItem}].errorType`);
      const errorCode = get(errorData, `[${zeroItem}].errorCode`);

      if (errorType === VALIDATION_ERROR) {
        dispatch(setErrorsField(error.response.data));
      } else if (errorType === EXTERNAL_ERROR) {
        dispatch(setExternalError(true));
      } else if (errorCode === PRICE_CHANGED) {
        dispatch(setPriceChangedError(true));
      } else if (errorCode === SOLD_OUT) {
        dispatch(setSoldOutError(true));
      } else if (errorType === ERROR) {
        dispatch(setErrorsBookingMessage(error.response.data));
      } else if (errorCode === SAVING_MISMATCH) {
        dispatch(setExpiredSession(true));
        dispatch(setSavingsMismatch(true));
      } else {
        const errorObject = isString(errorData) ? JSON.parse(errorData) : errorData;
        const errorMessage = get(errorObject, 'code', null);
        const errorReason = get(errorObject, 'reason', error.toString());

        const errorReasonInsufficientZbucks = errorReason === 'Insufficient Zbucks';
        const errorReasonSessionKeyNotValid = errorReason === 'SessionKey not valid';
        
        const externalErrors = [ROOMS_UNAVAILABLE_ERROR];
        const paymentErrors = [CHECK_OUT_PAYMENT_ERROR];
        const errors = [INVALID_PAYLOAD, CHECKOUT_ERROR]; 
        if (externalErrors.includes(errorMessage)) {
          dispatch(setErrorBook(errorMessage));
          dispatch(setExternalError(true));
        } else if (errorReasonSessionKeyNotValid) {
          dispatch(setErrorBook('INSUFFICIENT_ZBUCKS_ERROR'));
          dispatch(setExternalError(true));
        } else if (paymentErrors.includes(errorMessage)) {
          dispatch(setErrorBooking(errorReason));
        } else if (errors.includes(errorMessage)) {
          dispatch(setErrorBooking(errorReason));
        } else {
          dispatch(setErrorBooking(errorMessage ? `error.code.${errorReasonInsufficientZbucks ? 'INSUFFICIENT_ZBUCKS_ERROR' : errorMessage}` : errorReason));
        }
      }

    } else {
      dispatch(setErrorBooking(error.toString()));
    }
    dispatch(setLoadingBooking(false));  
  }
}