import React from 'react';

import ReactGA from 'react-ga4';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import { Popover, Radio, Tooltip } from 'antd';

import { RadioChangeEvent } from 'antd/lib/radio/interface';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { ModalCancellationPolicies, Currency, ExpiredSessionModal, WalletMessage } from '@components';
import { BlueButton } from '@share/components';
import { getBookRoutePath, renderNumber, insertSpacesAndPluralize, getPreferenceFromAccount, RootState, getToken, isVacationRentalsFromPath } from '@share/utils';
import { IPackage, IRoomsContent, RefundableEnum } from '@common-types';
import { IHotelsState, ILoginState, IMarginatorState, IMenuState, LoginType, hotelsActions } from '@share/store/slices';
import {
  ManDarkSvg,
  BedSmallSvg,
  InfoSvg,
  ArrowBottomSvg,
  ArrowTopSvg,
  ImagesQuantitySvg,
} from '@assets';
import { IAccount, IClientCash, IHotel, LOGOUT_FIELD_KEY } from '@share/common-types';
import { WalletPriceType, getWalletPrices, isAllInclusivetMealPlan, isBreakfastMealPlan, isFreeBreakfastMealPlan } from '@utils';
import { D_RESERVE_BUTTON, USER_BASE_URL } from '@share/constants';
import { IRoomsSearchState, setSelectedHotelReviewClientCash, setSelectedHotelReviewClientCashStr } from '@store/slices';

import { HotelImagesSlider } from '../hotel-image-slider';

import NotImageImg from '@assets/images/not_image_room.png';
import Package from '@assets/images/package.png';
import FreeBreakfast from '@assets/images/free-breakfast.png';
import AllInclusive from '@assets/images/all-inclusive.png';

import './style.scss';

interface IMapStateToProps {
  loginStore: ILoginState;
  hotelsStore: IHotelsState;
  roomsSearchStore: IRoomsSearchState;
  menuStore: IMenuState;
}

interface IMapDispatchToProps {
  setSelectedHotelReviewClientCash: (clientCash: number) => void;
  setSelectedHotelReviewClientCashStr: (selectedClientCash: string) => void;
  setSelectedHotelSearchClientCash: (clientCash: IClientCash) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps {
  hotel: IHotel;
  account: IAccount;
  groupedPackages: IPackage[];
  getRoomContent: (room: string) => IRoomsContent;
  nights: number;
  hotelId: number;
  isPreferred?: boolean;
  preferredRoomSavingPct?: number;
  marginator: IMarginatorState
}

interface IState {
  isAllAmenitiesShown: boolean;
  isSessionExpired: boolean;
  selectedPackageId: string;
  additionalHeight: number;
  showImageSlider: boolean;
  isModalCancellationPoliciesVisible: boolean;
  imageError: boolean;
  cancellationPoliciesText: string;
  refundabilityText: string;
  isChangedCancellationPolicy: boolean;
  nameHeight: number;
  content: IRoomsContent;
}

const zero = 0;
const one = 1;
const five = 5;
const basisHeight = 18;
const basisPadding = 60;
const basisMargin = 14;
const timeoutShow = 3000;

class RoomComponent extends React.Component<IProps, IState> {
  private timeout: any;
  state: IState;
  wrapperRef: React.RefObject<HTMLDivElement> = React.createRef();
  roomRef: React.RefObject<HTMLDivElement> = React.createRef();

  constructor(props: IProps) {
    super(props);
    const currentPackage = props.groupedPackages[zero];

    const selectedRoom = currentPackage?.rooms[zero];
    const content = props.getRoomContent(selectedRoom.targetRoomKey);

    this.state = {
      isAllAmenitiesShown: false,
      isSessionExpired: false,
      selectedPackageId: currentPackage.packageId,
      additionalHeight: 0,
      showImageSlider: false,
      imageError: false,
      isModalCancellationPoliciesVisible: false,
      cancellationPoliciesText: currentPackage.cancellationPolicyText,
      refundabilityText: currentPackage.refundabilityText ? currentPackage.refundabilityText : '',
      isChangedCancellationPolicy: false,
      nameHeight: 0,
      content 
    };
  }

  openImageSlider = () => {
    this.setState({ showImageSlider: true });
  };

  closeImageSlider = () => {
    this.setState({ showImageSlider: false });
  };

  toggleShowAll = (): void => {
    this.setState((prevState: IState) => ({ isAllAmenitiesShown: !prevState.isAllAmenitiesShown }));
  };

  handleClickOutside = (event: MouseEvent): void => {
    if (this.wrapperRef && !this.wrapperRef?.current?.contains(event.target as Node)) {
      this.setState({ isAllAmenitiesShown: false });
    }
  };

  componentDidMount(): void {
    document.addEventListener('mousedown', this.handleClickOutside);

    if (this.roomRef && this.roomRef.current) {
      const rooms = document.querySelectorAll('.rooms-search__room');

      if (rooms && rooms.length) {
        const { offsetHeight } = this.roomRef.current;
        let maxHeight = offsetHeight;

        rooms.forEach((item: HTMLDivElement) => {
          if (item.offsetHeight > maxHeight) {
            maxHeight = item.offsetHeight;
          }
        });

        if (maxHeight > offsetHeight) {
          this.setState({ additionalHeight: maxHeight - offsetHeight });
        }
      }

      const names = document.querySelectorAll('.rooms-search__name-wrapper');

      if (names && names.length) {
        let maxHeight = 0;

        names.forEach((item: HTMLDivElement) => {
          if (item.scrollHeight > maxHeight) {
            maxHeight = item.scrollHeight;
          }
        });

        this.setState({ nameHeight: maxHeight });
      }
    }
  }

  componentWillUnmount(): void {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  onRoomSelect = (e: RadioChangeEvent, cancellationPolicyChanged: boolean): void => {
    this.setState({ selectedPackageId: e.target.value, isChangedCancellationPolicy: cancellationPolicyChanged });
    
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    // @ts-ignore
    if (cancellationPolicyChanged) {
      this.timeout = setTimeout(() => {
        this.setState({ isChangedCancellationPolicy: false });
      }, timeoutShow);
    }
  };

  getReserveRoomUrl = (selectedPackageId: string): string => {
    const { hotelId, history } = this.props;
    return getBookRoutePath(hotelId, selectedPackageId, history);
  };

  handleReserve = (selectedPackageId: string, walletPrices: any) => {
    const { history, account } = this.props;

    const token = getToken(account);
    if (account?.type === LoginType.PrivateWithToken && !token) {
      this.setState({ isSessionExpired: true }); 
    } else {
      const { hotelsStore } = this.props;
      const { selectedHotelSearchClientCash } = hotelsStore;
  
      const displayWalletSavings = account?.walletWalletSavings && (walletPrices?.priceSavings > 0 || walletPrices?.maxClientCashAmountAllow > 0) && walletPrices?.maxClientCashAmountAllow;

      const selectedPropertyClientCash = displayWalletSavings ?  walletPrices?.maxClientCashPermited : selectedHotelSearchClientCash?.selectedPropertyClientCash;

      this.props.setSelectedHotelReviewClientCash(selectedPropertyClientCash);
      this.props.setSelectedHotelReviewClientCashStr(selectedPropertyClientCash ? selectedPropertyClientCash.toString() : '');
      this.props.setSelectedHotelSearchClientCash({ ...selectedHotelSearchClientCash, selectedPropertyReviewClientCash: selectedPropertyClientCash });

      history.push(this.getReserveRoomUrl(selectedPackageId));

      ReactGA.event({
        category: account.name,
        action: `${D_RESERVE_BUTTON}_${account.name.toUpperCase()}`,
        label: `User clicked reserve button on detail`,
        nonInteraction: false,
      });
    }
  }

  onCancelSlider = (e: React.MouseEvent<HTMLElement, MouseEvent>, isReserveRoom?: boolean) => {
    this.closeImageSlider();

    if (isReserveRoom) {
      const { history } = this.props;
      const { selectedPackageId } = this.state;

      history.push(this.getReserveRoomUrl(selectedPackageId));
    }
  };

  showCancellationPolicies = (): void => {
    this.setState({
      isModalCancellationPoliciesVisible: true,
      cancellationPoliciesText: this.getPackageInfo().cancellationPolicyText,
      refundabilityText: this.getPackageInfo().refundabilityText,
    });
  };

  handleCancelModalCancellationPolicies = (): void => {
    this.setState({ isModalCancellationPoliciesVisible: false });
  };

  handleRedirect = () => {
    const { loginStore } = this.props;
    const { account } = loginStore;

    const logoutUrlStorage = localStorage.getItem(USER_BASE_URL);
    const logoutPref = getPreferenceFromAccount(account, LOGOUT_FIELD_KEY);
    const logoutUrl = !isEmpty(logoutUrlStorage) ? logoutUrlStorage : logoutPref;
    if (!isEmpty(logoutUrl)) {
      window.location.replace(logoutUrl);
    }
  }

  getPackageInfo = () => {
    return this.props.groupedPackages.find((item) => {
      return item.packageId === this.state.selectedPackageId;
    });
  };

  renderPreferredMessage = () => {
    if (this.props.isPreferred) {
      return (
        <div className="rooms-search__room-image-preferred">
          <FormattedMessage id="popular.room" />
        </div>
      )
    }

    return null;
  }

  render(): React.ReactNode {
    const {
      isAllAmenitiesShown,
      isSessionExpired,
      selectedPackageId,
      additionalHeight,
      showImageSlider,
      isModalCancellationPoliciesVisible,
      cancellationPoliciesText,
      refundabilityText,
      isChangedCancellationPolicy,
      content
    } = this.state;
    const { loginStore, hotelsStore, groupedPackages, nights, isPreferred, marginator, hotel, preferredRoomSavingPct, roomsSearchStore, history } = this.props;
    const currentPackage = groupedPackages.find(g => g.packageId === selectedPackageId);

    const { packageId, rooms, refundability, price, pricePerNight, maxWalletClientCash } = currentPackage;
    const { account, userWalletData } = loginStore;
    
    const { showCommission, showSupplier, supplier } = marginator;

    const isVacationRentals = isVacationRentalsFromPath(history);

    const cunrrencyPackage = currentPackage?.currency;
    const cunrrencyDefault = account?.currency;
    const disableExclusivePricingTagVacationRentals= account?.disableExclusivePricingTagVacationRentals;
    const disableSavingsTagVacationRentals= account?.disableSavingsTagVacationRentals;
    
    const currency = cunrrencyPackage ? cunrrencyPackage : cunrrencyDefault;
    const priceInt = price;
    const maxWalletClientCashInt = maxWalletClientCash;

    const marginatorPrice = priceInt / (1 - (marginator.value / 100));
    const marginatorpricePerNight = pricePerNight / (1 - (marginator.value / 100));
    const marginatorCommission = marginatorPrice - priceInt;

    const selectedPackage = groupedPackages.find(({ packageId }) => packageId === selectedPackageId);
    const firstRoom = rooms[zero];
    const allRooms = groupedPackages.map(({ rooms, price, priceDifference, refundability, packageId }) => {
      return {
        packageId,
        priceDifference,
        price,
        refundability,
        room: rooms[zero],
      };
    });

    const allRoomsGrouped = groupBy(allRooms, (d: any) => d?.refundability);
    const allRoomsGroupedKeys = Object.keys(allRoomsGrouped);
    const allRoomsSelectedPackage = get(allRoomsGrouped, allRooms.find(a => a.packageId === selectedPackageId)?.refundability);

    const isPackage =  selectedPackage.isPackage;
    const isFreeBreakfast = isFreeBreakfastMealPlan(hotel?.mealPLan);
    const isAllInclusive = isAllInclusivetMealPlan(hotel?.mealPLan);

    const currentBasisHeight = basisPadding + basisHeight * allRooms.length + basisMargin * (allRooms.length - one);
    const basisMinHeight = currentBasisHeight + additionalHeight;

    if (!firstRoom) {
      return null;
    }

    const { bedType, roomName, adultsCount, kidsAges } = firstRoom;
    const adults = new Array(adultsCount).fill(one);

    const numberOfRooms = groupedPackages[zero].rooms.length;

    const { error } = roomsSearchStore;
    const { selectedHotelSearchClientCash } = hotelsStore;

    const hotelPrices = getWalletPrices(account, userWalletData, selectedHotelSearchClientCash, marginatorPrice, maxWalletClientCashInt, WalletPriceType.Details);

    const displayWalletSavings = account?.walletWalletSavings && (hotelPrices?.priceSavings > 0 || hotelPrices?.maxClientCashAmountAllow > 0) && !!hotelPrices?.maxClientCashAmountAllow;

    const marginatorPriceFinal = displayWalletSavings ? hotelPrices?.priceFinalMax : hotelPrices?.priceFinal;

    return (
      <div className={`rooms-search__room ${isPreferred ? 'preferred' : ''}`} key={`room_search_container_${packageId}`} ref={this.roomRef}>
        <div className={`rooms-search__label-info ${isPreferred ? 'preferred' : ''}`}>
          <p className="rooms-search__label-text">
            <FormattedMessage id="avg.rate" />
          </p>
          <p className="rooms-search__label-price"><Currency currency={currency} />{renderNumber(marginatorpricePerNight, 2)}</p>
          <p className="rooms-search__label-text">
            <FormattedMessage id="per.night" />
          </p>
        </div>

        {(preferredRoomSavingPct && preferredRoomSavingPct > 0 && (!isVacationRentals || !disableSavingsTagVacationRentals)) ? (
          <div className="hotel-card-wrapper__label-container">
            <div className="hotel-card-wrapper__label">
              <p className="hotel-card-wrapper__label-title">
                <FormattedMessage id={account?.forceMemberWording ? 'member.save' : 'save'} /> {preferredRoomSavingPct.toFixed(0)}%
              </p>
            </div>
            <div className="hotel-card-wrapper__label-corner"></div>
          </div>) : null}

        {(!this.state.imageError && content.images.length)? (
          <div className={`rooms-search__room-image ${isPreferred ? 'preferred' : ''}`}>
            {this.renderPreferredMessage()}
            <img
              className="rooms-search__room-img"
              src={content.images[zero]}
              alt="room image"
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = NotImageImg;
                this.setState({ imageError: true });
              }}
              onClick={this.openImageSlider}
            />
            <div className="rooms-search__room-image-count" onClick={this.openImageSlider}>
              <ImagesQuantitySvg />
              <span className="rooms-search__images-quantity-text">{content.images.length}+</span>
            </div>
          </div>
        ) : (
          <div className="rooms-search__room-image no-image preferred">
            {this.renderPreferredMessage()}
            <img src={NotImageImg} className="rooms-search__room-img"/>
            <div className='rooms-search__banner-not-image preferred'>
              <span className='rooms-search__text-not-img'>
                <FormattedMessage id="room.not.image" />
              </span>
            </div>
          </div>)}
          
        <div
          className="rooms-search__name-wrapper"
          style={{ height: '75px' }}
        >
          <Tooltip title={roomName}><div className="rooms-search__name">{roomName}</div></Tooltip>
          <div className="rooms-search__guests">
            {adults.map((value, i) => {
              return (
                <span className="rooms-search__icon-wrapper" key={`room_search_icon_adults_${i}`}>
                  <ManDarkSvg />
                </span>
              );
            })}
            {kidsAges?.length ? kidsAges?.map((value, i) => {
              return (
                <span className="rooms-search__icon-wrapper kids" key={`room_search_icon_kids_${i}`}>
                  <ManDarkSvg />
                </span>
              );
            }) : null}
          </div>
        </div>

        <div className="rooms-search__bed">
          <BedSmallSvg /> {bedType ? insertSpacesAndPluralize(bedType) : <FormattedMessage id="no.data.available" />}
        </div>

        <div className="rooms-search__amenities">
          {content.amenities.length > 0 ? (
            <div ref={this.wrapperRef}>
              <div className="rooms-search__amenities-text">
                {content.amenities.slice(zero, five).join(', ')}
              </div>
              {content.amenities.length > 5 && <div className="rooms-search__amenities-more" onClick={this.toggleShowAll}>
                <FormattedMessage id="more.details" />{' '}
                {isAllAmenitiesShown ? <ArrowTopSvg /> : <ArrowBottomSvg />}
              </div>}
              {isAllAmenitiesShown && (
                <div className="rooms-search__amenities-all">
                  {content.amenities.map((amenity: any) => {
                    return <div key={`room_search_amenities_${amenity}`}>{amenity}</div>;
                  })}
                </div>
              )}
            </div>
          ) : (
            <span className="rooms-search__amenities-no-data" style={{ marginBottom: '10px' }}>
              <FormattedMessage id="no.info.amenities" />
            </span>
          )}
        </div>

        {allRooms && allRooms.length ? (
          <div className="rooms-search__basis-wrapper" style={{ minHeight: `${basisMinHeight}px` }}>
            {allRooms?.length === 1 ? 
              allRooms.map(({ room }, index) => {
                if (isEmpty(room.roomBasis)) {
                  return <FormattedMessage key={`room_search_basis_${index}`} id="hotel.details.room.only" />
                }

                if (isFreeBreakfastMealPlan(room.roomBasis) || isFreeBreakfast) {
                  return (
                    <React.Fragment key={`room_search_basis_fragment_${index}`}>
                      <div className="free-breakfast-container">
                        <div className="free-breakfast-content">
                          <img src={FreeBreakfast} style={{width: 14, marginRight: 5}} />
                          <FormattedMessage id="result.free-breakfast" /> 
                        </div>
                      </div>
                      {isBreakfastMealPlan(room.roomBasis) ? (
                        <div className="breakfast-container">
                          <div className="breakfast-content">
                            <FormattedMessage id={room.roomBasis} /> 
                          </div>
                        </div>) : null}
                    </React.Fragment>
                  );
                } else if (isAllInclusivetMealPlan(room.roomBasis) || isAllInclusive) {
                  return (
                    <div key={`room_search_basis_breakfast_${index}`} className="all-inclusive-container">
                      <div className="all-inclusive-content">
                        <img src={AllInclusive} style={{width: 14, marginRight: 5}} />
                        <FormattedMessage id="result.all-inclusive" /> 
                      </div>
                    </div>
                  );
                } else {
                  return <div key={`room_search_basis_breakfast_basis_${index}`} className="rooms-search__basis">{room.roomBasis}</div>;
                }
              }) : (
              <>
                {allRoomsGroupedKeys.length > 1 ?
                  <>
                    <label style={{ marginBottom: '6px' }}><FormattedMessage id="cancellation.policy" /></label>
                    <Radio.Group value={selectedPackageId} onChange={e => this.onRoomSelect(e, true)} disabled={!isEmpty(error)}>
                      {allRoomsGroupedKeys.map((key, index) => {
                        const values = get(allRoomsGrouped, key);
                        const packageSelected: any = values.find(v => v.packageId === selectedPackageId);
                        const packageOption: any = get(values, '[0]');

                        const value = packageSelected?.packageId ? packageSelected?.packageId : packageOption?.packageId;
                        const price = packageSelected?.packageId ? packageSelected?.price : packageOption?.price;

                        const diff = value === selectedPackageId ? 0 : (price - marginatorPriceFinal);
                        const sign = diff >= 0;

                        return (
                          <Radio key={`refundability_${index}`} value={value}>
                            <span><FormattedMessage id={key} /></span><span style={{ justifyContent: 'flex-end' }}><Currency currency={currency} /> {sign ? '+' : '-'}{renderNumber(Math.abs(diff), 2)}</span>
                          </Radio>);
                      })}
                    </Radio.Group>
                  </> : null}
                
                {allRoomsSelectedPackage.length > 1 ?
                  <>
                    <label style={{ marginBottom: '6px', marginTop: (allRoomsGroupedKeys.length > 1 ? '6px' : '0px') }}><FormattedMessage id="cars.extras" /></label>
                    <Radio.Group value={selectedPackageId} onChange={e => this.onRoomSelect(e, false)} disabled={!isEmpty(error)}>
                      {allRoomsSelectedPackage.map((r: any, index: number) => {
                        const value = r?.packageId;
                        const diff = value === selectedPackageId ? 0 : (r?.price - marginatorPriceFinal);
                        const sign = diff >= 0;

                        return (
                          <Radio key={`refundability_${index}`} value={value}>
                            <span>{r?.room?.mealPlan}</span><span style={{ justifyContent: 'flex-end' }}><Currency currency={currency} /> {sign ? '+' : '-'}{renderNumber(Math.abs(diff), 2)}</span>
                          </Radio>);
                      })}
                    </Radio.Group>
                  </> : null}

              </>)}
          </div>) : (
          <div style={{ minHeight: `${basisMinHeight}px` }} />
        )}

        <ul>
          {selectedPackage.rooms[0].specialDeals.map((value, i) => {
            return (
              <li key={`room_search_selected_package_${i}`}>{value}</li>
            )})}
        </ul>

        {!account?.isMLM ? (
          <WalletMessage
            price={marginatorPrice}
            maxClientCash={maxWalletClientCashInt}
            currency={currency}
            type={WalletPriceType.Details}
            isOneLine
            hideApplied={displayWalletSavings}
            greenText={displayWalletSavings}
            style={{ paddingLeft: '15px', paddingRight: '15px', marginBottom: '15px' }}
          />) : null}

        <div>
          {refundability !== RefundableEnum.Unknown ? (
            <div className="rooms-search__refundable">
              {refundability === RefundableEnum.Refundable && (
                <div className="rooms-search__refundable-ref" onClick={this.showCancellationPolicies}>
                  <Popover
                    overlayClassName="overlay-popover"
                    content={<FormattedMessage id="changed.cancellation.policy" />}
                    visible={isChangedCancellationPolicy}
                    placement="bottomLeft"
                  >
                    <FormattedMessage id="refundable" />
                    <InfoSvg />
                  </Popover>
                  
                </div>
              )}

              {refundability === RefundableEnum.Nonrefundable && (
                <span
                  className="rooms-search__refundable-nonref"
                  onClick={this.showCancellationPolicies}
                >
                  <Popover
                    overlayClassName="overlay-popover"
                    content={<FormattedMessage id="changed.cancellation.policy" />}
                    visible={isChangedCancellationPolicy}
                    placement="bottomLeft"
                  >
                    <FormattedMessage id="non.refundable" />
                    <InfoSvg />
                  </Popover>
                  
                </span>
              )}

              {(isPackage && (!isVacationRentals || !disableExclusivePricingTagVacationRentals)) ? (
                <div className="package-container">
                  <div className="package-content">
                    <img src={Package} style={{width: 14, marginRight: 5}} />
                    <FormattedMessage id="result.package" /> 
                  </div>
                </div>
              ) : null}

            </div>
          ) : null}
        </div>

        <div className="rooms-search__bottom">
          <div className="rooms-search__price">
            <div className="hotel-card-wrapper__price-total">
              <p className="hotel-card-wrapper__price-total-text">
                <span style={{fontSize: '13px', marginRight: '4px'}}><Currency currency={currency} /></span>{renderNumber(marginatorPriceFinal, 2)}
              </p>
              <div className="hotel-card-wrapper__for-info">
                <p className="hotel-card-wrapper__for-info-text">
                  <FormattedMessage id="for" />{' '}
                  <FormattedMessage id="nights" values={{ count: nights }} />{' '}
                  <span className="rooms-search__price-includes-taxes">
                    <FormattedMessage id={account?.breakdownTaxes ? 'abbreviated.price.without.taxes.fees' : 'abbreviated.price.includes.taxes.fees'} />
                  </span>
                </p>
              </div>
              {showCommission && (
                <div className="hotel-card-wrapper__price-public">
                  <p className="hotel-card-wrapper__commission-text">
                    <FormattedMessage id="commission.price" />
                  </p>
                  <p className="hotel-card-wrapper__commission-text">
                    <Currency currency={currency} />{renderNumber(marginatorCommission, 2)}
                  </p>
                </div>
              )}
              {showSupplier && (
                <div className="hotel-card-wrapper__price-public">
                  <p className="hotel-card-wrapper__supplier-text"><FormattedMessage id="supplier" />:</p>
                  <p className="hotel-card-wrapper__supplier-text">{supplier}</p>
                </div>
              )}
            </div>
          </div>

          <div className={`rooms-search__action ${numberOfRooms !== one ? 'more-rooms' : ''}`}>
            <BlueButton disabled={!isEmpty(error)} onClick={() => this.handleReserve(selectedPackageId, hotelPrices)}>
              {numberOfRooms === one ? (
                <FormattedMessage id="reserve" />
              ) : (
                <FormattedMessage id="reserve.count.rooms" values={{ count: numberOfRooms }} />
              )}
            </BlueButton>
          </div>
        </div>
        <HotelImagesSlider
          onCancel={this.onCancelSlider}
          visible={showImageSlider}
          images={content.images.map((img: string) => {
            return {
              description: 'room-image',
              url: img,
              height: zero,
              width: zero,
            };
          })}
          hotelName={roomName}
          startIndex={zero}
        />
        <ModalCancellationPolicies
          cancellationPoliciesText={cancellationPoliciesText}
          refundabilityText={refundabilityText}
          visible={isModalCancellationPoliciesVisible}
          onCancel={this.handleCancelModalCancellationPolicies}
        />

        {isSessionExpired && (
          <div className="result-wrapper__expired-message">
            <ExpiredSessionModal visible={isSessionExpired} hasRedirect={true} onHandleRedirect={this.handleRedirect} />
          </div>)}

      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    hotelsStore: state.hotelsStore,
    menuStore: state.navigationMenuStore,
    loginStore: state.loginStore,
    roomsSearchStore: state.roomsSearchStore
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  setSelectedHotelReviewClientCash,
  setSelectedHotelReviewClientCashStr,
  setSelectedHotelSearchClientCash: hotelsActions.setSelectedHotelSearchClientCash,
};

const RoomComponentRouter = withRouter(RoomComponent);

export const Room = connect(mapStateToProps, mapDispatchToProps)(RoomComponentRouter);







