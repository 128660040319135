import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import isUndefined from 'lodash/isUndefined';
import { Routes } from '@share/constants';
import {
  getCurrentUser,
  getGetawayInfo,
  ICurrentUserState,
  IGetawayBookingState,
} from '@store/slices';
import { getSelectedCurrency, RootState } from '@share/utils';
import { GetawayBooking } from '../getaway-booking';
import { GetawayInfo } from '../getaway-info';
import { SkeletonReviewBook } from '../../../common';
import { ILoginState } from '@share/store/slices';
import { IAccount } from '@share/common-types';

import './style.scss';

interface IMapStateToProps {
  getawayBookingStore: IGetawayBookingState;
  currentUserStore: ICurrentUserState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  getGetawayInfo: (promoId: string, getaway: string) => void;
  getCurrentUser: () => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps {}

interface IState {
  promoId: string;
  activeGetaway: string;
  activeGetawaySubcategory: string;
}

const THREE = 3;
const FOUR = 4;
const FIVE = 5;

class GetawayBookingWrapperComponent extends React.Component<IProps, IState> {
  state: IState = {
    promoId: location.pathname.split('/')[THREE],
    activeGetaway: location.pathname.split('/')[FOUR],
    activeGetawaySubcategory: location.pathname.split('/')[FIVE],
  };

  redirectToDetails = () => {
    const { promoId, activeGetaway, activeGetawaySubcategory } = this.state;
    const { loginStore } = this.props;
    const { account } = loginStore;

    return `/${account?.name}${Routes.GetawayDetails}/${promoId}/${activeGetaway}/${
      !isUndefined(activeGetawaySubcategory) ? activeGetawaySubcategory : ''
    }`;
  };

  componentDidMount() {
    const { promoId, activeGetaway } = this.state;

    this.props.getGetawayInfo(promoId, activeGetaway);
    this.props.getCurrentUser();
  }

  render() {
    const { getawayBookingStore, currentUserStore, loginStore } = this.props;
    const { getawayInfo, loading, successSubmitGetaway } = getawayBookingStore;
    const { activeGetaway } = this.state;
    const { account } = loginStore;

    const currency = getSelectedCurrency(account as IAccount);

    return (
      <div className="getaway-booking-wrapper">
        {getawayInfo && !loading && currentUserStore.currentUser && !currentUserStore.loading ? (
          <div className="getaway-booking-wrapper__main-wrapper">
            <div className="getaway-booking-wrapper__header-wrapper">
              <div className="getaway-booking-wrapper__back-link">
                <Link to={this.redirectToDetails()}>
                  <FormattedMessage id="back.to.getaway.details" />
                </Link>
              </div>
              {!successSubmitGetaway ? (
                <div className="getaway-booking-wrapper__header">
                  <p className="getaway-booking-wrapper__header-title">
                    <FormattedMessage id="trip.request" />
                  </p>
                  <p className="getaway-booking-wrapper__header-description">
                    <FormattedMessage id="please.complete.the.following.information" />
                  </p>
                </div>
              ) : null}
            </div>
            <div className="getaway-booking-wrapper__wrapper">
              <GetawayBooking />
              <GetawayInfo getawayInfo={getawayInfo} currency={currency} activeGetaway={activeGetaway} />
            </div>
          </div>
        ) : (
          <SkeletonReviewBook />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    getawayBookingStore: state.getawayBookingStore,
    currentUserStore: state.currentUserStore,
    loginStore: state.loginStore
  };
};

const mapDispatchToProps: IMapDispatchToProps = { getGetawayInfo, getCurrentUser };

export const GetawayBookingWrapper = connect(
  mapStateToProps,
  mapDispatchToProps,
)(GetawayBookingWrapperComponent);
