import React, { useMemo, useState } from 'react';

import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import {
  IWeekState,
  IWeeksDatesState,
  IWeeksLocationsState,
  weeksActions,
} from '@share/store/slices';
import { ILoginState } from '@share/store/slices';
import { IWeek } from '@share/common-types';
import { RootState } from '@share/utils';
import { HasPermission, ViewQuotesSharePermission } from '@utils';

import { Button } from 'antd';
import { ModalCompare } from '../../weeks-modal-compare';

import './style.scss';

interface IMapStateToProps {
  weeksStore: IWeekState;
  weeksLocationsStore: IWeeksLocationsState;
  weeksDatesStore: IWeeksDatesState;

  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  setSelectedCompareWeeks: (selectedCompareWeeks: IWeek[]) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps {
  handleCompare: (week: IWeek) => void;
}

const maxCompareCondos = 5;

function WeeksResultCompareComponent(props: IProps) {
  const [showCompareModal, setShowCompareModal] = useState(false);

  const { weeksLocationsStore, loginStore, weeksStore, weeksDatesStore, setSelectedCompareWeeks } = props;

  const { user } = loginStore;
  const { selectedCompareWeeks, sessionKey } = weeksStore;
  const { location } = weeksLocationsStore;
  const { date, period } = weeksDatesStore;

  const HasViewQuotesSharePermission = useMemo(() => HasPermission(user, ViewQuotesSharePermission), [user]);
  const displayCompareLength = HasViewQuotesSharePermission ? 0 : 1;

  if (!selectedCompareWeeks?.length) {
    return null;
  }

  const handleCompare = (week: IWeek) => {
    const weekIndex = selectedCompareWeeks.findIndex(h => h?.propertyId === week?.propertyId);
    if (weekIndex != -1) {
      const selectedCompareWeeksFiltered = [...selectedCompareWeeks];
      selectedCompareWeeksFiltered.splice(weekIndex, 1);
      setSelectedCompareWeeks([...selectedCompareWeeksFiltered]);
    } else {
      setSelectedCompareWeeks([...selectedCompareWeeks, week]);
    }
  }

  return (
    <>
      <div className="condo-result-wrapper__compare-message">
        {selectedCompareWeeks.length > 1 ? (<FormattedMessage id="results.hotel.card.compare.message" values={{ selected: selectedCompareWeeks.length, total: maxCompareCondos }} />) : null}
        {selectedCompareWeeks.length === 1 ? (<FormattedMessage id="results.hotel.card.compare.message.select_more" />) : null}
        <div className="compare-buttons">
          {selectedCompareWeeks.length ? (
            <Button className="compare-clear-button" onClick={() => props.setSelectedCompareWeeks([])}>
              <FormattedMessage id="results.condos.card.compare.clear_selection" />
            </Button>) : null}
          <Button className="compare-button" disabled={selectedCompareWeeks.length <= displayCompareLength} onClick={() => setShowCompareModal(true)}>
            {HasViewQuotesSharePermission ? <FormattedMessage id="results.hotel.card.compare.share" /> : <FormattedMessage id="results.hotel.card.compare" /> }
          </Button>
        </div>
      </div>

      <ModalCompare
        visible={showCompareModal}
        weeks={selectedCompareWeeks}
        locationObject={location}
        sessionKey={sessionKey}
        date={date}
        period={period}
        onCancel={() => setShowCompareModal(false)}
        onRemove={handleCompare}
      />
    </>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    weeksStore: state.weeksStore,
    weeksLocationsStore: state.weeksLocationsStore,
    weeksDatesStore: state.weeksDatesStore,

    loginStore: state.loginStore,    
  };
};

const mapDispatchToProps:IMapDispatchToProps = {
  setSelectedCompareWeeks: weeksActions.setSelectedCompareWeeks,
};

export const WeeksResultCompare = connect(mapStateToProps, mapDispatchToProps)(withRouter(WeeksResultCompareComponent));
